import React, { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { BsCheck2, BsChevronExpand } from "react-icons/bs";
import _ from "lodash";

function Example({
  handleSelected,
  data,
  multiple,
  type,
  defaultValue,
  defaultValueEvent,
  width,
}) {
  let people = data;
  // console.log(defaultValue);
  const [selected, setSelected] = useState(defaultValue);

  React.useEffect(() => {
    handleSelected(selected);
  }, [selected]);

  return (
    <div
      className={`block ${
        multiple ? width : width
      }  h-12 justify-end font-jamjuree `}
    >
      <Listbox
        value={selected}
        onChange={(e) => {
          console.log(e);
          if (multiple) {
            if (e.length !== 0) {
              setSelected(e);
            }
          } else {
            setSelected(e);
          }
        }}
        multiple={multiple}
      >
        <div className={`relative w-auto h-12`}>
          <Listbox.Button
            className={`relative ${
              multiple ? width : width
            } cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm`}
          >
            {!multiple ? (
              <span className="block truncate">{selected.name}</span>
            ) : (
              <div className={`flex h-5`}>
                {selected.map((person, index) => (
                  <span
                    key={index}
                    className="px-3 rounded-lg flex mx-1 border w-auto"
                  >
                    {person.name}
                    {""}
                  </span>
                ))}
              </div>
            )}
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <BsChevronExpand
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {people.map((person, personIdx) => (
                <Listbox.Option
                  key={personIdx}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-8 pr-4 ${
                      active ? "bg-amber-100 text-amber-900" : "text-gray-900"
                    }`
                  }
                  value={person}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {person.name}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-2 text-amber-600">
                          <BsCheck2 className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}

export default React.memo(Example);
