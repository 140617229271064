import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { backendUrl } from "../utils/backend";

const LoginRedirect = ({ setJwt, provider }) => {
  const [text, setText] = useState("Loading...");
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // console.log("test login");
    // Successfully logged with the provider
    // Now logging with strapi by using the access_token (given by the provider) in props.location.search
    fetch(`${backendUrl}/api/auth/${provider}/callback${location.search}`)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(`Couldn't login to Strapi. Status: ${res.status}`);
        }
        return res;
      })
      .then((res) => res.json())
      .then((res) => {
        // Successfully logged with Strapi
        // Now saving the jwt to use it for future authenticated requests to Strapi
        localStorage.setItem("jwt", res.jwt);
        localStorage.setItem("userid", res.user.id);
        localStorage.setItem("username", res.user.username);
        sessionStorage.setItem("jwt", res.jwt);
        sessionStorage.setItem("userid", res.user.id);
        sessionStorage.setItem("username", res.user.username);
        setJwt(res.jwt);
        setText(
          "You have been successfully logged in. You will be redirected in a few seconds..."
        );
        setTimeout(() => navigate("/"), 3000); // Redirect to homepage after 3 sec
      })
      .catch((err) => {
        // console.log(err);
        setText("An error occurred, please see the developer console.");
      });
  }, [navigate, location.search]);

  return <p className="text-white">{text}</p>;
};

export default LoginRedirect;
