import _ from "lodash";
import { getOrigin } from ".";
import moment from "moment";

export const formatting = (data, groups) => {
  // console.log(data, groups);
  let veh_time_windows = [
    Math.ceil(
      moment(data[0].properties.origin_date).startOf("day").valueOf() / 1000
    ),
    Math.ceil(
      moment(data[0].properties.origin_date).endOf("day").valueOf() / 1000
    ),
  ];

  // first call is base on group 1
  let allGroup = [];
  let origins;
  let groupID = Object.keys(groups);
  if (groupID.length !== 0) {
    _.map(groupID, (gID, ind) => {
      // console.log(groupID);
      let shipments = [];
      _.map(groups[gID], (dest, index) => {
        // find shipment pair
        dest.properties.origins.data.map((origin, ind) => {
          shipments.push({
            pickup: {
              id:
                (gID + 1) * 10000 +
                (index + 1) * 100 +
                (ind + 1) * 10 +
                dest.properties.index +
                1,
              description: [
                origin.attributes.category,
                origin.attributes.branch_id,
                origin.attributes.name_t,
                origin.attributes.province,
                origin.attributes.vehicle_type,
                "origin", // origin.attributes.index + 1,
                origin.attributes.plate_no,
                origin.attributes.remark,
                origin.attributes.origin_time_to,
              ].join("|"),
              location: [
                origin.attributes.longitude,
                origin.attributes.latitude,
              ],
            },
            delivery: {
              id:
                (gID + 1) * 10000 +
                (index + 1) * 1000 +
                (ind + 1) * 10 +
                dest.properties.index +
                1,
              description: [
                dest.properties.category,
                dest.properties.branch_id,
                dest.properties.destination_place,
                dest.properties.province,
                dest.properties.vehicle_type,
                dest.properties.index + 1,
                dest.properties.plate_no,
                dest.properties.remark,
                dest.properties.origin_time_to,
              ].join("|"),
              location: [
                dest.geometry.coordinates[0],
                dest.geometry.coordinates[1],
              ],
            },
          });
        });
      });
      // console.log(groups[gID][0]);
      let result = {
        jobs: [],
        shipments,
        vehicles: [
          {
            id: ind,
            vehicle: "Veh. #" + ind,
            start: [
              groups[gID][0].properties.origins.data[0].attributes.longitude,
              groups[gID][0].properties.origins.data[0].attributes.latitude,
            ],
            startDescription: "From First Origin",
            capacity: [20],
            time_window: veh_time_windows,
          },
        ],
        options: {
          g: true,
        },
      };
      // console.log(result);
      allGroup.push(result);
    });
  } else {
    let shipments = [];
    _.map(data, (dest, index) => {
      // console.log(dest);
      dest.properties.origins.data.map((origin, ind) => {
        shipments.push({
          pickup: {
            id: (index + 1) * 100 + (ind + 1) * 10 + dest.properties.index + 1,
            description: [
              origin.attributes.category,
              origin.attributes.branch_id,
              origin.attributes.name_t,
              origin.attributes.province,
              origin.attributes.vehicle_type,
              "origin", // origin.attributes.index + 1,
              origin.attributes.plate_no,
              origin.attributes.remark,
              origin.attributes.origin_time_to,
            ].join("|"),
            location: [origin.attributes.longitude, origin.attributes.latitude],
          },
          delivery: {
            id: (index + 1) * 1000 + (ind + 1) * 10 + dest.properties.index + 1,
            description: [
              dest.properties.category,
              dest.properties.branch_id,
              dest.properties.destination_place,
              dest.properties.province,
              dest.properties.vehicle_type,
              dest.properties.index + 1,
              dest.properties.plate_no,
              dest.properties.remark,
              dest.properties.origin_time_to,
            ].join("|"),
            location: [
              dest.geometry.coordinates[0],
              dest.geometry.coordinates[1],
            ],
          },
        });
      });
    });

    allGroup.push({
      jobs: [],
      shipments,
      vehicles: [
        {
          id: 1,
          vehicle: "Veh. #" + 1,
          start: [
            data[0].properties.origins.data[0].attributes.longitude,
            data[0].properties.origins.data[0].attributes.latitude,
          ],
          startDescription: "From First Origin",
          capacity: [20],
          time_window: veh_time_windows,
        },
      ],
      options: {
        g: true,
      },
    });
  }
  return allGroup;
};
