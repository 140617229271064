import * as XLSX from "xlsx-js-style";
import { toast } from "react-toastify";
import _ from "lodash";
import { createTask } from "../utils/apis";
import moment from "moment";

async function ImportExcel(file, toastId, distribution) {
  // console.log("file", file);
  const data = await file.arrayBuffer();
  // console.log("data", data);
  const workbook = XLSX.read(data, { type: "array" });
  let xlsjson = XLSX.utils.sheet_to_json(workbook.Sheets.Task);
  // console.log(xlsjson);
  // console.log(distribution);

  if (!xlsjson) {
    return toast.error("Import format not support");
  }

  // console.log(xlsjson);

  //   let allBranchID = _.map(xlsjson, (item) => item["รหัสสาขา"]);
  //   if (allBranchID.length === 0) {
  //     return toast.error(
  //       "ไม่พบข้อมูล ไฟล์นำเข้ารองรับเฉพาะ excel(xlsx) ที่ได้จากการ Download Invoice ผ่านแอพเท่านั้น"
  //     );
  //   }
  //   if (_.uniq(allBranchID).length !== allBranchID.length) {
  //     return toast.error("นำเข้าล้มเหลว เลขรหัสสาขาซ้ำกันในไฟล์");
  //   } else {
  //     let isExist = await existDropPoint(allBranchID);
  //     if (isExist.data.length !== 0) {
  //       return toast.error("นำเข้าล้มเหลว เลขรหัสสาขาซ้ำกันในระบบ");
  //     }
  //   }

  let noCategory = _.some(xlsjson, (item) => !item["ประเภท/รหัส"]);
  if (noCategory) {
    return toast.error("ไม่พบข้อมูล ประเภท/รหัส");
  }

  let noOrigin_place = _.some(xlsjson, (item) => !item["สถานที่รับสินค้า"]);
  if (noOrigin_place) {
    return toast.error("ไม่พบข้อมูล สถานที่รับสินค้า");
  }

  let noOrigin_datetime = _.some(
    xlsjson,
    (item) => !item["วัน-เวลารับสินค้า[จาก]"]
  );
  if (noOrigin_datetime) {
    return toast.error("ไม่พบข้อมูล วัน-เวลารับสินค้า[จาก]");
  }

  let noOrigin_time_to = _.some(
    xlsjson,
    (item) => !item["วัน-เวลารับสินค้า[ถึง]"]
  );
  if (noOrigin_time_to) {
    return toast.error("ไม่พบข้อมูล วัน-เวลารับสินค้า[ถึง]");
  }

  let noDestination_place = _.some(
    xlsjson,
    (item) => !item["สถานที่ส่งสินค้า"]
  );
  if (noDestination_place) {
    return toast.error("ไม่พบข้อมูล สถานที่ส่งสินค้า");
  }

  let noDestination_datetime = _.some(
    xlsjson,
    (item) => !item["วัน-เวลาส่งสินค้า[จาก]"]
  );
  if (noDestination_datetime) {
    return toast.error("ไม่พบข้อมูล วัน-เวลาส่งสินค้า[จาก]");
  }

  let noDest_time_to = _.some(
    xlsjson,
    (item) => !item["วัน-เวลาส่งสินค้า[ถึง]"]
  );
  if (noDest_time_to) {
    return toast.error("ไม่พบข้อมูล วัน-เวลาส่งสินค้า[ถึง]");
  }

  let noProvince = _.some(xlsjson, (item) => !item["จังหวัด"]);
  if (noProvince) {
    return toast.error("ไม่พบข้อมูล จังหวัด");
  }

  let noRemark = _.some(xlsjson, (item) => !item["หมายเหตุ"]);
  if (noRemark) {
    return toast.error("ไม่พบข้อมูล หมายเหตุ");
  }

  let noShipper = _.some(xlsjson, (item) => !item["ขนส่ง"]);
  if (noShipper) {
    return toast.error("ไม่พบข้อมูล ขนส่ง");
  }

  let noLatitude = _.some(xlsjson, (item) => !item["Latitude"]);
  if (noLatitude) {
    return toast.error("ไม่พบข้อมูล Latitude");
  }

  let noLongitude = _.some(xlsjson, (item) => !item["Longitude"]);
  if (noLongitude) {
    return toast.error("ไม่พบข้อมูล Longitude");
  }

  let progress = 0;

  toastId.current = toast.info("Upload in Progress", { progress });

  xlsjson.map(async (item, ind) => {
    // find origins
    let origin_place = item["สถานที่รับสินค้า"].toString().split("|");
    let origin_id = [];
    // console.log(origin_place);
    origin_place.map(async (place) => {
      distribution.map(async (dist) => {
        if (dist.attributes.name_t === place) {
          // console.log(dist.id);
          origin_id.push(dist.id);
        }
      });
    });

    // console.log("test", origin_id.length, origin_place.length);
    if (origin_id.length === 0 || origin_id.length !== origin_place.length) {
      return toast.error("ไม่พบ สถานที่รับสินค้า ในระบบ");
    }

    let currentData = {
      category: item["ประเภท/รหัส"].toString(),
      origin_place: item["สถานที่รับสินค้า"].toString(),
      origin_datetime: moment(item["วัน-เวลารับสินค้า[จาก]"]).toISOString(),
      origin_time_to: moment(item["วัน-เวลารับสินค้า[ถึง]"]).toISOString(),
      destination_place: item["สถานที่ส่งสินค้า"].toString(),
      destination_datetime: moment(
        item["วัน-เวลาส่งสินค้า[จาก]"]
      ).toISOString(),
      dest_time_to: moment(item["วัน-เวลาส่งสินค้า[ถึง]"]).toISOString(),
      province: item["จังหวัด"].toString(),
      remark: item["หมายเหตุ"].toString(),
      shipper: item["ขนส่ง"].toString(),
      latitude: item["Latitude"].toString(),
      longitude: item["Longitude"].toString(),
      created_id: localStorage.getItem("userid")
        ? parseInt(localStorage.getItem("userid"))
        : parseInt(sessionStorage.getItem("userid")),
      rp_company: localStorage.getItem("company_id")
        ? parseInt(localStorage.getItem("company_id"))
        : parseInt(sessionStorage.getItem("company_id")),
      origins: origin_id,
    };
    await createTask(currentData);
    progress = (ind + 1) / xlsjson.length;
    // console.log(progress);
    toast.update(toastId.current, {
      render: `Upload success at ${(progress * 100).toFixed(2)}%`,
      progress,
    });
    toast.done(toastId.current);
    toast.success("Import successful");
  });
}

export default ImportExcel;
