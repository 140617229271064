import React from "react";
import { addpoint, updatepoint } from "../utils";
import { MdArrowBackIosNew, MdSave } from "react-icons/md";
import { toast } from "react-toastify";
import SearchOriginPlace from "../components/SearchOriginPlace";
import SearchDestinationPlace from "../components/SearchDestinationPlace";
import SearchProvince from "../components/SearchProvince";
import { Context } from "../store";
import moment from "moment";
import _ from "lodash";

function EditForm({ setIsEdit, data, clearNewData }) {
  const [originPlace, setOriginPlace] = React.useState(data.properties.origins);
  // console.log(data);
  // console.log(
  //   data.properties.origin_datetime,
  //   data.properties.destination_datetime
  // );
  // console.log(data.properties.origin_place);
  const [pointData, setPointData] = React.useState({
    category: data.properties.category,
    origin_place: data.properties.origin_place,
    origin_datetime: data.properties.origin_datetime,
    destination_place: data.properties.destination_place,
    destination_datetime: data.properties.destination_datetime,
    province: data.properties.province,
    branch_id: data.properties.branch_id,
    remark: data.properties.remark,
    shipper: data.properties.shipper,
    // vehicle_type: data.properties.vehicle_type,
    // vehicle_order: data.properties.vehicle_order,
    // plate_no: data.properties.plate_no,
    latitude: data.properties.latitude,
    longitude: data.properties.longitude,
    created_id: null,
    updated_id: null,
    origin_time_to: data.properties.origin_time_to,
    dest_time_to: data.properties.dest_time_to,
    destination:
      data.properties.destination &&
      data.properties.destination.data &&
      data.properties.destination.data.id,
  });
  const [state, setState] = React.useContext(Context);
  // console.log(data);
  const handleSavePoint = async () => {
    // console.log(pointData);
    let pointDataResponse = null;
    if (!pointData.origins) {
      return toast.error("Please update select origin [สถานที่รับสินค้า]");
    }
    // if (!pointData.destination) {
    //   return toast.error("Please select destiantion [สถานที่ส่งสินค้า]");
    // }

    // check start and end time_windows
    if (moment(pointData.origin_datetime) > moment(pointData.origin_time_to)) {
      return toast.error("Not valid time interval of origin");
    }
    if (
      moment(pointData.destination_datetime) > moment(pointData.dest_time_to)
    ) {
      return toast.error("Not valid time interval of destination");
    }

    if (data.properties.init_id) {
      // console.log("update");
      setPointData({
        ...pointData,
        updated_id: localStorage.getItem("userid")
          ? parseInt(localStorage.getItem("userid"))
          : parseInt(sessionStorage.getItem("userid")),
        rp_company: localStorage.getItem("company_id")
          ? parseInt(localStorage.getItem("company_id"))
          : parseInt(sessionStorage.getItem("company_id")),
      });
      // console.log(pointData);
      pointDataResponse = await updatepoint(data.properties.init_id, pointData);
    } else {
      // console.log("add");
      setPointData({
        ...pointData,
        created_id: localStorage.getItem("userid")
          ? parseInt(localStorage.getItem("userid"))
          : parseInt(sessionStorage.getItem("userid")),
        rp_company: localStorage.getItem("company_id")
          ? parseInt(localStorage.getItem("company_id"))
          : parseInt(sessionStorage.getItem("company_id")),
      });
      pointDataResponse = await addpoint(pointData);
    }
    // console.log(pointData);
    if (pointDataResponse && pointDataResponse.status) {
      // console.log("toast");
      clearNewData();
      toast.info("Save successful");
      setIsEdit(false);
    } else {
      return toast.error(pointDataResponse.message);
    }
  };

  React.useEffect(() => {
    if (state.selectedoriginplace) {
      let name = _.map(state.selectedoriginplace, (item) => item.name);
      let place_ids = _.map(state.selectedoriginplace, (item) => item.id);
      setPointData({
        ...pointData,
        origin_place: name.join("|"),
        origins: place_ids,
      });
    }
  }, [state.selectedoriginplace]);

  React.useEffect(() => {
    if (state.selecteddestinationplace) {
      // console.log(state.selecteddestinationplace);
      setPointData({
        ...pointData,
        destination_place: state.selecteddestinationplace.name,
        latitude: state.selecteddestinationplace.latitude,
        longitude: state.selecteddestinationplace.longitude,
        destination: state.selecteddestinationplace.id,
        branch_id: state.selecteddestinationplace.branch_id,
        province: state.selecteddestinationplace.province_t,
      });
    }
  }, [state.selecteddestinationplace]);

  React.useEffect(() => {
    if (state.selectedprovince) {
      setPointData({
        ...pointData,
        province: state.selectedprovince.name,
      });
    }
  }, [state.selectedprovince]);

  return (
    <div className="flex flex-col h-auto w-full">
      <div className="h-auto px-4">
        <div className="w-full bg-white rounded-lg border border-gray-200 shadow-md sm:p-6 lg:p-8 dark:bg-gray-800 dark:border-gray-700">
          <div className="flex justify-between">
            <button className="text-white" onClick={() => setIsEdit(false)}>
              <h3>
                <MdArrowBackIosNew className="ml-5 p-2 w-8 h-8 text-black bg-gray-300 rounded-full"></MdArrowBackIosNew>
              </h3>
            </button>
            <button
              onClick={handleSavePoint}
              className="flex w-20 tracking-wide items-center px-2 space-x-2 text-white transition-colors duration-200 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50 justify-end"
            >
              <MdSave className="w-5 h-5 my-auto" />
              <p>บันทึก</p>
            </button>
          </div>
          <div className="py-1 mt-3">
            <label
              htmlFor="category"
              className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              ประเภท/รหัส
            </label>
            <input
              type="text"
              name="category"
              id="category"
              defaultValue={data.properties.category}
              placeholder=""
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              required
              onChange={async (e) =>
                setPointData({ ...pointData, category: e.target.value })
              }
            ></input>
          </div>
          <div className="py-1">
            <label
              htmlFor="origin_place"
              className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              สถานที่รับสินค้า
            </label>
            <SearchOriginPlace
              origin_place={originPlace}
              // onChange={(e) => sentDataList(e)}
            ></SearchOriginPlace>
          </div>
          <div className="space-y-2 py-1">
            <label
              htmlFor="origin_datetime"
              className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              วัน-เวลารับสินค้า
            </label>
            <div className="flex space-x-2">
              <span className="font-jamjuree font-bold text-sm my-auto dark:text-gray-500">
                From
              </span>
              <input
                type="datetime-local"
                name="origin_datetime"
                id="origin_datetime"
                defaultValue={moment(data.properties.origin_datetime).format(
                  "YYYY-MM-DDTHH:mm"
                )}
                placeholder=""
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                required
                onChange={async (e) =>
                  setPointData({
                    ...pointData,
                    origin_datetime: moment(e.target.value),
                  })
                }
              ></input>
            </div>
            <div className="flex space-x-2">
              <span className="font-jamjuree font-bold text-sm my-auto dark:text-gray-500">
                To
              </span>
              <input
                type="datetime-local"
                name="origin_time_to"
                id="origin_datetime"
                defaultValue={moment(data.properties.origin_time_to).format(
                  "YYYY-MM-DDTHH:mm"
                )}
                placeholder=""
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                required
                onChange={async (e) => {
                  // console.log(e.target.value);
                  setPointData({
                    ...pointData,
                    origin_time_to: moment(e.target.value),
                  });
                }}
              ></input>
            </div>
          </div>
          <div className="py-1">
            <label
              htmlFor="destination_place"
              className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              สถานที่ส่งสินค้า
            </label>
            <SearchDestinationPlace
              destination_place={data.properties.destination_place}
            ></SearchDestinationPlace>
          </div>
          <div className="space-y-2 py-1">
            <label
              htmlFor="destination_datetime"
              className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              วัน-เวลาส่งสินค้า
            </label>
            <div className="flex space-x-2">
              <span className="font-jamjuree font-bold text-sm my-auto dark:text-gray-500">
                From
              </span>
              <input
                type="datetime-local"
                name="destination_datetime"
                id="destination_datetime"
                defaultValue={moment(
                  data.properties.destination_datetime
                ).format("YYYY-MM-DDTHH:mm")}
                placeholder=""
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                required
                onChange={async (e) => {
                  setPointData({
                    ...pointData,
                    destination_datetime: moment(e.target.value),
                  });
                }}
              ></input>
            </div>
            <div className="flex space-x-2">
              <span className="font-jamjuree font-bold text-sm my-auto dark:text-gray-500">
                To
              </span>
              <input
                type="datetime-local"
                name="destination_time_to"
                id="destination_datetime"
                defaultValue={moment(data.properties.dest_time_to).format(
                  "YYYY-MM-DDTHH:mm"
                )}
                placeholder=""
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                required
                onChange={async (e) => {
                  setPointData({
                    ...pointData,
                    dest_time_to: moment(e.target.value),
                  });
                }}
              ></input>
            </div>
          </div>
          <div className="py-1">
            <label
              htmlFor="province"
              className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              จังหวัด
            </label>
            <SearchProvince
              province={data.properties.province}
            ></SearchProvince>
          </div>
          <div className="py-1">
            <label
              htmlFor="remark"
              className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              หมายเหตุ
            </label>
            <input
              type="text"
              name="remark"
              id="remark"
              defaultValue={data.properties.remark}
              placeholder=""
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              required
              onChange={async (e) =>
                setPointData({ ...pointData, remark: e.target.value })
              }
            ></input>
          </div>
          <div className="py-1">
            <label
              htmlFor="shipper"
              className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              ขนส่ง
            </label>
            <input
              type="text"
              name="shipper"
              id="shipper"
              defaultValue={data.properties.shipper}
              placeholder=""
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              required
              onChange={async (e) =>
                setPointData({ ...pointData, shipper: e.target.value })
              }
            ></input>
          </div>
          {/* <div className="py-1">
            <label
              htmlFor="vehicle_type"
              className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              ประเภทรถ
            </label>
            <select
              defaultValue={"4 ล้อ"}
              className="form-select appearance-none block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white focus:text-gray-700 focus:bg-white dark:focus:text-white dark:focus:bg-gray-600 focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
              onChange={async (e) =>
                setPointData({ ...pointData, vehicle_type: e.target.value })
              }
            >
              <option value="2 ล้อ">2 ล้อ</option>
              <option value="4 ล้อ">4 ล้อ</option>
              <option value="6 ล้อ">6 ล้อ</option>
              <option value="8 ล้อ">8 ล้อ</option>
              <option value="≥ 10 ล้อ">≥ 10 ล้อ</option>
            </select>
          </div> */}
          {/* <div className="py-1">
            <label
              htmlFor="vehicle_order"
              className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              ลำดับคันรถ
            </label>
            <input
              type="text"
              name="vehicle_order"
              id="vehicle_order"
              defaultValue={data.properties.vehicle_order}
              placeholder=""
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              required
              onChange={async (e) =>
                setPointData({ ...pointData, vehicle_order: e.target.value })
              }
            ></input>
          </div> */}
          {/* <div className="py-1">
            <label
              htmlFor="plate_no"
              className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              ทะเบียน
            </label>
            <input
              type="text"
              name="plate_no"
              id="plate_no"
              defaultValue={data.properties.plate_no}
              placeholder=""
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              required
              onChange={async (e) =>
                setPointData({ ...pointData, plate_no: e.target.value })
              }
            ></input>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default EditForm;
