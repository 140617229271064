const statellite = {
  version: 8,
  glyphs:
    "https://maps.powermap.live/vtile/fonts/{fontstack}/{range}.pbf?access_token=b378c575291af30a29f59919fd7e7e4c012d45c4",
  sources: {
    "raster-tiles": {
      type: "raster",
      // tiles: ["https://mt0.google.com/vt/lyrs=y&hl=th&x={x}&y={y}&z={z}"],
      tiles: [
        "https://3.aerial.maps.api.here.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/256/png8?app_id=ezdRAOqIR5w1r2DBxKrr&app_code=VoVBxZEEjj8iMxGzKzsDbw",
      ],
      tileSize: 256,
      attribution: "",
    },
  },
  layers: [
    {
      id: "simple-tiles",
      type: "raster",
      source: "raster-tiles",
      minzoom: 0,
      maxzoom: 22,
    },
  ],
};
export default statellite;
