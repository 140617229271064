import * as XLSX from "xlsx-js-style";

function ExportTemplate() {
  let company_id = localStorage.company_id || sessionStorage.company_id;
  let newMap = [{}];
  if (company_id === "1") {
    newMap = [
      {
        "ประเภท/รหัส": "ลูกค้า",
        สถานที่รับสินค้า: "ศูนย์กระจายสินค้า ซีพี บีแอนด์เอฟ วิภาวดี 62",
        "วัน-เวลารับสินค้า[จาก]": "01-01-2022 08:00",
        "วัน-เวลารับสินค้า[ถึง]": "01-01-2022 11:00",
        สถานที่ส่งสินค้า: "ซีพี เฟรชมาร์ท วิภาวดี 64 จุด 2",
        "วัน-เวลาส่งสินค้า[จาก]": "01-01-2022 13:00",
        "วัน-เวลาส่งสินค้า[ถึง]": "01-01-2022 15:00",
        จังหวัด: "กรุงเทพมหานคร",
        หมายเหตุ: "-",
        ขนส่ง: "ทีมเอ",
        Latitude: "13.8754408199",
        Longitude: "100.577622269",
      },
    ];
  } else {
    newMap = [
      {
        "ประเภท/รหัส": "แว่นท็อปเจริญ",
        สถานที่รับสินค้า: "สำนักงานใหญ่",
        "วัน-เวลารับสินค้า[จาก]": "01-01-2022 08:00",
        "วัน-เวลารับสินค้า[ถึง]": "01-01-2022 11:00",
        สถานที่ส่งสินค้า: "รพ.วิภาวดี",
        "วัน-เวลาส่งสินค้า[จาก]": "01-01-2022 13:00",
        "วัน-เวลาส่งสินค้า[ถึง]": "01-01-2022 15:00",
        จังหวัด: "กรุงเทพมหานคร",
        หมายเหตุ: "ส่งพัสดุ จันทร์,พุธ,ศุกร์",
        ขนส่ง: "1AA",
        Latitude: "13.846302",
        Longitude: "100.562544",
      },
    ];
  }

  let header = Object.keys(newMap[0]).map((el) => {
    let style = {
      fill: {
        fgColor: {
          rgb: "D3D3D3",
        },
      },
      font: {
        name: "Calibri",
        sz: 12,
        bold: true,
      },
      border: {
        top: { style: "medium", color: { rgb: "708090" } },
        right: { style: "medium", color: { rgb: "708090" } },
        bottom: { style: "medium", color: { rgb: "708090" } },
        left: { style: "medium", color: { rgb: "708090" } },
      },
    };
    return {
      v: el,
      t: "s",
      s: style,
    };
  });

  let data = newMap.map((el) => {
    return Object.values(el).map((ele) => ({
      v: ele,
      t: "s",
      s: {
        border: {
          top: { style: "thin", color: { rgb: "696969" } },
          right: { style: "thin", color: { rgb: "696969" } },
          bottom: { style: "thin", color: { rgb: "696969" } },
          left: { style: "thin", color: { rgb: "696969" } },
        },
      },
    }));
  });

  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet([header, ...data]);
  XLSX.utils.book_append_sheet(wb, ws, "Task");

  XLSX.writeFile(wb, "Task.xlsx");
}

export default ExportTemplate;
