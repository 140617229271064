import React from "react";
import Table from "../components/TailwindTable";
import { getDrops, getAllDropPoint } from "../utils/apis";
import { useQuery } from "@tanstack/react-query";
import _ from "lodash";
import GroupButton from "./GroupButton";
import Pagination from "./Pagination";
import { updateStatusDropPoint } from "../utils";
import { toast } from "react-toastify";
import ListBox from "./ListBox";
import EditTable from "./EditTable";
import Task from "../components/Task";
import maplibregl from "maplibre-gl";
import { RiMapPinAddLine } from "react-icons/ri";
import { FaFileDownload, FaFileUpload } from "react-icons/fa";
import Dialog from "../components/Dialog";
import { Context } from "../store";
import { getCategoryApi } from "../utils/apis";
import ImportExcel from "./ImportExcel";
import ExportExcel from "./ExportExcel";

const pageSizeList = [
  { id: 1, name: 5 },
  { id: 2, name: 10 },
  { id: 3, name: 20 },
  { id: 4, name: 50 },
  { id: 5, name: 100 },
];
const statusList = [
  { id: 1, name: "all (Status)" },
  { id: 2, name: "waiting" },
  { id: 3, name: "confirm" },
  { id: 4, name: "delete" },
];
var el = document.createElement("div");
el.className = "marker";
el.style.backgroundImage = "url(./pinpoint/add_marker.png)";
el.style.width = "30px";
el.style.height = "40px";
el.style.backgroundSize = "contain";
el.style.backgroundRepeat = "no-repeat";

var marker = new maplibregl.Marker({
  element: el,
  draggable: true,
});

const region = [
  { id: 1, name: "all (Region)" },
  { id: 2, name: "north" },
  { id: 3, name: "northeast" },
  { id: 4, name: "west" },
  { id: 5, name: "central" },
  { id: 6, name: "east" },
  { id: 7, name: "south" },
];

function DropTable({ map }) {
  const toastId = React.useRef(null);
  const dataDropRef = React.useRef([]);
  const selectedRef = React.useState();
  const newMarkerRef = React.useRef(false);
  const [state, setState] = React.useContext(Context);
  const [newMarker, setNewMarker] = React.useState(false);
  const [pointData, setPointData] = React.useState();
  const [showTable, setShowTable] = React.useState(false);
  const [selectablePage, setSelectablePage] = React.useState([1, 2, 3]);
  const [activePage, setActivePage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState();
  const [totalDrops, setTotalDrops] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(5);
  const [dropsData, setDropsData] = React.useState([]);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [selectID, setSelectID] = React.useState();
  const [selectType, setSelectType] = React.useState();
  const [pageSizeSelected, setPageSizeSelected] = React.useState(
    pageSizeList[0]
  );
  const [category, setCategory] = React.useState([
    {
      id: 1,
      name: "all (Category)",
      hex: "000000",
      icon: "",
    },
  ]);
  const [typeSelected, setTypeSelected] = React.useState(category[0]);
  const [regionSelected, setRegionSelected] = React.useState(region[0]);
  const [searchBranch, setSearchBranch] = React.useState("");
  const [searchName, setSearchName] = React.useState("");
  const [searchProvinces, setSearchProvinces] = React.useState("");
  const [statusSelected, setStatusSelected] = React.useState(statusList[0]);

  const columns = React.useMemo(
    () => [
      {
        Header: "actions",
        // Header: "การจัดการ",
        Cell: ({ row }) => {
          if (map) {
            return (
              <GroupButton
                handleDelete={() => {
                  handleDeleteClick(row.original.id);
                }}
                handleEdit={() => {
                  if (newMarkerRef.current) {
                    toast.info("กรุณาปิดตัวเลือก 'เพิ่มจุด' ก่อนแก้ไขข้อมูล");
                  } else {
                    handleEdit(row.original);
                    handleMarker(row.original.latitude, row.original.longitude);
                  }
                }}
                // handleMarker={() => {
                //   handleMarker(row.original.latitude, row.original.longitude);
                // }}
                handleConfirmPoint={() => {
                  handleConfirmClick(row.original.id);
                  handleMarker(row.original.latitude, row.original.longitude);
                }}
                // canEdit={newMarkerRef.current}
              />
            );
          }
        },
      },
      {
        Header: "point id",
        // Header: "สถานะ",
        Cell: ({ row }) => {
          let color = "blue";
          // category.map((cate) => {
          //   row.original.cate_e === cate.name
          //     ? (color = `%23${cate.hex}`)
          //     : (color = color);
          // });
          if (row.original.cate_e === "Arabitia-Cafe") {
            color = "%2378350F";
          } else if (row.original.cate_e === "CP Fresh Mart") {
            color = "%23EAB308";
          } else if (row.original.cate_e === "Customer") {
            color = "%23DB2777";
          } else if (row.original.cate_e === "DC") {
            color = "%23EA580C";
          } else if (row.original.cate_e === "Jungle Cafe") {
            color = "%2310B981";
          } else if (row.original.cate_e === "Kaidee Shop") {
            color = "%231E40AF";
          } else if (row.original.cate_e === "Refrig") {
            color = "%230284C7";
          } else if (row.original.cate_e === "Spare-part") {
            color = "%230D9488";
          } else if (row.original.cate_e === "Top Charoen") {
            color = "%230284C7";
          } else {
            color = "blue";
          }
          return (
            <img
              className="w-8 rounded-full cursor-pointer"
              src={`data:image/svg+xml;charset=utf-8,<svg width="100" height="133.33424" viewBox="0 0 105 136.33424" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M 48.48894,2.0846666 C 45.345582,2.4764484 42.045821,3.0699806 39.971926,3.6166357 31.331372,5.8941857 23.70167,10.287001 17.343929,16.644739 7.5307186,26.457951 2.26971,39.565851 2.6992958,53.1321 c 0.2096929,6.62207 1.4945831,12.285322 4.2570658,18.763368 3.8625134,9.057619 8.9221174,16.765286 21.9823994,33.487402 9.794726,12.54096 13.702902,17.89379 17.704361,24.24874 1.304226,2.0713 2.77134,4.14006 3.260261,4.59722 1.567811,1.46597 3.94258,1.46597 5.510391,0 0.48892,-0.45716 1.956035,-2.52592 3.26026,-4.59722 4.00146,-6.35495 7.909636,-11.70778 17.704362,-24.24874 C 89.438679,88.660754 94.498283,80.953087 98.360793,71.895468 102.97204,61.082067 103.87705,50.520165 101.12201,39.670835 98.886222,30.866339 94.481777,23.15329 87.973228,16.644739 80.532191,9.2037052 71.45767,4.5056208 60.997853,2.6789833 58.10893,2.1744806 50.622249,1.8187766 48.48894,2.0846666 Z" 
                        fill="${color}"/><style>
                        .number { font: bold 50px sans-serif; fill: white;}
                      </style>
                      <text x="50%" y="50%" dominant-baseline="start" text-anchor="middle" class="number">★</text></svg>`}
              alt="icon"
              onClick={() =>
                handleMarker(row.original.latitude, row.original.longitude)
              }
            />
          );
        },
      },
      {
        Header: "status",
        // Header: "สถานะ",
        Cell: ({ row }) => {
          return (
            <span className={`text-sm font-jamjuree`}>
              {row.original.status}
            </span>
          );
        },
      },
      {
        // Header: "branch id site",
        Header: "id",
        Cell: ({ row }) => {
          return (
            <span className={`text-sm font-jamjuree`}>{row.original.id}</span>
          );
        },
      },
      {
        // Header: "branch id site",
        Header: "รหัสสาขา",
        Cell: ({ row }) => {
          return (
            <span className={`text-sm font-jamjuree`}>
              {row.original.branch_id}
            </span>
          );
        },
      },
      {
        // Header: "category_th",
        Header: "หมวดหมู่ภาษาไทย",
        Cell: ({ row }) => {
          return (
            <span className={`text-sm font-jamjuree`}>
              {row.original.cate_t}
            </span>
          );
        },
      },
      {
        // Header: "category_en",
        Header: "หมวดหมู่ภาษาอังกฤษ",
        Cell: ({ row }) => {
          return (
            <span className={`text-sm font-jamjuree`}>
              {row.original.cate_e}
            </span>
          );
        },
      },
      {
        // Header: "name_th",
        Header: "ชื่อภาษาไทย",
        Cell: ({ row }) => {
          return (
            <span className={`text-sm font-jamjuree`}>
              {row.original.name_t}
            </span>
          );
        },
      },
      {
        // Header: "name_en",
        Header: "ชื่อภาษาอังกฤษ",
        Cell: ({ row }) => {
          return (
            <span className={`text-sm font-jamjuree`}>
              {row.original.name_e}
            </span>
          );
        },
      },
      {
        // Header: "tambon_t",
        Header: "ตำบล",
        Cell: ({ row }) => {
          return (
            <span className={`text-sm font-jamjuree`}>
              {row.original.tambon_t}
            </span>
          );
        },
      },
      {
        // Header: "amphoe_t",
        Header: "อำเภอ",
        Cell: ({ row }) => {
          return (
            <span className={`text-sm font-jamjuree`}>
              {row.original.amphoe_t}
            </span>
          );
        },
      },
      {
        // Header: "province_t",
        Header: "จังหวัด",
        Cell: ({ row }) => {
          return (
            <span className={`text-sm font-jamjuree`}>
              {row.original.province_t}
            </span>
          );
        },
      },
      {
        // Header: "postcode",
        Header: "รหัสไปรษณีย์",
        Cell: ({ row }) => {
          return (
            <span className={`text-sm font-jamjuree`}>
              {row.original.postcode}
            </span>
          );
        },
      },
    ],
    [map]
  );

  const getCategory = async () => {
    let allCategory = await getCategoryApi();
    let formatCate = _.map(allCategory.data, (item) => ({
      id: item.id + 1,
      name: item.attributes.cate_e,
      hex: item.attributes.hex,
      icon: item.attributes.icon.slice(13).slice(0, -4),
    }));
    setCategory([
      {
        id: 1,
        name: "all (Category)",
        hex: "000000",
        icon: "",
      },
      ...formatCate,
    ]);
  };

  React.useEffect(() => {
    getCategory();
  }, []);

  const handleDeleteClick = (id) => {
    setShowConfirm(true);
    setSelectType("delete");
    setSelectID(id);
  };
  const handleConfirmClick = (id) => {
    setShowConfirm(true);
    setSelectType("confirm");
    setSelectID(id);
  };
  const previousPage = () => {
    if (activePage - 1 > 0) {
      setActivePage(activePage - 1);
    }
  };
  const nextPage = () => {
    if (totalPages >= activePage + 1) {
      setActivePage(activePage + 1);
    }
  };
  const gotoLastPage = () => {
    setActivePage(totalPages === 0 ? 1 : totalPages);
  };
  const gotoFirstPage = () => {
    setActivePage(1);
  };

  const handlePageSizeSelected = (pageSizeSelect) => {
    setPageSize(pageSizeSelect.name);
    setPageSizeSelected(pageSizeSelect);
  };
  const handleTypeSelected = (e) => {
    setTypeSelected(e);
  };
  const handleStatusSelected = (e) => {
    setStatusSelected(e);
  };
  const handleRegionSelected = (e) => {
    setRegionSelected(e);
  };

  const handleConfirm = () => {
    handleUpdatePoint(selectID, selectType);
    setShowTable(false);
    setShowConfirm(false);
  };

  const handleUpdatePoint = async (id) => {
    let dropPointResponse = await updateStatusDropPoint(id, selectType);
    if (dropPointResponse && dropPointResponse.status) {
      refetch();
      toast.info("Update successful");
    } else {
      return toast.error(dropPointResponse.message);
    }
  };

  const handleImportPoint = async (e) => {
    // console.log(e.target.files[0]);
    await ImportExcel(e.target.files[0], toastId);
    toast.success("Import successful");
    // console.log("test");
    refetch();
  };

  const handleExportPoint = async () => {
    let allData = await getAllDropPoint();
    if (allData) {
      let formattedData = _.map(allData.data, (item) => {
        let data = {
          ...item.attributes,
          id: item.id,
        };
        delete data.amphoe_e;
        delete data.createdAt;
        delete data.created_id;
        delete data.fid;
        delete data.geom;
        delete data.id;
        delete data.new_cate;
        delete data.poi_id;
        delete data.province_e;
        delete data.publishedAt;
        delete data.status;
        delete data.tam_id;
        delete data.tambon_e;
        delete data.updatedAt;
        delete data.updated_id;
        return data;
      });
      ExportExcel(formattedData);
      toast.success("Export successful");
    } else {
      return toast.error(allData.message);
    }
  };

  function onDragEnd() {
    var lngLat = marker.getLngLat();
    setPointData({
      ...selectedRef.current,
      latitude: lngLat.lat,
      longitude: lngLat.lng,
    });
  }

  const handleEdit = (data) => {
    updateMap();
    setPointData(data);
    selectedRef.current = data;
    setShowTable(true);
    // add marker to map for draggable
    marker.setLngLat([data.longitude, data.latitude]).addTo(map);
    marker.on("dragend", onDragEnd);
  };

  const handleMarker = (latitude, longitude) => {
    updateMap();
    map.flyTo({
      center: [longitude, latitude],
      duration: 2000,
      zoom: 16,
    });
  };

  const { data, refetch } = useQuery(["dropData", activePage], () =>
    getDrops(
      activePage,
      pageSize,
      typeSelected,
      regionSelected,
      statusSelected,
      searchBranch,
      searchName,
      searchProvinces
    )
  );

  React.useEffect(() => {
    refetch();
  }, [
    pageSize,
    activePage,
    selectablePage,
    pageSizeSelected,
    typeSelected,
    regionSelected,
    statusSelected,
    searchBranch,
    searchName,
    searchProvinces,
  ]);

  const showMarker = (initialMarker) => {
    updateMap();
    if (map.getSource("places")) {
      map.getSource("places").setData({
        type: "FeatureCollection",
        features: initialMarker,
      });
    }
  };

  const updateMap = () => {
    // console.log(dataDropRef.current, map.getSource("places"));
    if (map.getSource("places")) {
      map.getSource("places").setData({
        type: "FeatureCollection",
        features: dataDropRef.current,
      });
    }
  };
  const formatData = (data) => {
    let newData = _.map(data.data, (item) => ({
      id: item.id,
      ...item.attributes,
    }));

    let initialMarker = _.map(data.data, (item, index) => {
      let marker = "marker-spare-part";
      // category.map((cate) => {
      //   item.attributes.cate_e === cate.name
      //     ? (marker = `${cate.icon}`)
      //     : (marker = marker);
      // });
      if (item.attributes.cate_e === "Arabitia-Cafe") {
        marker = "marker-arabitia-cafe";
      } else if (item.attributes.cate_e === "CP Fresh Mart") {
        marker = "marker-cp-freshmart";
      } else if (item.attributes.cate_e === "Customer") {
        marker = "marker-customer";
      } else if (item.attributes.cate_e === "DC") {
        marker = "marker-dc";
      } else if (item.attributes.cate_e === "Jungle Cafe") {
        marker = "marker-jungle-cafe";
      } else if (item.attributes.cate_e === "Kaidee Shop") {
        marker = "marker-kaidee-shop";
      } else if (item.attributes.cate_e === "Refrig") {
        marker = "marker-refrig";
      } else if (item.attributes.cate_e === "Spare-part") {
        marker = "marker-spare-part";
      } else if (item.attributes.cate_e === "Top Charoen") {
        marker = "marker-top-charoen";
      } else {
        marker = "marker-spare-part";
      }
      // console.log(marker);
      return {
        type: "Feature",
        properties: {
          init_id: item.id,
          index: index,
          icon: marker,
          description: item.attributes.name_t,
        },
        geometry: {
          type: "Point",
          coordinates: [item.attributes.longitude, item.attributes.latitude],
        },
      };
    });
    // console.log("newData", newData);
    dataDropRef.current = initialMarker;
    setState((state) => ({ ...state, initialMarker: initialMarker }));
    showMarker(initialMarker);
    setDropsData(newData);
    setTotalPages(data.meta.pagination.pageCount);
    setTotalDrops(data.meta.pagination.total);
  };

  React.useEffect(() => {
    if (data) {
      map.on("click", (e) => {
        if (newMarkerRef.current) {
          marker.setLngLat([e.lngLat.lng, e.lngLat.lat]).addTo(map);
          marker.on("dragend", onDragEnd);
          // setnew point data
          // console.log(data);
          let cloneData = data.data[0].attributes;
          _.map(Object.keys(cloneData), (each) => {
            cloneData[each] = null;
          });
          delete cloneData.createdAt;
          delete cloneData.updatedAt;
          delete cloneData.publishedAt;
          cloneData.latitude = e.lngLat.lat;
          cloneData.longitude = e.lngLat.lng;
          // console.log(cloneData);
          setPointData(cloneData);
          setShowTable(true);
        }
      });
    }
    if (data && map) formatData(data);
  }, [data, map]);

  React.useEffect(() => {
    if (totalPages === 0) {
      setSelectablePage([1]);
    } else {
      let newSelecteablePages = _.filter(
        [activePage - 1, activePage, activePage + 1],
        (item) => item !== 0 && item !== totalPages + 1
      );
      setSelectablePage(newSelecteablePages);
    }
  }, [activePage, totalPages]);

  // console.log(dropsData, data, map);
  if (dropsData) {
    return (
      <div className="overflow-hidden">
        {showConfirm && (
          <Dialog
            isOpen={showConfirm}
            setIsOpen={setShowConfirm}
            handleConfirm={handleConfirm}
            handleCancel={() => {
              setShowConfirm(false);
            }}
            title={"Confirmation " + selectType}
            message={"Are you sure to " + selectType + " this location ?"}
          />
        )}
        {showTable && (
          <Task
            open={showTable}
            setOpen={setShowTable}
            width="w-96"
            layout="right"
          >
            <div className="flex flex-col pt-3 bg-gray-700  h-auto">
              <EditTable
                data={pointData}
                refetch={refetch}
                setShowTable={setShowTable}
              />
            </div>
          </Task>
        )}
        <div className="flex flex-col w-sidebar z-10 pl-2 justify-between pt-3 pr-3 fixed bg-slate-700 shadow-lg">
          <div className="flex space-x-2 justify-between">
            <div className="flex space-x-2 justify-end">
              <input
                type="text"
                name="branch_id"
                id="branch_id"
                placeholder="รหัสสาขา"
                className="h-9 bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:placeholder-gray-400 w-24"
                onChange={async (e) => setSearchBranch(e.target.value)}
              ></input>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="ชื่อภาษาไทย"
                className="h-9 bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:placeholder-gray-400 w-36"
                onChange={async (e) => setSearchName(e.target.value)}
              ></input>
              <input
                type="text"
                name="provinces"
                id="provinces"
                placeholder="จังหวัด"
                className="h-9 bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:placeholder-gray-400 w-32"
                onChange={async (e) => setSearchProvinces(e.target.value)}
              ></input>
            </div>
            <div className="flex space-x-2 ">
              <ListBox
                width="w-30"
                data={statusList}
                type="event status"
                multiple={false}
                handleSelected={handleStatusSelected}
                defaultValue={statusSelected}
              />
              <ListBox
                width="w-40"
                data={category}
                type="event types"
                multiple={false}
                handleSelected={handleTypeSelected}
                defaultValue={typeSelected}
              />
              <ListBox
                width="w-36"
                data={region}
                type="event region"
                multiple={false}
                handleSelected={handleRegionSelected}
                defaultValue={regionSelected}
              />
              <button
                title="Add Point"
                onClick={() => {
                  setShowTable(false);
                  setNewMarker(!newMarker);
                  newMarkerRef.current = !newMarkerRef.current;
                  marker.remove();
                }}
                className={`my-2.5 -mt-0.5 p-2.5 rounded-full shadow-lg ${
                  newMarkerRef.current
                    ? "bg-teal-500 text-teal-100"
                    : "bg-teal-100 text-teal-500"
                }`}
              >
                <RiMapPinAddLine className="w-5 h-5 my-auto" />
              </button>
            </div>
          </div>
          <div className="flex justify-between">
            <div>
              <Pagination
                selectablePage={selectablePage}
                activePage={activePage}
                previousPage={previousPage}
                nextPage={nextPage}
                gotoLastPage={gotoLastPage}
                gotoFirstPage={gotoFirstPage}
                setActivePage={setActivePage}
              />
            </div>
            <div className="flex space-x-2">
              <div className="flex mt-1 text-white font-jamjuree">
                Total Record: {totalDrops}
              </div>
              <ListBox
                width="w-20"
                data={pageSizeList}
                type="event pagination"
                multiple={false}
                handleSelected={handlePageSizeSelected}
                defaultValue={pageSizeSelected}
              />
              <div title="Import Point">
                <label
                  htmlFor="upload"
                  className="flex items-center my-2.5 -mt-0.5 p-2.5 rounded-lg bg-blue-500 text-white cursor-pointer"
                >
                  <div className="group relative">
                    <FaFileDownload className="w-5 h-5 my-auto" />
                    <input
                      id="upload"
                      type="file"
                      accept=".xlsx"
                      className="hidden"
                      onChange={(e) => handleImportPoint(e)}
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                    />
                  </div>
                </label>
              </div>
              <button
                title="Export Point"
                onClick={() => handleExportPoint()}
                className={`my-2.5 -mt-0.5 p-2.5 rounded-lg bg-green-500 text-white`}
              >
                <FaFileUpload className="w-5 h-5 my-auto" />
              </button>
            </div>
          </div>
        </div>
        <div className="block mt-28">
          <Table
            columns={columns}
            data={dropsData}
            manualPagination={true}
            showSelect={false}
            handleSelect={(selected) => selected}
          />
        </div>
      </div>
    );
  }
  return (
    <div className="flex justify-center w-auto h-full items-center overflow-hidden">
      <div
        style={{ borderTopColor: "transparent" }}
        className="w-16 h-16 border-4 border-blue-400 border-dotted rounded-full animate-spin"
      ></div>
    </div>
  );
}

export default DropTable;
