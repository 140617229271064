import { HiLocationMarker } from "react-icons/hi";
import { BsTruck } from "react-icons/bs";

function VehicelCard({ title, icon, data }) {
  return (
    <>
      <article className="flex items-center p-6 bg-white border border-gray-100 rounded-lg gap-4 w-full">
        <span className="p-3 text-blue-600 bg-blue-100 rounded-full">
          {icon == "truck" && <BsTruck className="w-4 h-4"></BsTruck>}
          {icon == "marker" && (
            <HiLocationMarker className="w-4 h-4"></HiLocationMarker>
          )}
        </span>

        <div>
          <p className="text-2xl font-medium text-gray-900">{data}</p>

          <p className="text-sm text-gray-500">Total {title}</p>
        </div>
      </article>
    </>
  );
}

export default VehicelCard;
