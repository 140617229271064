import { GiConfirmed } from "react-icons/gi";

function ButtonGroup({
  handleDelete,
  handleEdit,
  handleConfirmPoint,
  canEdit,
}) {
  return (
    <div className="flex items-center -space-x-2 hover:space-x-0">
      <button
        disabled={canEdit}
        onClick={handleEdit}
        className="block p-2 text-blue-700 bg-blue-100 border-2 border-white rounded-full transition-all active:bg-blue-50 hover:scale-110 focus:outline-none focus:ring"
        type="button"
      >
        <div className="group relative">
          <svg
            className="w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
            />
          </svg>
          <div className="hidden group-hover:block absolute z-10 -top-9 left-4 bg-gray-700 text-white rounded px-2 py-1 text-sm whitespace-nowrap">
            Edit
          </div>
        </div>
      </button>

      <>
        <button
          onClick={handleDelete}
          className="block p-2 text-red-700 bg-red-300 border-2 border-white rounded-full transition-all hover:scale-110 focus:outline-none focus:ring active:bg-red-50"
          type="button"
        >
          <div className="group relative">
            <svg
              className="w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              />
            </svg>
            <div className="hidden group-hover:block absolute z-10 -top-9 left-4 bg-gray-700 text-white rounded px-2 py-1 text-sm whitespace-nowrap">
              Delete
            </div>
          </div>
        </button>
        <button
          onClick={handleConfirmPoint}
          className="block p-2 text-gray-500 bg-green-300 border-2 border-white rounded-full transition-all hover:scale-110 focus:outline-none focus:ring active:bg-red-50"
          type="button"
        >
          <div className="group relative">
            <GiConfirmed className="w-5 h-5" />
            <div className="hidden group-hover:block absolute z-10 -top-9 left-4 bg-gray-700 text-white rounded px-2 py-1 text-sm whitespace-nowrap">
              Confirm
            </div>
          </div>
        </button>
      </>
    </div>
  );
}

export default ButtonGroup;
