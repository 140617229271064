import React from "react";
import { addDropPoint, updateDropPoint } from "../utils";
import { getGeofenceData } from "../utils/apis";
import { getCategoryApi } from "../utils/apis";
import { toast } from "react-toastify";
import _ from "lodash";
import { BsSave } from "react-icons/bs";
// import SearchOriginPlace from "../components/SearchOriginPlace";
// import SearchDestinationPlace from "../components/SearchDestinationPlace";
// import SearchProvince from "../components/SearchProvince";
// import { Context } from "../store";
// import moment from "moment";

// const types = [
//   {
//     id: 0,
//     name_th: "อาราบิเทีย",
//     name_en: "Arabitia-Cafe",
//   },
//   {
//     id: 1,
//     name_th: "อะไหล่",
//     name_en: "Spare-part",
//   },
//   {
//     id: 2,
//     name_th: "ศูนย์กระจายสินค้า",
//     name_en: "DC",
//   },
//   {
//     id: 3,
//     name_th: "ตู้แช่แข็ง",
//     name_en: "Refrig",
//   },
//   {
//     id: 4,
//     name_th: "ซีพี เฟรชมาร์ท",
//     name_en: "CP Fresh Mart",
//   },
//   {
//     id: 5,
//     name_th: "จังเกิล คาเฟ่",
//     name_en: "Jungle Cafe",
//   },
//   {
//     id: 6,
//     name_th: "ร้านขายดี",
//     name_en: "Kaidee Shop",
//   },
// ];

function EditTable({ data, refetch, setShowTable }) {
  // console.log(data);
  const [pointEdit, setPointEdit] = React.useState(data);
  // console.log(data.cate_t, types.name_th);
  const [cateID, setCateID] = React.useState(0);

  const [geofenceAllData, setGeofenceAllData] = React.useState();
  // console.log(pointEdit);
  // React.useEffect(() => {
  //   setPointEdit(data);
  //   console.log("first", pointEdit);
  // }, []);
  const [category, setCategory] = React.useState();

  const getCategory = async () => {
    let allCategory = await getCategoryApi();
    let formatCate = _.map(allCategory.data, (item, index) => ({
      id: index,
      name_th: item.attributes.cate_t,
      name_en: item.attributes.cate_e,
    }));
    setCategory(formatCate);
    // setCategory([
    //   {
    //     id: 1,
    //     name: "all (Category)",
    //     hex: "000000",
    //     icon: "",
    //   },
    //   ...formatCate,
    // ]);
  };

  React.useEffect(() => {
    getCategory();
  }, []);

  React.useEffect(async () => {
    let geofenceData = await getGeofenceData(data.latitude, data.longitude);
    setGeofenceAllData(geofenceData);
    // setPointEdit(data);
    // console.log(data);
    // console.log(data, pointEdit);
    // console.log(geofenceData);
    if (data.id && category) {
      // console.log(category);
      // console.log(data.id);
      let cate_ID = _.filter(category, (item) => item.name_th === data.cate_t);
      // console.log(cate_ID[0].id);
      setCateID(cate_ID[0].id);
      setPointEdit({
        ...data,
        latitude: data.latitude,
        longitude: data.longitude,
        tambon_t: geofenceData.data[0].tambon_t,
        tambon_e: geofenceData.data[0].tambon_e,
        amphoe_t: geofenceData.data[0].amphoe_t,
        amphoe_e: geofenceData.data[0].amphoe_e,
        province_t: geofenceData.data[0].province_t,
        province_e: geofenceData.data[0].province_e,
        postcode: geofenceData.data[0].postcode,
        updated_id: localStorage.getItem("userid")
          ? parseInt(localStorage.getItem("userid"))
          : parseInt(sessionStorage.getItem("userid")),
        rp_company: localStorage.getItem("company_id")
          ? parseInt(localStorage.getItem("company_id"))
          : parseInt(sessionStorage.getItem("company_id")),
        status: "waiting",
      });
    } else {
      setPointEdit({
        ...data,
        latitude: data.latitude,
        longitude: data.longitude,
        tambon_t: geofenceData.data[0].tambon_t,
        tambon_e: geofenceData.data[0].tambon_e,
        amphoe_t: geofenceData.data[0].amphoe_t,
        amphoe_e: geofenceData.data[0].amphoe_e,
        province_t: geofenceData.data[0].province_t,
        province_e: geofenceData.data[0].province_e,
        postcode: geofenceData.data[0].postcode,
        created_id: localStorage.getItem("userid")
          ? parseInt(localStorage.getItem("userid"))
          : parseInt(sessionStorage.getItem("userid")),
        rp_company: localStorage.getItem("company_id")
          ? parseInt(localStorage.getItem("company_id"))
          : parseInt(sessionStorage.getItem("company_id")),
        status: "waiting",
      });
    }
  }, [data, category]);

  const handleSavePoint = async () => {
    let pointEditResponse = null;
    if (data.id) {
      if (pointEdit.branch_id === null || pointEdit.branch_id === "") {
        return toast.error("กรุณาระบุรหัสสาขา");
      } else {
        pointEditResponse = await updateDropPoint(data.id, pointEdit);
      }
      // console.log("update");
      // setPointEdit({
      //   ...pointEdit,
      //   updated_id: localStorage.getItem("userid")
      //     ? parseInt(localStorage.getItem("userid"))
      //     : parseInt(sessionStorage.getItem("userid")),
      //   status: "completed",
      // });
    } else {
      if (pointEdit.branch_id === null || pointEdit.branch_id === "") {
        return toast.error("กรุณาระบุรหัสสาขา");
      } else {
        pointEditResponse = await addDropPoint(pointEdit);
      }
      // console.log("add");
      // setPointEdit({
      //   ...pointEdit,
      //   updated_id: localStorage.getItem("userid")
      //     ? parseInt(localStorage.getItem("userid"))
      //     : parseInt(sessionStorage.getItem("userid")),
      //   status: "completed",
      // });
    }
    if (pointEditResponse && pointEditResponse.status) {
      refetch();
      setShowTable(false);
      toast.info("Save successful");
    } else {
      setShowTable(false);
      return toast.error(pointEditResponse.message);
    }
  };

  if (!pointEdit || !category) {
    return <p>Loading...</p>;
  } else {
    return (
      <div className="flex flex-col w-full">
        <div className="h-auto px-4">
          <div className="w-full bg-white rounded-lg border border-gray-200 shadow-md sm:p-6 lg:p-8 dark:bg-gray-800 dark:border-gray-700">
            <div className="flex justify-end">
              <button
                onClick={handleSavePoint}
                className="flex items-center justify-between p-2 space-x-2 w-20 tracking-wide text-white transition-colors duration-200 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50"
              >
                <BsSave className="w-5 h-5"></BsSave>
                <p>บันทึก</p>
              </button>
            </div>
            <div className="py-1 mt-3">
              <label
                htmlFor="branch_id"
                className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                รหัสสาขา
              </label>
              <input
                type="text"
                name="branch_id"
                id="branch_id"
                value={pointEdit.branch_id}
                placeholder=""
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                required
                onChange={async (e) =>
                  setPointEdit({
                    ...pointEdit,
                    branch_id: e.target.value,
                  })
                }
              ></input>
            </div>
            <div className="py-1">
              <label
                htmlFor="cate_t"
                className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                หมวดหมู่ภาษาไทย
              </label>
              <select
                name="cate_t"
                id="cate_t"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm
              rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full
              p-2.5 dark:bg-gray-600 dark:border-gray-500
              dark:placeholder-gray-400 dark:text-white"
                value={category[cateID].id}
                onChange={async (e) => {
                  setCateID(e.target.value);
                  setPointEdit((pointEdit) => ({
                    ...pointEdit,
                    cate_t: category[e.target.value].name_th,
                    cate_e: category[e.target.value].name_en,
                  }));
                }}
              >
                {category.map((value, index) => (
                  <option key={index} value={value.id}>
                    {value.name_th}
                  </option>
                ))}
              </select>
            </div>
            <div className="py-1">
              <label
                htmlFor="cate_e"
                className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                หมวดหมู่ภาษาอังกฤษ
              </label>
              <select
                name="cate_e"
                id="cate_e"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm
              rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full
              p-2.5 dark:bg-gray-600 dark:border-gray-500
              dark:placeholder-gray-400 dark:text-white"
                value={category[cateID].id}
                onChange={async (e) => {
                  setCateID(e.target.value);
                  setPointEdit({
                    ...pointEdit,
                    cate_t: category[e.target.value].name_th,
                    cate_e: category[e.target.value].name_en,
                  });
                }}
              >
                {category.map((value, index) => (
                  <option key={index} value={value.id}>
                    {value.name_en}
                  </option>
                ))}
              </select>
            </div>
            <div className="py-1">
              <label
                htmlFor="name_t"
                className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                ชื่อภาษาไทย
              </label>
              <input
                type="text"
                name="name_t"
                id="name_t"
                value={pointEdit.name_t || ""}
                placeholder=""
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                required
                onChange={async (e) =>
                  setPointEdit({
                    ...pointEdit,
                    name_t: e.target.value,
                  })
                }
              ></input>
            </div>
            <div className="py-1">
              <label
                htmlFor="name_e"
                className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                ชื่อภาษาอังกฤษ
              </label>
              <input
                type="text"
                name="name_e"
                id="name_e"
                value={pointEdit.name_e || ""}
                placeholder=""
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                required
                onChange={async (e) =>
                  setPointEdit({
                    ...pointEdit,
                    name_e: e.target.value,
                  })
                }
              ></input>
            </div>
            <div className="py-1">
              <label
                htmlFor="addr_t"
                className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                ที่อยู่ภาษาไทย
              </label>
              <input
                type="text"
                name="addr_t"
                id="addr_t"
                value={pointEdit.addr_t || ""}
                placeholder=""
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                required
                onChange={async (e) =>
                  setPointEdit({
                    ...pointEdit,
                    addr_t: e.target.value,
                  })
                }
              ></input>
            </div>
            <div className="py-1">
              <label
                htmlFor="addr_e"
                className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                ที่อยู่ภาษาอังกฤษ
              </label>
              <input
                type="text"
                name="addr_e"
                id="addr_e"
                value={pointEdit.addr_e || ""}
                placeholder=""
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                required
                onChange={async (e) =>
                  setPointEdit({
                    ...pointEdit,
                    addr_e: e.target.value,
                  })
                }
              ></input>
            </div>
            <div className="py-1">
              <label
                htmlFor="house_no"
                className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                บ้านเลขที่
              </label>
              <input
                type="text"
                name="house_no"
                id="house_no"
                value={pointEdit.house_no || ""}
                placeholder=""
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                required
                onChange={async (e) =>
                  setPointEdit({
                    ...pointEdit,
                    house_no: e.target.value,
                  })
                }
              ></input>
            </div>
            <div className="py-1">
              <label
                htmlFor="moo"
                className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                หมู่
              </label>
              <input
                type="text"
                name="moo"
                id="moo"
                value={pointEdit.moo || ""}
                placeholder=""
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                required
                onChange={async (e) =>
                  setPointEdit({
                    ...pointEdit,
                    moo: e.target.value,
                  })
                }
              ></input>
            </div>
            <div className="py-1">
              <label
                htmlFor="road_tname"
                className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                ชื่อถนนภาษาไทย
              </label>
              <input
                type="text"
                name="road_tname"
                id="road_tname"
                value={pointEdit.road_tname || ""}
                placeholder=""
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                required
                onChange={async (e) =>
                  setPointEdit({
                    ...pointEdit,
                    road_tname: e.target.value,
                  })
                }
              ></input>
            </div>
            <div className="py-1">
              <label
                htmlFor="road_ename"
                className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                ชื่อถนนภาษาอังกฤษ
              </label>
              <input
                type="text"
                name="road_ename"
                id="road_ename"
                value={pointEdit.road_ename || ""}
                placeholder=""
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                required
                onChange={async (e) =>
                  setPointEdit({
                    ...pointEdit,
                    road_ename: e.target.value,
                  })
                }
              ></input>
            </div>
            {/* <div className="py-1">
            <label
              htmlFor="tam_id"
              className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              tam_id
            </label>
            <input
              type="text"
              name="tam_id"
              id="tam_id"
              value={data.tam_id}
              placeholder=""
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              required
              onChange={async (e) =>
                setPointEdit({
                  ...pointEdit,
                  tam_id: e.target.value,
                })
              }
            ></input>
          </div> */}
            <div className="py-1">
              <label
                htmlFor="tambon_t"
                className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                ตำบล
              </label>
              <input
                type="text"
                name="tambon_t"
                id="tambon_t"
                value={pointEdit.tambon_t || ""}
                placeholder=""
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                required
                onChange={async (e) =>
                  setPointEdit({
                    ...pointEdit,
                    tambon_t: e.target.value,
                  })
                }
                // disabled
              ></input>
            </div>
            {/* <div className="py-1">
            <label
              htmlFor="tambon_e"
              className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              tambon
            </label>
            <input
              type="text"
              name="tambon_e"
              id="tambon_e"
              value={data.tambon_e}
              placeholder=""
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              required
              onChange={async (e) =>
                setPointEdit({
                  ...pointEdit,
                  tambon_e: e.target.value,
                })
              }
              disabled
            ></input>
          </div> */}
            <div className="py-1">
              <label
                htmlFor="amphoe_t"
                className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                อำเภอ
              </label>
              <input
                type="text"
                name="amphoe_t"
                id="amphoe_t"
                value={pointEdit.amphoe_t || ""}
                placeholder=""
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                required
                onChange={async (e) =>
                  setPointEdit({
                    ...pointEdit,
                    amphoe_t: e.target.value,
                  })
                }
                // disabled
              ></input>
            </div>
            {/* <div className="py-1">
            <label
              htmlFor="amphoe_e"
              className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              amphoe
            </label>
            <input
              type="text"
              name="amphoe_e"
              id="amphoe_e"
              value={data.amphoe_e}
              placeholder=""
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              required
              onChange={async (e) =>
                setPointEdit({
                  ...pointEdit,
                  amphoe_e: e.target.value,
                })
              }
              disabled
            ></input>
          </div> */}
            <div className="py-1">
              <label
                htmlFor="province_t"
                className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                จังหวัด
              </label>
              <input
                type="text"
                name="province_t"
                id="province_t"
                value={pointEdit.province_t || ""}
                placeholder=""
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                required
                onChange={async (e) =>
                  setPointEdit({
                    ...pointEdit,
                    province_t: e.target.value,
                  })
                }
                // disabled
              ></input>
            </div>
            {/* <div className="py-1">
            <label
              htmlFor="province_e"
              className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              province
            </label>
            <input
              type="text"
              name="province_e"
              id="province_e"
              value={data.province_e}
              placeholder=""
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              required
              onChange={async (e) =>
                setPointEdit({
                  ...pointEdit,
                  province_e: e.target.value,
                })
              }
              disabled
            ></input>
          </div> */}
            <div className="py-1">
              <label
                htmlFor="postcode"
                className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                รหัสไปรษณีย์
              </label>
              <input
                type="text"
                name="postcode"
                id="postcode"
                value={pointEdit.postcode}
                placeholder=""
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                required
                onChange={async (e) =>
                  setPointEdit({
                    ...pointEdit,
                    postcode: e.target.value,
                  })
                }
              ></input>
            </div>
            <div className="py-1">
              <label
                htmlFor="contact_name"
                className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                ผู้ติดต่อ
              </label>
              <input
                type="text"
                name="contact_name"
                id="contact_name"
                value={pointEdit.contact_name || ""}
                placeholder=""
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                required
                onChange={async (e) =>
                  setPointEdit({
                    ...pointEdit,
                    contact_name: e.target.value,
                  })
                }
              ></input>
            </div>
            <div className="py-1">
              <label
                htmlFor="phone_no"
                className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                เบอร์ติดต่อ
              </label>
              <input
                type="text"
                name="phone_no"
                id="phone_no"
                value={pointEdit.phone_no || ""}
                placeholder=""
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                required
                onChange={async (e) =>
                  setPointEdit({
                    ...pointEdit,
                    phone_no: e.target.value,
                  })
                }
              ></input>
            </div>
            <div className="py-1">
              <label
                htmlFor="opening_hours"
                className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                เวลาเปิดทำการ
              </label>
              <input
                type="text"
                name="opening_hours"
                id="opening_hours"
                value={pointEdit.opening_hours || ""}
                placeholder=""
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                required
                onChange={async (e) =>
                  setPointEdit({
                    ...pointEdit,
                    opening_hours: e.target.value,
                  })
                }
              ></input>
            </div>
            <div className="py-1">
              <label
                htmlFor="branch_tty"
                className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                ชื่อสาขาภาษาไทย
              </label>
              <input
                type="text"
                name="branch_tty"
                id="branch_tty"
                value={pointEdit.branch_tty || ""}
                placeholder=""
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                required
                onChange={async (e) =>
                  setPointEdit({
                    ...pointEdit,
                    branch_tty: e.target.value,
                  })
                }
              ></input>
            </div>
            <div className="py-1">
              <label
                htmlFor="branch_ety"
                className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                ชื่อสาขาภาษาอังกฤษ
              </label>
              <input
                type="text"
                name="branch_ety"
                id="branch_ety"
                value={pointEdit.branch_ety || ""}
                placeholder=""
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                required
                onChange={async (e) =>
                  setPointEdit({
                    ...pointEdit,
                    branch_ety: e.target.value,
                  })
                }
              ></input>
            </div>
            <div className="py-1">
              <label
                htmlFor="latitude"
                className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                latitude
              </label>
              <input
                type="text"
                name="latitude"
                id="latitude"
                value={pointEdit.latitude}
                placeholder=""
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                required
                onChange={async (e) =>
                  setPointEdit({
                    ...pointEdit,
                    latitude: e.target.value,
                  })
                }
                // disabled
              ></input>
            </div>
            <div className="py-1">
              <label
                htmlFor="longitude"
                className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                longitude
              </label>
              <input
                type="text"
                name="longitude"
                id="longitude"
                value={pointEdit.longitude}
                placeholder=""
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                required
                onChange={async (e) =>
                  setPointEdit({
                    ...pointEdit,
                    longitude: e.target.value,
                  })
                }
                // disabled
              ></input>
            </div>
            <div className="py-1">
              <label
                htmlFor="remark"
                className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                หมายเหตุ
              </label>
              <input
                type="text"
                name="remark"
                id="remark"
                value={pointEdit.remark || ""}
                placeholder=""
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                required
                onChange={async (e) =>
                  setPointEdit({
                    ...pointEdit,
                    remark: e.target.value,
                  })
                }
              ></input>
            </div>
            {/* <div className="py-1">
            <label
              htmlFor="poi_id"
              className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              poi_id
            </label>
            <input
              type="text"
              name="poi_id"
              id="poi_id"
              value={data.poi_id}
              placeholder=""
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              required
              onChange={async (e) =>
                setPointEdit({
                  ...pointEdit,
                  poi_id: e.target.value,
                })
              }
            ></input>
          </div>
          <div className="py-1">
            <label
              htmlFor="new_cate"
              className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              new_cate
            </label>
            <input
              type="text"
              name="new_cate"
              id="new_cate"
              value={data.new_cate}
              placeholder=""
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              required
              onChange={async (e) =>
                setPointEdit({
                  ...pointEdit,
                  new_cate: e.target.value,
                })
              }
            ></input>
          </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default EditTable;
