import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import navigationData from "./data/navigation";
// import Map from "./Map";
import Docs from "./Docs";
import Optimize from "./RouteOpt";
import LoginRedirect from "./SignUp/googleAuth";
import Login from "./LoginLocal";
import ProtectedRoute from "./utils/protectedRoute";
import jwtDecode from "jwt-decode"; // import dependency
import { ToastContainer, toast } from "react-toastify";
import SignUp from "./SignUp";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import RoutePlanner from "./RoutePlanner";
import MapCluster from "./MapCluster";
import Drops from "./Drops";
import Vehicle from "./Vehicle";
import Dashboard from "./Dashboard";
import Manual from "./Manual";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

function seconds_since_epoch(d) {
  return Math.floor(d / 1000);
}

// Driver Code
var d = new Date();
var sec = seconds_since_epoch(d);

const Layout = ({ children }) => {
  return (
    <div className="bg-gray-200 h-screen">
      <Sidebar navigationData={navigationData} />
      <div className="flex items-center justify-center">{children}</div>
    </div>
  );
};

export default function App() {
  let navigate = useNavigate();
  let [jwt, setJwt] = React.useState(
    localStorage.getItem("jwt")
      ? localStorage.getItem("jwt")
      : sessionStorage.getItem("jwt")
  );

  const handleLogout = () => {
    // console.log("logout");
    localStorage.removeItem("jwt");
    localStorage.removeItem("userid");
    localStorage.removeItem("username");
    localStorage.removeItem("company_id");
    localStorage.removeItem("company_name");
    localStorage.removeItem("company_abbr");
    localStorage.removeItem("company_address");
    localStorage.removeItem("company_email");
    localStorage.removeItem("company_tel");
    localStorage.removeItem("company_website");

    sessionStorage.removeItem("jwt");
    sessionStorage.removeItem("userid");
    sessionStorage.removeItem("username");
    sessionStorage.removeItem("company_id");
    sessionStorage.removeItem("company_name");
    sessionStorage.removeItem("company_abbr");
    sessionStorage.removeItem("company_address");
    sessionStorage.removeItem("company_email");
    sessionStorage.removeItem("company_tel");
    sessionStorage.removeItem("company_website");
    return navigate("/signin");
  };

  React.useEffect(() => {
    let access_token = localStorage.getItem("jwt")
      ? localStorage.getItem("jwt")
      : sessionStorage.getItem("jwt");
    if (access_token) {
      const detail_token = jwtDecode(access_token);
      // console.log("Access token: " + JSON.stringify(detail_token), sec);
      if (sec > detail_token.exp) {
        toast.info("Session Expired");
        handleLogout();
      }
    }
  }, []);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ToastContainer theme="colored" />
        <Routes>
          <Route
            restricted={false}
            path="/signin"
            element={<Login setJwt={setJwt} />}
          />
          <Route
            restricted={false}
            path="/signup"
            element={<SignUp setJwt={setJwt} />}
          />
          <Route
            restricted={false}
            path="/forgot-password"
            element={<ForgotPassword setJwt={setJwt} />}
          />
          <Route
            restricted={false}
            path="/reset-password"
            element={<ResetPassword setJwt={setJwt} />}
          />
          <Route
            restricted={false}
            path="/connect/google/redirect"
            element={<LoginRedirect provider={"google"} setJwt={setJwt} />}
          />
          <Route
            restricted={false}
            path="/connect/facebook/redirect"
            element={<LoginRedirect provider={"facebook"} setJwt={setJwt} />}
          />
          <Route
            path="/"
            element={
              <ProtectedRoute access_token={jwt}>
                <Layout>
                  <Dashboard />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/maps"
            element={
              <ProtectedRoute access_token={jwt}>
                <Layout>
                  <Dashboard />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/planner"
            element={
              <ProtectedRoute access_token={jwt}>
                <Layout>
                  <RoutePlanner />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/optimize"
            element={
              <ProtectedRoute access_token={jwt}>
                <Layout>
                  <Optimize />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/docs"
            element={
              <ProtectedRoute access_token={jwt}>
                <Layout>
                  <Docs />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/map-cluster"
            element={
              <ProtectedRoute access_token={jwt}>
                <Layout>
                  <MapCluster />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/drop-master"
            element={
              <ProtectedRoute access_token={jwt}>
                <Layout>
                  <Drops />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/vehicle"
            element={
              <ProtectedRoute access_token={jwt}>
                <Layout>
                  <Vehicle />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/manual"
            element={
              <ProtectedRoute access_token={jwt}>
                <Layout>
                  <Manual />
                </Layout>
              </ProtectedRoute>
            }
          />
        </Routes>
      </QueryClientProvider>
    </>
  );
}
