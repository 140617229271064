import React from "react";
import Table from "../components/TailwindTable";
import { getVehicle } from "../utils/apis";
import { useQuery } from "@tanstack/react-query";
import _ from "lodash";
import GroupButton from "./GroupButton";
import Pagination from "./Pagination";
import { updateStatusVehicle } from "../utils";
import { toast } from "react-toastify";
import ListBox from "./ListBox";
import EditVehicle from "./EditVehicle";
import Task from "../components/Task";
import Dialog from "../components/Dialog";
import { MdAddCircle } from "react-icons/md";

const pageSizeList = [
  { id: 1, name: 5 },
  { id: 2, name: 10 },
  { id: 3, name: 20 },
  { id: 4, name: 50 },
  { id: 5, name: 100 },
];

function VehicleTable() {
  const [pointData, setPointData] = React.useState();
  const [showTable, setShowTable] = React.useState(false);
  const [selectablePage, setSelectablePage] = React.useState([1, 2, 3]);
  const [activePage, setActivePage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState();
  const [totalVehicles, setTotalVehicles] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(1);
  const [vehicleData, setVehicleData] = React.useState([]);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [selectID, setSelectID] = React.useState();
  const [selectType, setSelectType] = React.useState();
  const [pageSizeSelected, setPageSizeSelected] = React.useState(
    pageSizeList[0]
  );
  const columns = React.useMemo(
    () => [
      {
        Header: "actions",
        // Header: "การจัดการ",
        Cell: ({ row }) => {
          return (
            <GroupButton
              handleEdit={() => {
                // console.log(row);
                handleEdit(row.original);
              }}
              handleDelete={() => {
                handleDeleteClick(row.original.id);
              }}
              handleConfirmPoint={() => {
                handleConfirmClick(row.original.id);
              }}
            />
          );
        },
      },
      {
        Header: "id",
        Cell: ({ row }) => {
          return (
            <span className={`text-sm font-jamjuree`}>{row.original.id}</span>
          );
        },
      },
      {
        Header: "status",
        Cell: ({ row }) => {
          return (
            <span className={`text-sm font-jamjuree`}>
              {row.original.status}
            </span>
          );
        },
      },
      {
        Header: "ทะเบียนรถ",
        Cell: ({ row }) => {
          return (
            <span className={`text-sm font-jamjuree`}>
              {row.original.plate_no}
            </span>
          );
        },
      },
      {
        Header: "ประเภทรถ",
        Cell: ({ row }) => {
          return (
            <span className={`text-sm font-jamjuree`}>{row.original.type}</span>
          );
        },
      },
    ],
    []
  );

  const handleDeleteClick = (id) => {
    setShowConfirm(true);
    setSelectType("delete");
    setSelectID(id);
  };
  const handleConfirmClick = (id) => {
    setShowConfirm(true);
    setSelectType("confirm");
    setSelectID(id);
  };
  const previousPage = () => {
    if (activePage - 1 > 0) {
      setActivePage(activePage - 1);
    }
  };
  const nextPage = () => {
    if (totalPages >= activePage + 1) {
      setActivePage(activePage + 1);
    }
  };
  const gotoLastPage = () => {
    setActivePage(totalPages === 0 ? 1 : totalPages);
  };
  const gotoFirstPage = () => {
    setActivePage(1);
  };

  const handlePageSizeSelected = (pageSizeSelect) => {
    setPageSize(pageSizeSelect.name);
    setPageSizeSelected(pageSizeSelect);
  };

  const handleConfirm = () => {
    handleUpdatePoint(selectID, selectType);
    setShowTable(false);
    setShowConfirm(false);
  };

  const handleUpdatePoint = async (id) => {
    let vehicleResponse = await updateStatusVehicle(id, selectType);
    if (vehicleResponse && vehicleResponse.status) {
      refetch();
      toast.info("Operation successful");
    } else {
      return toast.error(vehicleResponse.message);
    }
  };

  const handleEdit = (data) => {
    // console.log(data);
    setPointData(data);
    setShowTable(true);
  };

  const formatData = (data) => {
    let newData = _.map(data.data, (item) => ({
      id: item.id,
      ...item.attributes,
    }));
    setVehicleData(newData);
    setTotalPages(data.meta.pagination.pageCount);
    setTotalVehicles(data.meta.pagination.total);
  };

  const { data, refetch } = useQuery(["vehicleData", activePage], () =>
    getVehicle(activePage, pageSize)
  );
  React.useEffect(() => {
    if (data) {
      formatData(data);
    }
  }, [data]);

  React.useEffect(() => {
    refetch();
  }, [pageSize, activePage, selectablePage, pageSizeSelected, refetch]);

  React.useEffect(() => {
    if (totalPages === 0) {
      setSelectablePage([1]);
    } else {
      let newSelecteablePages = _.filter(
        [activePage - 1, activePage, activePage + 1],
        (item) => item !== 0 && item !== totalPages + 1
      );
      setSelectablePage(newSelecteablePages);
    }
  }, [activePage, totalPages]);

  if (vehicleData) {
    return (
      <div className="overflow-hidden">
        {showConfirm && (
          <Dialog
            isOpen={showConfirm}
            setIsOpen={setShowConfirm}
            handleConfirm={handleConfirm}
            handleCancel={() => {
              setShowConfirm(false);
            }}
            title={"Confirmation " + selectType}
            message={"Are you sure to " + selectType + " this vehicle ?"}
          />
        )}
        {showTable && (
          <Task
            open={showTable}
            setOpen={setShowTable}
            width="w-96"
            layout="right"
          >
            <div className="flex flex-col pt-3 bg-gray-500 bg-opacity-50 h-full">
              <EditVehicle
                data={pointData}
                refetch={refetch}
                setShowTable={setShowTable}
              />
            </div>
          </Task>
        )}
        <div className="flex flex-col w-sidebar z-10 pl-2 justify-between pt-3 pr-3 fixed bg-slate-700 shadow-lg">
          <div className="flex space-x-2 justify-between">
            <div>
              <Pagination
                selectablePage={selectablePage}
                activePage={activePage}
                previousPage={previousPage}
                nextPage={nextPage}
                gotoLastPage={gotoLastPage}
                gotoFirstPage={gotoFirstPage}
                setActivePage={setActivePage}
              />
            </div>
            <div className="flex space-x-2">
              <div className="flex mt-1 mr-2 text-white font-jamjuree">
                Total Record: {totalVehicles}
              </div>
              <ListBox
                width={"w-20"}
                data={pageSizeList}
                type="event pagination"
                multiple={false}
                handleSelected={handlePageSizeSelected}
                defaultValue={pageSizeSelected}
              />
              <button
                onClick={() => {
                  let cloneData = {
                    plate_no: "",
                    type: "",
                  };
                  delete cloneData.createdAt;
                  delete cloneData.updatedAt;
                  delete cloneData.publishedAt;
                  setPointData(cloneData);
                  setShowTable(true);
                }}
                className={`my-3 -mt-0.5 p-1 rounded-full shadow-lg bg-sky-200
                }`}
              >
                <MdAddCircle className="w-8 h-8 my-auto text-sky-700" />
              </button>
            </div>
          </div>
        </div>
        <div className="block mt-16">
          <Table
            columns={columns}
            data={vehicleData}
            manualPagination={true}
            showSelect={false}
            handleSelect={(selected) => selected}
          />
        </div>
      </div>
    );
  }
  return (
    <div className="flex justify-center w-auto h-full items-center overflow-hidden">
      <div
        style={{ borderTopColor: "transparent" }}
        className="w-16 h-16 border-4 border-blue-400 border-dotted rounded-full animate-spin"
      ></div>
    </div>
  );
}

export default VehicleTable;
