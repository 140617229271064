import React from "react";
import moment from "moment";
import { MdFavoriteBorder } from "react-icons/md";
import { getVehicleNoPage } from "../utils/apis";
import _ from "lodash";
import { Context } from "../store";

function OptOutput({
  data,
  markerGroup,
  optimizeResult,
  mapRef,
  handlePlateChange,
  plateList,
}) {
  const [state, setState] = React.useContext(Context);
  const [vehicleList, setVehicleList] = React.useState([]);
  const [allVehicleList, setAllVehicleList] = React.useState([]);

  const handleAddToFav = (e) => {
    // console.log(e);
    setState((state) => ({ ...state, saveFav: true }));
  };

  const getvehicles = async () => {
    let allVehicles = await getVehicleNoPage();
    setAllVehicleList(allVehicles.data);
    let formattedVehicle = _.map(allVehicles.data, (vehicle, index) => ({
      key: index,
      id: vehicle.id,
      name: vehicle.attributes.plate_no,
    }));

    setVehicleList([
      {
        id: 0,
        name: "",
      },
      ...formattedVehicle,
    ]);
  };

  // console.log(optimizeResult, plateList);

  React.useEffect(() => {
    getvehicles();
  }, []);
  return (
    <>
      {optimizeResult.map((result, ind) => (
        <div
          key={ind}
          className="border m-3 p-4 mt-2 rounded-lg dark:border-gray-600 bg-white"
        >
          <div className="flex justify-between w-full">
            <p className="border-b font-bold uppercase dark:text-gray-400 dark:border-gray-600 mr-10 flex">
              Group {ind + 1}
            </p>
            <div className="flex space-x-2 mb-3">
              <select
                defaultValue={""}
                value={plateList[ind] && plateList[ind].id}
                className="form-select appearance-none block w-36 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white focus:text-gray-700 focus:bg-white dark:focus:text-white dark:focus:bg-gray-600 focus:border-blue-600 focus:outline-none"
                aria-label="Default select example"
                onChange={async (e) => {
                  let selectedVehicle = _.filter(
                    allVehicleList,
                    (item) => item.id == parseInt(e.target.value)
                  );

                  // console.log(allVehicleList, selectedVehicle, e.target.value);
                  handlePlateChange(selectedVehicle, ind);
                }}
              >
                {vehicleList.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
              <button
                title="favourite"
                className=""
                onClick={(result) => {
                  handleAddToFav(result, ind);
                }}
              >
                <MdFavoriteBorder className="w-5 h-5 text-gray-500" />
              </button>
            </div>
          </div>
          <div className="container">
            <div className="flex flex-col md:grid grid-cols-12 text-gray-50">
              {_.uniqBy(result.steps, (item) => item.description).map(
                (step, index) => {
                  if (index !== 0 && index !== result.steps.length - 1) {
                    return (
                      <div key={index} className="flex md:contents">
                        <div className="col-start-0 col-end-2 mr-5 md:mx-auto relative">
                          <div className="h-full w-6 flex items-center justify-center">
                            <div className="h-full w-1 bg-gray-300 pointer-events-none"></div>
                          </div>
                          <div className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full text-center">
                            <img
                              className="w-6 rounded-full cursor-pointer"
                              src={`data:image/svg+xml;charset=utf-8,<svg width="100" height="133.33424" viewBox="0 0 105 136.33424" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M 48.48894,2.0846666 C 45.345582,2.4764484 42.045821,3.0699806 39.971926,3.6166357 31.331372,5.8941857 23.70167,10.287001 17.343929,16.644739 7.5307186,26.457951 2.26971,39.565851 2.6992958,53.1321 c 0.2096929,6.62207 1.4945831,12.285322 4.2570658,18.763368 3.8625134,9.057619 8.9221174,16.765286 21.9823994,33.487402 9.794726,12.54096 13.702902,17.89379 17.704361,24.24874 1.304226,2.0713 2.77134,4.14006 3.260261,4.59722 1.567811,1.46597 3.94258,1.46597 5.510391,0 0.48892,-0.45716 1.956035,-2.52592 3.26026,-4.59722 4.00146,-6.35495 7.909636,-11.70778 17.704362,-24.24874 C 89.438679,88.660754 94.498283,80.953087 98.360793,71.895468 102.97204,61.082067 103.87705,50.520165 101.12201,39.670835 98.886222,30.866339 94.481777,23.15329 87.973228,16.644739 80.532191,9.2037052 71.45767,4.5056208 60.997853,2.6789833 58.10893,2.1744806 50.622249,1.8187766 48.48894,2.0846666 Z" 
                        fill="%230000FF"/><style>
                        .number { font: bold 50px sans-serif; fill: white;}
                      </style>
                      <text x="50%" y="50%" dominant-baseline="start" text-anchor="middle" class="number">${index}</text></svg>`}
                              alt="icon"
                              onClick={() => {
                                mapRef.flyTo({
                                  center: [step.location[0], step.location[1]],
                                  duration: 2000,
                                  zoom: 16,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-start-3 col-end-12 rounded-xl p-1 w-full">
                          <div className="flex mt-2 dark:text-gray-400 text-jamjuree">
                            <div className="flex-1 min-w-0">
                              <p className="text-sm text-gray-500 truncate dark:text-gray-400 font-jamjuree">
                                {step.description
                                  ? step.description.split("|")[2] || "No Name"
                                  : "No Name"}
                              </p>
                              <p className="text-sm font-medium text-gray-900 truncate font-jamjuree">
                                {step.distance / 1000} km [
                                {moment
                                  .utc(step.duration * 1.3 * 1000)
                                  .format("HH")}{" "}
                                H{" "}
                                {moment
                                  .utc(step.duration * 1.3 * 1000)
                                  .format("mm")}{" "}
                                min ]
                              </p>
                              {step.description &&
                                step.description.split("|")[8] && (
                                  <p className="text-sm font-medium text-gray-900 truncate font-jamjuree">
                                    ETA at{" "}
                                    {moment
                                      .unix(step.arrival)
                                      .format("YYYY-MM-DD HH:mm")}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                }
              )}
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default OptOutput;
