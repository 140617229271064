import React, { useState, useEffect } from "react";
import Selection from "./Selection";
import { MdDeleteForever } from "react-icons/md";
import _ from "lodash";
import Switch from "./Switch";

let currentMarker;
function PopupDetails({
  description,
  options,
  index,
  data,
  handleChange,
  handleDelete,
  handleChangeType,
}) {
  const [isPickup, setIsPickup] = React.useState(false);
  const [isDepot, setIsDepot] = React.useState(false);
  const [isStart, setIsStart] = React.useState(false);
  const [isEnd, setIsEnd] = React.useState(false);

  const handleChangeSelection = (type, choose, index) => {
    // change only the selected
    handleChange(type, choose, index);
    if (type == "start") {
      setIsStart(true);
    }
    if (type == "end") {
      setIsEnd(true);
    }
  };

  React.useEffect(() => {
    currentMarker = _.filter(
      data.data.features,
      (item) => item.properties.index === index
    );
    if (!currentMarker[0].properties.isDrop) {
      setIsDepot(true);
    }
    setIsStart(currentMarker[0].properties.isStart);
    setIsEnd(currentMarker[0].properties.isEnd);
  }, []);

  // React.useEffect(() => {
  //   handleChangeType();
  // }, []);

  // console.log("refresh", data.data.features);

  return (
    <div className="flex-col p-2 m-2 rounded-lg">
      <div className="flex w-full justify-between items-center py-1">
        <div sr_only="address">Location #{index + 1} </div>
        <button className="" onClick={() => handleDelete(index)}>
          <MdDeleteForever className="w-6 h-6 text-red-500" />
        </button>
      </div>
      <div className="flex w-full justify-between items-center py-1">
        <div sr_only="address">Address : </div>
        <div sr_only="address">{description}</div>
      </div>
      <div className="flex w-full justify-between items-center py-1">
        <div sr_only="address">{isPickup ? "Pick up" : "Delivery"}</div>
        <Switch enabled={isPickup} setEnabled={setIsPickup} />
      </div>
      <div className="flex w-full justify-between items-center py-1">
        <div sr_only="address">{!isDepot ? "Drop" : "Depot"}</div>
        <Switch enabled={isDepot} setEnabled={setIsDepot} />
      </div>
      {isDepot && (
        <div className="flex w-full justify-between items-center py-1">
          {!isEnd && (
            <div className="flex-col w-full justify-between items-center">
              <>
                <div sr_only="type" className="justify-start">
                  {" "}
                  Start:{" "}
                </div>
                <Selection
                  index={index}
                  type="start"
                  handleChange={handleChangeSelection}
                  data={data}
                  options={options}
                />
              </>
            </div>
          )}
          {!isStart && (
            <div className="flex-col w-full justify-end">
              <>
                <div sr_only="type" className="flex">
                  {" "}
                  End:{" "}
                </div>
                <Selection
                  index={index}
                  type="end"
                  handleChange={handleChangeSelection}
                  data={data}
                  options={options}
                />
              </>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default PopupDetails;
