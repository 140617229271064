import { MdAccountCircle } from "react-icons/md";

function Account() {
  return (
    <div className="flex flex-col h-screen text-white">
      <div className="flex flex-col items-center justify-center flex-shrink-0 px-4 py-4 border-b-2">
        <span aria-hidden="true">
          <MdAccountCircle className="w-20 h-20" />
        </span>
      </div>
      <div className="flex-1 overflow-hidden hover:overflow-y-auto">
        <div className="px-4 py-2 font-medium space-y-3">
          <div>
            Username: {localStorage.username || sessionStorage.username}
          </div>
          <div>
            Company: {localStorage.company_name || sessionStorage.company_name}
          </div>
          <div>
            Address:{" "}
            {localStorage.company_address || sessionStorage.company_address}
          </div>
          <div>
            Email: {localStorage.company_email || sessionStorage.company_email}
          </div>
          <div>
            Tel: {localStorage.company_tel || sessionStorage.company_tel}
          </div>
          <div>
            Website:{" "}
            {localStorage.company_website || sessionStorage.company_website}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Account;
