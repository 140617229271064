import { Navigate } from "react-router-dom";

const ProtectedRoute = ({
  access_token,
  redirectPath = "/signin",
  children,
}) => {
  if (localStorage.getItem("jwt") || sessionStorage.getItem("jwt")) {
    return children;
  } else {
    return <Navigate to={redirectPath} replace />;
  }
};

export default ProtectedRoute;
