/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

export default function HeadlessSlideOver({
  open,
  setOpen,
  width,
  children,
  layout,
}) {
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    setShow(open);
  }, []);

  React.useEffect(() => {
    if (!show) {
      const timer = setTimeout(() => {
        setOpen(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [show]);

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        static
        className="${width} inset-0 overflow-auto"
        open={show}
        onClose={() => {}}
      >
        <div className={`inset-0 overflow-hidden`}>
          <div
            className={`fixed inset-y-0 ${
              layout === "left" ? "left-14" : "right-0"
            } pl-0 ${width} flex z-50`}
          >
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom={
                layout === "left" ? "-translate-x-full" : "translate-x-full"
              }
              enterTo={layout === "left" ? "translate-x-0" : "translate-x-0"}
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom={layout === "left" ? "translate-x-0" : "translate-x-0"}
              leaveTo={
                layout === "left" ? "-translate-x-full" : "translate-x-full"
              }
            >
              <div className={`relative w-full dark:bg-dark bg-light`}>
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div
                    className={`absolute top-0 ${
                      layout == "left"
                        ? "-right-16 sm:-right-6"
                        : "left-0 -ml-16"
                    } pt-3 pr-2 flex sm:-mr-12 sm:pr-4`}
                  >
                    <button
                      className="bg-gray-400 p-2 m-2 z-50 rounded-full text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      <span className="sr-only">Close panel</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-full flex flex-col text-light dark:text-dark shadow-xl overflow-y-auto dark:bg-dark bg-light">
                  <div className="relative flex-1">
                    {/* Replace with your content */}
                    {children}
                    {/* /End replace */}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
