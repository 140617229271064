import * as XLSX from "xlsx-js-style";
import moment from "moment";
import _ from "lodash";

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

let styleHeader = {
  fill: {
    fgColor: {
      rgb: "D3D3D3",
    },
  },
  font: {
    name: "Calibri",
    sz: 12,
    bold: true,
  },
  border: {
    top: { style: "medium", color: { rgb: "708090" } },
    right: { style: "medium", color: { rgb: "708090" } },
    bottom: { style: "medium", color: { rgb: "708090" } },
    left: { style: "medium", color: { rgb: "708090" } },
  },
};

export const ExportXLSX = (optimizeResult, plateList) => {
  // console.log(optimizeResult, plateList);

  var header = [
    "Type",
    "Code",
    "Name",
    "Province",
    "Car Type",
    "Order",
    "Team",
    "Remark",
    "Loading Time",
  ].map((e) => {
    return {
      v: e,
      t: "s",
      s: styleHeader,
    };
  });
  // First Group
  let merge = [];

  let formattedData = [];
  let lastRow = 1;
  optimizeResult.map((item, gId) => {
    let filterResultData = _.filter(
      item.steps,
      (each, index) => each.type == "pickup" || each.type == "delivery"
    );
    let resultData = filterResultData.map((el, row) => {
      let groupLength = filterResultData.length;
      lastRow = lastRow + 1;
      let rowdata = el.description.split("|");
      rowdata.splice(4, 1, plateList[gId].attributes.type);
      rowdata.splice(6, 1, plateList[gId].attributes.plate_no);
      return rowdata.map((ele, col) => {
        if (col === 4 && row === groupLength - 1) {
          // exclude header
          merge.push({
            s: { r: lastRow - groupLength, c: col },
            e: { r: lastRow - 1, c: col },
          });
        }
        return {
          v: ele,
          t: "s",
          s: {
            border: {
              top: { style: "thin", color: { rgb: "696969" } },
              right: { style: "thin", color: { rgb: "696969" } },
              bottom: {
                style: row === groupLength - 1 ? "thick" : "thin",
                color: { rgb: "696969" },
              },
              left: { style: "thin", color: { rgb: "696969" } },
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
          },
        };
      });
    });
    formattedData = [...formattedData, ...resultData];
  });

  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet([header, ...formattedData]);
  // console.log(merge);
  ws["!merges"] = merge;
  XLSX.utils.book_append_sheet(wb, ws, "Route Details");
  XLSX.writeFile(wb, "Route Planner Report.xlsx");
};
