import React, { useState, useEffect, useRef } from "react";
import MapView from "./MapView";
import { GrAddCircle } from "react-icons/gr";
import Split from "react-split";
import { Context } from "../store";
import _ from "lodash";
import Modal from "./Modal";
import { GrSettingsOption } from "react-icons/gr";
import RouteResult from "./RouteResult";
import { TrashIcon } from "@heroicons/react/solid";
import humanizeDuration from "humanize-duration";

const renderResult = (item) => {
  return (
    <>
      {item && (
        <div className="border rounded-md w-full space-y-1 p-2">
          <div className="container flex justify-between">
            <div className="text-sm">Cost:</div>
            <div className="text-sm">
              {humanizeDuration(item.summary.cost * 1000)}
            </div>
          </div>
          <div className="container flex justify-between">
            <div className="text-sm">Distance:</div>
            <div className="text-sm">
              {(item.summary.distance / 1000).toFixed(2)} Km
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const vehicleCard = (item) => {
  return (
    <div className="border rounded-md w-full space-y-1 p-2">
      <div className="container flex justify-between">
        <div className="text-sm">Name:</div>
        <div className="text-sm">{item.each.name}</div>
      </div>
    </div>
  );
};

const depotCard = (item) => {
  return (
    <div className="border rounded-md w-full space-y-1 p-2">
      <div className="container flex justify-between">
        <div className="text-sm">Depot ID:</div>
        <div className="text-sm">#{item.each.properties.index + 1}</div>
      </div>
      <div className="container flex justify-between">
        <div className="text-sm">
          {item.each.properties.isStart ? "Start of" : "End of"}
        </div>
        <div className="text-sm">{item.each.properties.vehicle}</div>
      </div>
    </div>
  );
};

const dropCard = (item) => {
  return (
    <div className="border rounded-md w-full space-y-1 p-2">
      <div className="container flex justify-between">
        <div className="text-sm">Drop ID:</div>
        <div className="text-sm">#{item.each.properties.index + 1}</div>
      </div>
    </div>
  );
};

function RouteOpt() {
  const [isOpen, setIsOpen] = React.useState(false);
  const [vehicleName, setVehicleName] = React.useState("");
  const [state, setState] = React.useContext(Context);
  const markerRef = React.useRef(state.markerLayer);
  const [loading, setLoading] = React.useState(false);
  const [storeJob, setStoreJob] = React.useState({});
  const [data, setData] = React.useState({
    jobs: [],
    shipments: [],
    vehicles: [],
  });
  const map = useRef(null);

  // console.log(data, state.markerLayer);

  let vehicleOnly = _.filter(state.options, (each) => each.name !== "N/A");
  let depotOnly = _.filter(
    state.markerLayer.data.features,
    (each) => !each.properties.isDrop
  );
  let dropOnly = _.filter(
    state.markerLayer.data.features,
    (each) => each.properties.isDrop
  );

  const handleSubmit = async () => {
    // console.log(state);
    // create correct format
    let cloneMarkerList = [...state.markerLayer.data.features];
    let vehicleUsed = [],
      jobs = [],
      vehicle_name_list = [];
    let recordLoc = {};
    await _.map(cloneMarkerList, async (each, index) => {
      // check if need to update drop or vehicle state
      recordLoc[index + 1] = each.properties.icon;
      if (each.properties.isDrop) {
        // console.log("index", index);
        jobs.push({
          id: index + 1,
          description: "Wang Thonglang District",
          location: each.geometry.coordinates,
          delivery: [1],
        });
      }

      if (each.properties.vehicle !== "N/A") {
        // console.log("index", index);
        // console.log("vehicle add", each.properties.vehicle);
        if (!vehicle_name_list.includes(each.properties.vehicle)) {
          // console.log("index", index);
          vehicle_name_list.push(each.properties.vehicle);
          if (each.properties.isStart) {
            vehicleUsed.push({
              id: index + 1,
              vehicle: each.properties.vehicle,
              start: each.geometry.coordinates,
              startDescription: "Bang Kruai Town Municipality",
              capacity: [3],
            });
          } else {
            vehicleUsed.push({
              id: index + 1,
              vehicle: each.properties.vehicle,
              end: each.geometry.coordinates,
              endDescription: "Bang Kruai Town Municipality",
              capacity: [3],
            });
          }
        } else {
          // console.log("index", index);
          // console.log("vehicle", vehicle_name_list, vehicleUsed);
          // if append start or end
          let i;
          _.map(vehicleUsed, (e, ind) => {
            if (e.vehicle == each.properties.vehicle) {
              i = ind;
              return;
            }
          });
          if (each.properties.isStart) {
            vehicleUsed[i] = {
              ...vehicleUsed[i],
              start: each.geometry.coordinates,
              startDescription: "Bang Kruai Town Municipality",
            };
          } else {
            vehicleUsed[i] = {
              ...vehicleUsed[i],
              end: each.geometry.coordinates,
              endDescription: "dgerge",
            };
          }
        }
      }
    });
    setStoreJob(recordLoc);
    // console.log("vehicleUsed", vehicleUsed, jobs, recordLoc);
    if (vehicleUsed.length === 0) {
      return alert("Please assign a vehicle");
    }
    if (jobs.length === 0) {
      return alert("Please assign a drop");
    }
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3ACYfc5JXYCRXOjaqy8vMzK7076tig5ljt.m0192rdwXdwSTlrGb3e8S2FgP%2F728jrKCSSpksBaAVQ"
    );

    var raw = JSON.stringify({
      jobs,
      shipments: [],
      vehicles: vehicleUsed,
      options: { g: true },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    setLoading(true);
    fetch(
      "https://maps.powermap.live/api/v2/vrp?access_token=b378c575291af30a29f59919fd7e7e4c012d45c4",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setLoading(false);
        setState((state) => ({ ...state, routeOpt: result }));
      });
    // .catch((error) => console.log("error", error));
  };
  const handleClear = () => {
    // console.log(map);
    markerRef.current = {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    };
    map.current.getSource("routeOpt").setData({
      type: "FeatureCollection",
      features: [],
    });
    map.current.getSource("places").setData({
      type: "FeatureCollection",
      features: [],
    });
    setState((state) => ({
      ...state,
      routeOpt: null,
      markerLayer: {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [],
        },
      },
    }));
  };

  const updateOptions = (vehicle) => {
    let cloneOptions = [...state.options];
    cloneOptions.push({ name: vehicle });
    setState((state) => ({ ...state, options: cloneOptions }));
  };

  React.useEffect(() => {
    if (vehicleName) updateOptions(vehicleName);
  }, [vehicleName]);

  return (
    <Split
      direction="vertical"
      onDragEnd={() => {
        map.current && map.current.resize();
        // console.log("reset");
      }}
      style={{
        height: `calc(100vh)`,
        width: "100%",
        backgroundColor: "transparent",
      }}
    >
      <Split className="flex">
        <MapView
          map={map}
          state={state}
          setState={setState}
          markerRef={markerRef}
        />
      </Split>
      {isOpen && (
        <Modal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          vehicleName={vehicleName}
          setVehicleName={setVehicleName}
        />
      )}
      <div className="bg-white p-2">
        <div className="flex h-auto ml-16 z-10 space-x-2">
          <div className="w-60 rounded-lg p-2 shadow-lg space-y-1 ">
            <div className="flex justify-between pb-2">
              Vehicle
              <button onClick={() => setIsOpen(true)}>
                {" "}
                <GrAddCircle className="w-5 h-5 text-green-400" />
              </button>
            </div>
            {vehicleOnly.map((each) => vehicleCard({ each }))}
          </div>
          <div className="w-60 rounded-lg p-2 shadow-lg space-y-1 ">
            <div className="flex justify-between pb-2">Depot</div>
            {depotOnly.map((each) => depotCard({ each }))}
            <div className="flex justify-between pb-2">Stops </div>
            {dropOnly.map((each) => dropCard({ each }))}
          </div>
          <div className="w-full justify-between shadow-lg rounded-lg p-2 space-y-1 ">
            <div className="flex w-full justify-between pb-2">
              Calculate
              <div>
                <button onClick={handleSubmit}>
                  <GrSettingsOption className="w-5 h-5 text-green-500" />
                </button>{" "}
                <button onClick={handleClear}>
                  <TrashIcon className="w-5 h-5 text-gray-500" />
                </button>{" "}
              </div>
            </div>
            {!loading && (
              <div>
                {renderResult(state.routeOpt)}
                <div className="justify-between rounded-lg p-3 space-y-1 ">
                  {state.routeOpt &&
                    state.routeOpt.routes.map((each) => {
                      return <RouteResult route={each} storeJob={storeJob} />;
                    })}
                </div>
              </div>
            )}
            {loading && (
              <div className="flex justify-center items-center">loading...</div>
            )}
          </div>
        </div>
      </div>
    </Split>
  );
}

export default RouteOpt;
