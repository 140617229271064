import Table from "./Table";

export default function Vehicle() {
  return (
    <div className="h-screen w-sidebar ml-14 flex-col">
      <div className="overflow-y-scroll overflow-x-hidden">
        <Table />
      </div>
    </div>
  );
}
