import React from "react";
import { getCategoryApi } from "../utils/apis";

const Legend = ({ name, color }) => {
  return (
    <div className="flex flex-row">
      <img
        className="w-3"
        src={`data:image/svg+xml;charset=utf-8,<svg width="100" height="133.33424" viewBox="0 0 105 136.33424" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M 48.48894,2.0846666 C 45.345582,2.4764484 42.045821,3.0699806 39.971926,3.6166357 31.331372,5.8941857 23.70167,10.287001 17.343929,16.644739 7.5307186,26.457951 2.26971,39.565851 2.6992958,53.1321 c 0.2096929,6.62207 1.4945831,12.285322 4.2570658,18.763368 3.8625134,9.057619 8.9221174,16.765286 21.9823994,33.487402 9.794726,12.54096 13.702902,17.89379 17.704361,24.24874 1.304226,2.0713 2.77134,4.14006 3.260261,4.59722 1.567811,1.46597 3.94258,1.46597 5.510391,0 0.48892,-0.45716 1.956035,-2.52592 3.26026,-4.59722 4.00146,-6.35495 7.909636,-11.70778 17.704362,-24.24874 C 89.438679,88.660754 94.498283,80.953087 98.360793,71.895468 102.97204,61.082067 103.87705,50.520165 101.12201,39.670835 98.886222,30.866339 94.481777,23.15329 87.973228,16.644739 80.532191,9.2037052 71.45767,4.5056208 60.997853,2.6789833 58.10893,2.1744806 50.622249,1.8187766 48.48894,2.0846666 Z" 
                        fill="${color}"/><style>
                        .number { font: bold 50px sans-serif; fill: white;}
                      </style>
                      <text x="50%" y="50%" dominant-baseline="start" text-anchor="middle" class="number">★</text></svg>`}
        alt="icon"
      />
      <p className="px-1 text-sm">{name}</p>
    </div>
  );
};

function LegendGroup() {
  const [category, setCategory] = React.useState();
  const getCategory = async () => {
    let allCategory = await getCategoryApi();
    setCategory(allCategory.data);
  };

  React.useEffect(() => {
    getCategory();
  }, []);

  if (!category) {
    return null;
  } else {
    return (
      <div className="flex flex-col bg-white bg-opacity-80 rounded-lg p-1 font-jamjuree font-medium">
        {category.map((cate, index) => (
          <Legend
            key={index}
            name={`${cate.attributes.cate_e}`}
            color={`%23${cate.attributes.hex}`}
          ></Legend>
        ))}
      </div>
    );
  }
}

export default LegendGroup;
