import { RedocStandalone } from "@incognia/redoc";

const Docs = () => {
  return (
    <div className="ml-14 overflow-y-scroll w-auto">
      <RedocStandalone
        specUrl="./api_doc/powermap_api.json"
        options={{
          nativeScrollbars: true,
          hideFab: false,
          hideLoading: true,
          theme: {
            colors: {
              primary: { main: "#dd5522" },
            },
          },
          sidebar: {
            width: "600px",
            textColor: "#000000",
          },
        }}
      />
    </div>
  );
};

export default Docs;
