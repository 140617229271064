import React from "react";
import { addVehicle, updateVehicle } from "../utils";
import { toast } from "react-toastify";
import _ from "lodash";
import { BsSave } from "react-icons/bs";

const types = [
  {
    id: 0,
    name_th: "2 ล้อ",
  },
  {
    id: 1,
    name_th: "4 ล้อ",
  },
  {
    id: 2,
    name_th: "6 ล้อ",
  },
  {
    id: 3,
    name_th: "8 ล้อ",
  },
  {
    id: 4,
    name_th: "10 ล้อ",
  },
];

function EditTable({ data, refetch, setShowTable }) {
  console.log(data);
  const [pointEdit, setPointEdit] = React.useState(data);
  React.useEffect(async () => {
    if (data.id) {
      setPointEdit({
        ...data,
        rp_company: localStorage.getItem("company_id")
          ? parseInt(localStorage.getItem("company_id"))
          : parseInt(sessionStorage.getItem("company_id")),
        status: "waiting",
      });
    } else {
      setPointEdit({
        ...data,
        type: "4 ล้อ",
        rp_company: localStorage.getItem("company_id")
          ? parseInt(localStorage.getItem("company_id"))
          : parseInt(sessionStorage.getItem("company_id")),
        status: "waiting",
      });
    }
  }, [data]);

  const handleSavePoint = async () => {
    let pointEditResponse = null;
    if (data.id) {
      console.log("update");
      pointEditResponse = await updateVehicle(data.id, pointEdit);
    } else {
      console.log("add");
      pointEditResponse = await addVehicle(pointEdit);
    }
    if (pointEditResponse && pointEditResponse.status) {
      refetch();
      setShowTable(false);
      toast.info("Save successful");
    } else {
      setShowTable(false);
      return toast.error(pointEditResponse.message);
    }
  };

  if (!pointEdit) {
    return <p>Loading...</p>;
  }

  return (
    <div className="flex flex-col w-full">
      <div className="h-auto px-4">
        <div className="w-full bg-white rounded-lg border border-gray-200 shadow-md sm:p-6 lg:p-8 dark:bg-gray-800 dark:border-gray-700">
          <div className="flex justify-end">
            <button
              onClick={handleSavePoint}
              className="flex items-center justify-between p-2 space-x-2 w-20 tracking-wide text-white transition-colors duration-200 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50"
            >
              <BsSave className="w-5 h-5"></BsSave>
              <p>บันทึก</p>
            </button>
          </div>
          <div className="py-1 mt-3">
            <label
              htmlFor="plate_no"
              className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              ทะเบียนรถ
            </label>
            <input
              type="text"
              name="plate_no"
              id="plate_no"
              value={pointEdit.plate_no}
              placeholder=""
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              required
              onChange={async (e) =>
                setPointEdit({
                  ...pointEdit,
                  plate_no: e.target.value,
                })
              }
            ></input>
          </div>
          <div className="py-1">
            <label
              htmlFor="type"
              className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              ประเภทรถ
            </label>
            <select
              value={pointEdit.type}
              className="form-select appearance-none block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white focus:text-gray-700 focus:bg-white dark:focus:text-white dark:focus:bg-gray-600 focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
              onChange={async (e) =>
                setPointEdit({ ...pointEdit, type: e.target.value })
              }
            >
              <option value="2 ล้อ">2 ล้อ</option>
              <option value="4 ล้อ">4 ล้อ</option>
              <option value="6 ล้อ">6 ล้อ</option>
              <option value="8 ล้อ">8 ล้อ</option>
              <option value="≥ 10 ล้อ">≥ 10 ล้อ</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditTable;
