import React from "react";
import { FaRoute, FaFileExport } from "react-icons/fa";
import { BsCalculator, BsTrash, BsSearch } from "react-icons/bs";
import { MdOutlineSaveAlt } from "react-icons/md";
import { toast } from "react-toastify";
import Task from "../components/Task";
import _ from "lodash";
import ListMarkers from "./ListMarkers";
import maplibregl from "maplibre-gl";
import * as MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as turf from "@turf/turf";
import "maplibre-gl/dist/maplibre-gl.css";
import "./map.css";
import { Context } from "../store";
import moment from "moment";
import { loadMap, getOrigin } from "../utils";
import {
  searchPeriod,
  saveFav,
  saveOpt,
  getPlan,
  updateOpt,
} from "../utils/apis";
import { getCategoryApi } from "../utils/apis";
import { addpoint } from "../utils";
import { getRoute, renderRoute } from "../utils/vrpApis";
import { formatting } from "../utils/shipmentFormat";
import OptResult from "./OptimizeOutput";
import SearchBox from "./SearchAddress";
import Dialog from "../components/Dialog";
import SearchOriginPlace from "../components/SearchOriginPlace";
import { ExportXLSX } from "./ExportExcel";

let draw = new MapboxDraw({
  displayControlsDefault: false,
  // Select which mapbox-gl-draw control buttons to add to the map.
  controls: {
    polygon: true,
    trash: true,
  },
});

var el = document.createElement("div");
el.className = "marker";
el.style.backgroundImage = "url(./pinpoint/snap.png)";
el.style.width = "30px";
el.style.height = "40px";
el.style.backgroundSize = "contain";
el.style.backgroundRepeat = "no-repeat";

var marker = new maplibregl.Marker({
  element: el,
  draggable: false,
});

export default function MapCluster() {
  const mapContainer = React.useRef(null);
  const [showTable, setShowTable] = React.useState(true);
  const map = React.useRef(null);
  const [lng] = React.useState(100.514103509131);
  const [lat] = React.useState(13.773507201767668);
  const [zoom] = React.useState(7);
  const dataRef = React.useRef([]);
  const dataOriRef = React.useRef([]);
  const periodRef = React.useRef([]);
  const addRef = React.useRef(false);
  const [data, setData] = React.useState([]);
  const [state, setState] = React.useContext(Context);
  const [markerGroup, setMarkerGroup] = React.useState({});
  const [optimizeResult, setOptimizeResult] = React.useState();
  const [showSearchBox, setShowSearchBox] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState(false);
  const [originPlace, setOriginPlace] = React.useState();
  const [pointData, setPointData] = React.useState();
  const [plateList, setPlateList] = React.useState([]);
  const [favTitles, setFavTitles] = React.useState("");
  const [plan, setPlan] = React.useState();
  const [category, setCategory] = React.useState();

  const getCategory = async () => {
    let allCategory = await getCategoryApi();
    let formatCate = _.map(allCategory.data, (item, index) => ({
      id: index,
      name: item.attributes.cate_t,
    }));
    setCategory(formatCate);
  };

  const getInitialDropPoint = async () => {
    if (state.periodDate) {
      let searchResponse = await searchPeriod(state.periodDate);
      if (searchResponse.data.length === 0) {
        clearData();
      } else {
        // console.log(searchResponse.data);
        getInitialMarker(searchResponse.data);
      }
    } else {
      toast.error("Get period error");
      // console.log(`You must provide period date`);
    }
  };

  const updateMap = () => {
    if (map.current.getSource("places")) {
      map.current.getSource("places").setData({
        type: "FeatureCollection",
        features: dataRef.current,
      });
      setData(dataRef.current);
      setShowTable(true);
    }
    if (map.current.getSource("origins")) {
      map.current.getSource("origins").setData({
        type: "FeatureCollection",
        features: dataOriRef.current,
      });
    }
  };

  const getInitialMarker = (e) => {
    let initialMarker = _.map(e, (item, index) => {
      return {
        type: "Feature",
        properties: {
          init_id: item.id,
          index: index,
          category: item.attributes.category,
          origin_place: item.attributes.origin_place,
          origin_datetime: item.attributes.origin_datetime,
          destination_place: item.attributes.destination_place,
          destination_datetime: item.attributes.destination_datetime,
          province: item.attributes.province,
          remark: item.attributes.remark,
          shipper: item.attributes.shipper,
          // vehicle_type: item.attributes.vehicle_type,
          // vehicle_order: item.attributes.vehicle_order,
          // plate_no: item.attributes.plate_no,
          latitude: item.attributes.latitude,
          longitude: item.attributes.longitude,
          icon: "destination_marker",
          destination: item.attributes.destination,
          origins: item.attributes.origins,
          origin_time_to: item.attributes.origin_time_to,
          dest_time_to: item.attributes.dest_time_to,
          branch_id: item.attributes.branch_id,
        },
        geometry: {
          type: "Point",
          coordinates: [item.attributes.longitude, item.attributes.latitude],
        },
      };
    });
    dataRef.current = initialMarker;
    let Origins = getOrigin(dataRef.current);
    setState((state) => ({ ...state, origins: Origins }));
    dataOriRef.current = Origins;
    // console.log(dataRef.current, dataOriRef.current);
    updateMap();
  };

  // const setNewMarker = (e) => {
  //   // console.log(e);
  //   let today = moment();
  //   let newMarker = {
  //     type: "Feature",
  //     properties: {
  //       init_id: null,
  //       index: dataRef.current.length,
  //       category: "ลูกค้า " + (dataRef.current.length + 1),
  //       origin_place: "จุดรับที่ " + (dataRef.current.length + 1),
  //       origin_datetime: periodRef.current,
  //       origin_time_to: periodRef.current,
  //       destination_place: "จุดส่งที่ " + (dataRef.current.length + 1),
  //       destination_datetime: periodRef.current,
  //       dest_time_to: periodRef.current,
  //       province: "กรุงเทพมหานคร",
  //       remark: "-",
  //       shipper: "-",
  //       vehicle_type: "4 ล้อ",
  //       vehicle_order: "-",
  //       plate_no: "-",
  //       latitude: e.lngLat.lat,
  //       longitude: e.lngLat.lng,
  //       icon: "destination_marker",
  //       // isStart: false,
  //       // isEnd: false,
  //       // isDrop: true,
  //     },
  //     geometry: {
  //       type: "Point",
  //       coordinates: [e.lngLat.lng, e.lngLat.lat],
  //     },
  //   };
  //   dataRef.current = [...dataRef.current, newMarker];
  // };

  const handlePlateChange = (plate, ind) => {
    // get current index of plate change
    let clonePlateList = [...plateList];
    // if plate[0] already exist ????
    if (clonePlateList.includes(plate[0])) {
      // console.log(plate[0]);
      toast.info("เลือกทะเบียนรถซ้ำ กรุณาเลือกใหม่");
      clonePlateList[ind] = "";
      // console.log(clonePlateList, "");
      setPlateList(clonePlateList);
      return;
    } else {
      clonePlateList[ind] = plate[0];
      // console.log(clonePlateList, plate[0]);
      setPlateList(clonePlateList);
    }
  };

  const clearData = () => {
    dataRef.current = [];
    setData([]);
    updateMap();
  };

  const removePoint = (data) => {
    // console.log(dataRef, data.properties.index);
    let newRefData = _.filter(
      dataRef.current,
      (item) => item.properties.index !== data.properties.index
    );
    // update index

    let newIndexData = _.map(newRefData, (item, index) => ({
      ...item,
      properties: {
        ...item.properties,
        index,
        icon: "destination_marker",
      },
    }));

    dataRef.current = newIndexData;
    updateMap();
  };

  const updateMapMarker = () => {
    let new_marker = _.map(dataRef.current, (item) => {
      // console.log(item);
      if (item.properties.index === state.selected_marker.properties.index) {
        // console.log(
        //   item.properties.index,
        //   state.selected_marker.properties.index
        // );
        return {
          ...item,
          properties: {
            ...item.properties,
            latitude: state.selecteddestinationplace.latitude,
            longitude: state.selecteddestinationplace.longitude,
          },
          geometry: {
            ...item.geometry,
            coordinates: [
              state.selecteddestinationplace.longitude,
              state.selecteddestinationplace.latitude,
            ],
          },
        };
      } else {
        return item;
      }
    });
    dataRef.current = new_marker;
    updateMap();
    // console.log(new_marker);
  };

  const handleConfirmOrigin = async () => {
    // console.log(pointData);
    if (!pointData.destination_place) {
      return toast.error("Please input name");
    }
    let searchResponse = await searchPeriod(periodRef.current);
    if (searchResponse.meta.pagination.total === dataRef.current.length) {
      let pointDataResponse = await addpoint(pointData);
      // console.log(pointData);
      if (pointDataResponse && pointDataResponse.status) {
        // console.log("toast");
        toast.info("Save successful");
      } else {
        return toast.error(pointDataResponse.message);
      }
      // update marker
      getInitialDropPoint();
      updateMap();
      setShowTable(true);
      setShowDialog(false);
    } else {
      toast.info("Please add destination and origin of task first");
    }
  };

  React.useEffect(() => {
    if (map.current) return;
    map.current = new maplibregl.Map({
      container: mapContainer.current,
      style: `https://maps.powermap.live/api/v2/map/vtile/styles?name=thailand_th_black&access_token=b378c575291af30a29f59919fd7e7e4c012d45c4`,
      center: [lng, lat],
      zoom: zoom,
    });
    loadMap(map.current, updateMap);
    map.current.on("click", async function (e) {
      // only if add state action
      if (addRef && addRef.current) {
        // check if all marker save
        setPointData();
        setShowDialog(true);
        setShowTable(false);

        // add marker to map for draggable
        // console.log(e.lngLat.lng, e.lngLat.lat);
        marker.setLngLat([e.lngLat.lng, e.lngLat.lat]).addTo(map.current);
      }
    });

    map.current.addControl(new maplibregl.NavigationControl(), "top-left");
    // Add geolocate control to the map.
    map.current.addControl(
      new maplibregl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
      }),
      "top-left"
    );
    map.current.addControl(draw, "top-left");
    // map.current.addControl(new maplibregl.ScaleControl(), "bottom-left");
    map.current.on("draw.create", updateSelect);
    map.current.on("draw.delete", updateSelect);
    map.current.on("draw.update", updateSelect);
    map.current.on("draw.modechange", updateMode); // when start drawing
    setState({ ...state, map: map.current });
  });

  const updateMode = (e) => {
    // console.log(e);
    // check if alldata is not saved
    addRef.current = false;
    setState((state) => ({ ...state, switch: false }));
  };
  const updateSelect = () => {
    const data = draw.getAll();
    // group data ref
    let groupMarker = {};
    // have polygons
    data.features.map((poly, index) => {
      var poly = turf.polygon([poly.geometry.coordinates[0]]);
      groupMarker[index] = [];
      dataRef.current.map((each) => {
        var pt = turf.point(each.geometry.coordinates);
        let isInside = turf.booleanPointInPolygon(pt, poly);
        if (isInside) {
          groupMarker[index].push(each);
        }
      });
    });
    // update current dataRef icon
    if (!_.isEmpty(groupMarker)) {
      let newIndex = Object.keys(groupMarker).length;
      groupMarker[newIndex] = [];
      let mergeGroup = _.flatten(Object.values(groupMarker));
      let allSelectMarkers = _.map(mergeGroup, (item) => item.properties.index);
      let newDataRef = _.map(dataRef.current, (each, ind) => {
        if (allSelectMarkers.includes(each.properties.index)) {
          return {
            ...each,
            properties: {
              ...each.properties,
              icon: "selected_marker",
            },
          };
        } else {
          groupMarker[newIndex].push(each);
          return {
            ...each,
            properties: {
              ...each.properties,
              icon: "destination_marker",
            },
          };
        }
      });
      // remove empty groups from
      Object.keys(groupMarker).map((key) => {
        if (groupMarker[key].length === 0) {
          delete groupMarker[key];
        }
      });
      dataRef.current = newDataRef;
    } else {
      let newDataRef = _.map(dataRef.current, (each, ind) => {
        return {
          ...each,
          properties: {
            ...each.properties,
            icon: "destination_marker",
          },
        };
      });
      dataRef.current = newDataRef;
    }

    setMarkerGroup(groupMarker);
    updateMap();
  };

  const handleOptimize = async () => {
    let formattedData = formatting(dataRef.current, markerGroup, state.origins);
    // console.log("optimization route", formattedData);
    // TODO mutiple route
    let allRoutes = _.map(formattedData, async (each) => await getRoute(each));
    let routes = await Promise.all(allRoutes);
    let mergeRoute = [];
    _.map(routes, (eRoute) => {
      mergeRoute.push(eRoute.routes[0]);
    });
    setOptimizeResult(mergeRoute);
    setPlateList(new Array(mergeRoute.length).fill(""));
    renderRoute(mergeRoute, map);
  };

  const handleClear = async () => {
    if (map.current.getSource("routeOpt")) {
      map.current.getSource("routeOpt").setData({
        type: "FeatureCollection",
        features: [],
      });
    }
    setOptimizeResult(null);
    setPlateList([]);
  };

  const handleAddDestination = (e) => {
    // console.log(e);
    marker
      .setLngLat([e.attributes.longitude, e.attributes.latitude])
      .addTo(map.current);
    setPointData({
      ...pointData,
      destination: e.id,
      branch_id: e.attributes.branch_id,
      destination_place: e.attributes.name_t,
      category: e.attributes.cate_t,
      latitude: e.attributes.latitude,
      longitude: e.attributes.longitude,
      origin_datetime: state.periodDate,
      province: e.attributes.province_t,
    });
    // console.log(e.attributes.cate_t);
    // console.log(pointData);
    setShowDialog(true);
    setShowTable(false);
  };

  const handleExport = () => {
    // check plate number of each groups
    // console.log(plateList);
    let noPlateList = _.some(plateList, (item) => !item);
    if (noPlateList) {
      return toast.error("Please fill all plate no before export");
    }
    ExportXLSX(optimizeResult, plateList);
  };

  const handleSave = async () => {
    // console.log("handle save");
    // console.log("save fave", markerGroup || dataRef.current);
    if (!optimizeResult) {
      return toast.error("No optimize result to save");
    }

    let noPlateList = _.some(plateList, (item) => !item);
    if (noPlateList) {
      return toast.error("Please fill all plate no before export");
    }

    let data = {
      Date: new moment(state.periodDate).format("YYYY-MM-DD"),
      allMarkers: dataRef.current,
      markerGroup: !_.isEmpty(markerGroup) ? markerGroup : dataRef.current,
      vehicles: plateList,
      optResult: optimizeResult,
      rp_company: localStorage.getItem("company_id")
        ? parseInt(localStorage.getItem("company_id"))
        : parseInt(sessionStorage.getItem("company_id")),
    };
    // check if already save
    if (plan) {
      let updateResult = await updateOpt(plan.data[0].id, data);
      if (updateResult.status === 200) {
        // setState((state) => ({ ...state, save: false }));
        return toast.success("Save to favorite successfully");
      } else {
        let error = await updateResult.json();
        return toast.error("Error: " + error.error.message);
      }
    } else {
      let saveResult = await saveOpt(data);
      if (saveResult.status === 200) {
        let result = await saveResult.json();
        setPlan(result);
        // setState((state) => ({ ...state, save: false }));
        return toast.success("Save to favorite successfully");
      } else {
        let error = await saveResult.json();
        return toast.error("Error: " + error.error.message);
      }
    }
  };

  const handleAddFavor = (data) => {
    // console.log(markerGroup);
    if (!_.isEmpty(markerGroup)) {
      return toast.error(
        "Cannot add favorite to group please remove group first"
      );
    }
    // handleAddFavor
    let mergeData = [...dataRef.current, ...data.attributes.data];
    let newOrigin = getOrigin(mergeData);
    dataRef.current = mergeData;
    dataOriRef.current = newOrigin;
    setState((state) => ({ ...state, origins: newOrigin }));
    updateMap();
  };

  const handleOpenPlan = () => {
    // TODO add group and clear group
    // console.log(plan);
    setOptimizeResult(plan.data[0].attributes.optResult);
    setPlateList(plan.data[0].attributes.vehicles);
    // setMarkerGroup({plan.data[0].attributes.markerGroup});
    renderRoute(plan.data[0].attributes.optResult, map);
    updateMap();
  };

  React.useEffect(() => {
    addRef.current = state.switch;
  }, [state.switch]);

  React.useEffect(() => {
    // console.log("calls", state.periodDate);
    if (state.periodDate) {
      getInitialDropPoint();
      periodRef.current = state.periodDate;
      dataOriRef.current = [];
      handleClear();
      updateMap();
    }
  }, [state.periodDate]);

  const getTodayPlan = async () => {
    let todayPlan = await getPlan(
      new moment(state.periodDate).format("YYYY-MM-DD")
    );
    // console.log(todayPlan);
    if (todayPlan.data.length === 0) {
      return setPlan();
    }
    setPlan(todayPlan);
  };
  const handleSaveFav = async (data, ind) => {
    // console.log("save fave", markerGroup[ind] || dataRef.current);
    let dataFav = {
      name: favTitles,
      data: markerGroup[ind] || dataRef.current,
      rp_company: localStorage.getItem("company_id")
        ? parseInt(localStorage.getItem("company_id"))
        : parseInt(sessionStorage.getItem("company_id")),
    };
    // console.log(dataFav);
    let saveResult = await saveFav(dataFav);
    if (saveResult.status === 200) {
      setState((state) => ({ ...state, saveFav: false }));
      setShowDialog(false);
      return toast.success("Save to favorite successfully");
    } else {
      let error = await saveResult.json();
      setShowDialog(false);
      return toast.error("Error: " + error.error.message);
    }
  };

  React.useEffect(() => {
    // console.log("React.useEffect", state.selecteddestinationplace);
    if (state.selecteddestinationplace) {
      updateMapMarker();
    }
  }, [state.selecteddestinationplace]);

  React.useEffect(() => {
    getCategory();
    periodRef.current = state.periodDate;
    getInitialDropPoint();
  }, []);

  React.useEffect(() => {
    getTodayPlan();
    if (!plan) {
      setMarkerGroup({});
    }
  }, [state.periodDate]);

  React.useEffect(() => {
    var lngLat = marker.getLngLat();
    if (state.selectedoriginplace && lngLat) {
      let name = _.map(state.selectedoriginplace, (item) => item.name);
      let place_ids = _.map(state.selectedoriginplace, (item) => item.id);
      setPointData({
        ...pointData,
        origin_place: name.join("|"),
        origins: place_ids,
        latitude: lngLat.lat,
        longitude: lngLat.lng,
        origin_datetime: state.periodDate,
        created_id: localStorage.getItem("userid")
          ? parseInt(localStorage.getItem("userid"))
          : parseInt(sessionStorage.getItem("userid")),
        rp_company: localStorage.getItem("company_id")
          ? parseInt(localStorage.getItem("company_id"))
          : parseInt(sessionStorage.getItem("company_id")),
      });
    }
  }, [state.selectedoriginplace, marker]);

  // console.log(plan, markerGroup);

  return (
    <div className="map-wrap">
      <div ref={mapContainer} className="map" />
      <button
        className="fixed top-4 right-4 z-10 bg-white rounded-full p-3 shadow-md"
        onClick={() => {
          setShowTable(true);
          // loadMap(map.current);
        }}
      >
        <FaRoute className="w-6 h-6" />
      </button>
      {state.saveFav && (
        <Dialog
          isOpen={state.saveFav}
          handleCancel={(e) => {
            setState((state) => ({ ...state, saveFav: false }));
          }}
          handleConfirm={handleSaveFav}
          title={"Confirm save favorite"}
          message={"Save this route for later planning?"}
        >
          <input
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
            onChange={(e) => setFavTitles(e.target.value)}
          ></input>
        </Dialog>
      )}
      {showDialog && (
        <div className="h-84">
          <Dialog
            isOpen={showDialog}
            setIsOpen={setShowDialog}
            handleConfirm={handleConfirmOrigin}
            handleCancel={() => {
              setShowDialog(false);
              marker.remove();
            }}
            title={"Select Origin"}
            message={"Where this trip start from?"}
          >
            <div className="flex flex-col space-y-4">
              <div className="flex flex-row space-x-2">
                <div className="flex w-1/2">
                  <SearchOriginPlace
                    origin_place={originPlace}
                  ></SearchOriginPlace>
                </div>
                <div className="flex w-1/2 items-start">
                  <select
                    value={(pointData && pointData.category) || "ลูกค้า"}
                    className="form-select w-full px-3 py-1 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded-lg transition ease-in-out mt-1 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white focus:text-gray-700 focus:bg-white dark:focus:text-white dark:focus:bg-gray-600 focus:border-blue-600 focus:outline-none"
                    aria-label="cate_t"
                    onChange={async (e) =>
                      setPointData({ ...pointData, category: e.target.value })
                    }
                  >
                    {category.map((value, index) => (
                      <option key={index} value={value.name}>
                        {value.name}
                      </option>
                    ))}
                    {/* <option value="อาราบิเทีย">อาราบิเทีย</option>
                    <option value="ซีพี เฟรชมาร์ท">ซีพี เฟรชมาร์ท</option>
                    <option value="ลูกค้า">ลูกค้า</option>
                    <option value="ศูนย์กระจายสินค้า">ศูนย์กระจายสินค้า</option>
                    <option value="จังเกิล คาเฟ่">จังเกิล คาเฟ่</option>
                    <option value="ร้านขายดี">ร้านขายดี</option>
                    <option value="ตู้แช่แข็ง">ตู้แช่แข็ง</option>
                    <option value="อะไหล่">อะไหล่</option> */}
                  </select>
                </div>
              </div>
              <div className="flex flex-col">
                <label className="block mb-1 text-sm text-gray-500">
                  Destination
                </label>
                <input
                  id="destination_place"
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Your destination..."
                  value={(pointData && pointData.destination_place) || ""}
                  onChange={async (e) =>
                    setPointData({
                      ...pointData,
                      destination_place: e.target.value,
                    })
                  }
                ></input>
              </div>
              <div className="flex flex-col">
                <label className="block mb-1 text-sm text-gray-500">
                  Remarks
                </label>
                <textarea
                  id="remark"
                  rows="4"
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Your message..."
                  onChange={async (e) =>
                    setPointData({
                      ...pointData,
                      remark: e.target.value,
                    })
                  }
                ></textarea>
              </div>
            </div>
          </Dialog>
        </div>
      )}
      <div className="flex">
        {showTable && (
          <Task
            open={showTable}
            setOpen={setShowTable}
            width="w-96"
            layout="right"
          >
            <div className="flex flex-col h-auto min-h-screen pt-3 bg-opacity-95 bg-gray-900">
              <ListMarkers
                data={data}
                clearNewData={getInitialDropPoint}
                removePoint={removePoint}
                mapRef={map.current}
                markerGroup={markerGroup}
                handleAddFavor={handleAddFavor}
                handleOpenPlan={handleOpenPlan}
                plan={plan}
              />
              {dataRef.current.length !== 0 && state.origins && !state.isEdit && (
                <div className="flex justify-end space-x-2 mr-4 mt-2">
                  <button
                    title="Save"
                    className="relative inline-flex p-2 rounded-lg justify-between items-center bg-green-800 dark:bg-dark text-white"
                    onClick={handleSave}
                  >
                    {!plan && (
                      <>
                        <span className="absolute -top-1 -left-1 h-2.5 w-2.5 animate-ping rounded-full bg-green-600/75"></span>
                        <span className="absolute -top-1 -left-1 h-2.5 w-2.5 rounded-full bg-green-600"></span>
                      </>
                    )}
                    <MdOutlineSaveAlt className="w-4 h-4" />
                  </button>
                  {optimizeResult && (
                    <button
                      title="Export"
                      className="p-2 rounded-lg flex justify-between items-center bg-green-800 dark:bg-dark text-white"
                      onClick={handleExport}
                    >
                      <FaFileExport className="w-4 h-4" />
                    </button>
                  )}
                  <button
                    title="Calculate"
                    className="p-2 rounded-lg flex justify-between items-center bg-green-800 dark:bg-dark text-white"
                    onClick={handleOptimize}
                  >
                    <BsCalculator className="w-4 h-4" />
                  </button>
                  <button
                    title="Remove"
                    className="p-2 rounded-lg flex justify-between items-center bg-gray-500 dark:bg-dark text-white"
                    onClick={handleClear}
                  >
                    <BsTrash className="w-4 h-4" />
                  </button>
                </div>
              )}

              {optimizeResult && (
                <div className="text-gray-500">
                  <OptResult
                    data={data}
                    markerGroup={markerGroup}
                    optimizeResult={optimizeResult}
                    mapRef={map.current}
                    handlePlateChange={handlePlateChange}
                    plateList={plateList}
                  />
                </div>
              )}
            </div>
          </Task>
        )}
      </div>
      <>
        <button
          className="fixed bottom-4 left-16 z-10 bg-white rounded-full p-3 shadow-md"
          onClick={() => setShowSearchBox(true)}
        >
          <BsSearch className="w-6 h-6" />
        </button>
      </>
      {showSearchBox && (
        <Task
          open={showSearchBox}
          setOpen={setShowSearchBox}
          width="w-[300px]"
          layout="left"
        >
          <div className="flex flex-col h-screen">
            <SearchBox addDestination={handleAddDestination} />
          </div>
        </Task>
      )}
    </div>
  );
}
