import _ from "lodash";
import polyline from "@mapbox/polyline";

const routing_backend = "https://maps.powermap.live/api/v2/vrp";
const token = "b378c575291af30a29f59919fd7e7e4c012d45c4";

export const getRoute = async (data) => {
  // prepare data

  let results = await fetch(`${routing_backend}?access_token=${token}`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: { Accept: "application/json", "Content-Type": "application/json" },
  }).then((res) => res.json());
  return results;
};

export const renderRoute = async (routes, map) => {
  let geoJson = [];
  _.map(routes, (each, index) => {
    let geometry = polyline.toGeoJSON(each.geometry);
    geoJson.push({
      type: "Feature",
      properties: {
        route_id: index + 1,
        color: "#" + Math.floor(Math.random() * 16777215).toString(16),
        icon: "arrow",
      },
      geometry: geometry,
    });
  });
  if (!map.current.getSource("routeOpt")) {
    // Find the index of the first symbol layer in the map style
    map.current.addSource("routeOpt", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: geoJson,
      },
    });

    // add outline layer
    map.current.addLayer(
      {
        id: "my-route-layer-outline",
        type: "line",
        source: "routeOpt", // <= the same source id
        layout: {
          "line-cap": "round",
          "line-join": "round",
        },
        paint: {
          "line-color": ["get", "color"],
          "line-width": 12,
        },
      },
      "places"
    );

    // add route layer
    map.current.addLayer(
      {
        id: "my-route-layer",
        type: "line",
        source: "routeOpt", // <= the same source id
        layout: {
          "line-cap": "round",
          "line-join": "round",
        },
        paint: {
          "line-color": "#6084eb",
          "line-width": 10,
        },
      },
      "places"
    );
    // add direction
    map.current.addLayer({
      id: "my-route-layer-symbol",
      type: "symbol",
      source: "routeOpt",
      layout: {
        "icon-image": ["get", "icon"],
        "symbol-placement": "line",
        "icon-size": 1,
        "icon-rotate": 90,
        "symbol-spacing": 20,
        "icon-rotation-alignment": "map",
        "icon-allow-overlap": true,
        "icon-ignore-placement": true,
      },
    });
  } else {
    map.current.getSource("routeOpt").setData({
      type: "FeatureCollection",
      features: geoJson,
    });
  }
};
