import React, { useRef, useEffect, useState } from "react";
import maplibregl from "maplibre-gl";
import Table from "./Table";
import "maplibre-gl/dist/maplibre-gl.css";
import { loadMap, styleLoad } from "../utils";
import Satellite from "./SatelliteStyle";
import Legend from "./Legend";
import Switch from "./MapSwitch";
import SearchBox from "./SearchAddress";
import { Context } from "../store";
import Task from "./Task";
import { BsSearch } from "react-icons/bs";

var el = document.createElement("div");
el.className = "marker";
el.style.backgroundImage = "url(./pinpoint/snap.png)";
el.style.width = "30px";
el.style.height = "40px";
el.style.backgroundSize = "contain";
el.style.backgroundRepeat = "no-repeat";

var marker = new maplibregl.Marker({
  element: el,
  draggable: false,
});

export default function Map() {
  const mapContainer = useRef(null);
  const mapRef = useRef(null);
  const [state, setState] = React.useContext(Context);
  const [active, setActive] = React.useState("satellite");
  const [map, setMap] = useState(null);
  const [lng] = useState(100.5336154);
  const [lat] = useState(13.7460966);
  const [zoom] = useState(16);
  const [showSearchBox, setShowSearchBox] = React.useState(false);

  const handleAddDropPoint = (e) => {
    // console.log(e);
    marker
      .setLngLat([e.attributes.longitude, e.attributes.latitude])
      .addTo(mapRef.current);
  };

  useEffect(() => {
    if (mapRef.current) return;
    mapRef.current = new maplibregl.Map({
      container: mapContainer.current,
      style: Satellite,
      center: [lng, lat],
      zoom: zoom,
    });
    mapRef.current.addControl(new maplibregl.NavigationControl(), "top-left");
    // Add geolocate control to the map.
    mapRef.current.addControl(
      new maplibregl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
      }),
      "top-left"
    );
    setState({ ...state, mapRef: mapRef.current });
  });

  React.useEffect(() => {
    setMap(mapRef.current);
  }, [mapRef.current]);

  React.useEffect(() => {
    if (map) {
      loadMap(map);
      map.on("style.load", () => {
        styleLoad(mapRef.current);
      });
    }
  }, [map]);

  React.useEffect(() => {
    if (map) {
      if (active === "street") {
        mapRef.current.setStyle(
          "https://maps.powermap.live/api/v2/map/vtile/styles?name=thailand_th_gray&access_token=b378c575291af30a29f59919fd7e7e4c012d45c4"
        );
      } else {
        map.setStyle(Satellite);
      }
    }
  }, [active, map]);

  return (
    <div className="h-screen w-sidebar ml-14 flex-col">
      <div ref={mapContainer} className="h-1/2" />
      <div className="fixed z-50 top-3 right-2">
        <Switch active={active} setActive={setActive} />
      </div>
      <div className="fixed z-50 bottom-1/2 mb-2 right-2">
        <Legend />
      </div>
      <>
        <button
          className="fixed z-50 bottom-1/2 mb-2 left-16 bg-white rounded-full p-3 shadow-md"
          onClick={() => setShowSearchBox(true)}
        >
          <BsSearch className="w-6 h-6" />
        </button>
      </>
      {showSearchBox && (
        <Task
          open={showSearchBox}
          setOpen={setShowSearchBox}
          width="w-[300px]"
          layout="left"
        >
          <div className="flex flex-col h-screen">
            <SearchBox addDestination={handleAddDropPoint} />
          </div>
        </Task>
      )}
      {map && (
        <div className="h-1/2 overflow-y-scroll overflow-x-hidden">
          <Table map={mapRef.current} />
        </div>
      )}
    </div>
  );
}
