import moment from "moment";
import _ from "lodash";
import { backendUrl } from "./backend";

let server = "https://maps.powermap.live/api/v2/map";
let token = "b378c575291af30a29f59919fd7e7e4c012d45c4";

const regionDetails = {
  north: [
    "เชียงราย",
    "เชียงใหม่",
    "น่าน",
    "พะเยา",
    "แพร่",
    "แม่ฮ่องสอน",
    "ลำปาง",
    "ลำพูน",
    "อุตรดิตถ์",
  ],
  northeast: [
    "กาฬสินธุ์",
    "ขอนแก่น",
    "ชัยภูมิ",
    "นครพนม",
    "นครราชสีมา",
    "บึงกาฬ",
    "บุรีรัมย์",
    "มหาสารคาม",
    "มุกดาหาร",
    "ยโสธร",
    "ร้อยเอ็ด",
    "เลย",
    "ศรีสะเกษ",
    "สกลนคร",
    "สุรินทร์",
    "หนองคาย",
    "หนองบัวลำภู",
    "อำนาจเจริญ",
    "อุดรธานี",
    "อุบลราชธานี",
  ],
  west: ["กาญจนบุรี", "ตาก", "ประจวบคีรีขันธ์", "เพชรบุรี", "ราชบุรี"],
  central: [
    "กรุงเทพมหานคร",
    "กำแพงเพชร",
    "ชัยนาท",
    "นครนายก",
    "นครปฐม",
    "นครสวรรค์",
    "นนทบุรี",
    "ปทุมธานี",
    "พระนครศรีอยุธยา",
    "พิจิตร",
    "พิษณุโลก",
    "เพชรบูรณ์",
    "ลพบุรี",
    "สมุทรปราการ",
    "สมุทรสงคราม",
    "สมุทรสาคร",
    "สระบุรี",
    "สิงห์บุรี",
    "สุโขทัย",
    "สุพรรณบุรี",
    "อ่างทอง",
    "อุทัยธานี",
  ],
  east: [
    "จันทบุรี",
    "ฉะเชิงเทรา",
    "ชลบุรี",
    "ตราด",
    "ปราจีนบุรี",
    "ระยอง",
    "สระแก้ว",
  ],
  south: [
    "กระบี่",
    "ชุมพร",
    "ตรัง",
    "นครศรีธรรมราช",
    "นราธิวาส",
    "ปัตตานี",
    "พังงา",
    "พัทลุง",
    "ภูเก็ต",
    "ยะลา",
    "ระนอง",
    "สงขลา",
    "สตูล",
    "สุราษฎร์ธานี",
  ],
};
export const getPOIsTH = async (term, coor) => {
  let results = await fetch(
    `${server}/address_th?q=${term}${
      coor ? "&coor=" + coor : ""
    }&access_token=${token}`
  ).then((res) => res.json());
  return results;
};
export const getPOIsEN = async (term, coor) => {
  let results = await fetch(
    `${server}/address_en?q=${term}${
      coor ? "&coor=" + coor : ""
    }&access_token=${token}`
  ).then((res) => res.json());
  return results;
};

export const getCategoryApi = async () => {
  let company_id = localStorage.company_id || sessionStorage.company_id;
  return await fetch(
    `${backendUrl}/api/rp-categories?filters[rp_company][id][$eq]=${company_id}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          localStorage.getItem("jwt") || sessionStorage.getItem("jwt")
        }`,
      },
    }
  )
    .then(async (res) => {
      // Handle success.
      return res.json();
    })
    .catch((error) => {
      // Handle error.
      // console.log("An error occurred:", error.response);
    });
};

export const searchByCategory = (searchData) => {
  let { coor, cate, dist } = searchData;
  return fetch(
    `${server}/cate?coor=${encodeURI(coor)}&type=${encodeURI(
      cate
    )}&access_token=${token}&dist=${dist}`
  ).then((res) => res.json());
};

export const searchNearBy = (searchData) => {
  let { coor, dist } = searchData;
  return fetch(
    `${server}/geo_search?coor=${encodeURI(
      coor
    )}&access_token=${token}&dist=${dist}`
  ).then((res) => res.json());
};

export const searchDropPoint = (searchData) => {
  let company_id = localStorage.company_id || sessionStorage.company_id;
  return fetch(
    `${backendUrl}/api/rp-droppoints?filters[rp_company][id][$eq]=${company_id}&filters[$or][0][name_t][$contains]=${searchData}&filters[$or][1][branch_id][$contains]=${searchData}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          localStorage.getItem("jwt") || sessionStorage.getItem("jwt")
        }`,
      },
    }
  ).then((res) => res.json());
};

export const searchOrigin = (searchData) => {
  let company_id = localStorage.company_id || sessionStorage.company_id;
  return fetch(
    `${backendUrl}/api/rp-droppoints?filters[rp_company][id][$eq]=${company_id}&filters[$or][0][name_t][$contains]=${searchData}&filters[$or][1][branch_id][$contains]=${searchData}&filters[cate_e][$eq]=DC`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          localStorage.getItem("jwt") || sessionStorage.getItem("jwt")
        }`,
      },
    }
  ).then((res) => res.json());
};

export const searchProvince = (searchData) => {
  return fetch(
    `${backendUrl}/api/provinces?filters[province_th][$contains]=${encodeURI(
      searchData
    )}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          localStorage.getItem("jwt") || sessionStorage.getItem("jwt")
        }`,
      },
    }
  ).then((res) => res.json());
};

export const searchPeriod = (date) => {
  let company_id = localStorage.company_id || sessionStorage.company_id;
  let startDate = moment(date)
    .subtract(7, "hours")
    .format("YYYY-MM-DD HH:00:00.000");
  let endDate = moment(date)
    .add(1, "days")
    .subtract(7, "hours")
    .format("YYYY-MM-DD HH:00:00.000");
  return fetch(
    `${backendUrl}/api/rp-tasks?filters[rp_company][id][$eq]=${company_id}&filters[$and][0][origin_datetime][$gte]=${encodeURI(
      startDate
    )}&filters[$and][1][origin_datetime][$lt]=${encodeURI(endDate)}&populate=*`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          localStorage.getItem("jwt") || sessionStorage.getItem("jwt")
        }`,
      },
    }
  ).then((res) => res.json());
};

export const getDrops = async (
  activePage,
  pageSize,
  typeSelected,
  regionSelected,
  statusSelected,
  searchBranch,
  searchName,
  searchProvinces
) => {
  let filterType = "";
  let company_id = localStorage.company_id || sessionStorage.company_id;
  if (typeSelected.id !== 1) {
    filterType = "&filters[cate_e][$eq]=" + typeSelected.name;
  }
  let filterBranch =
    searchBranch && "&filters[branch_id][$contains]=" + searchBranch;
  let filterName = searchName && "&filters[name_t][$contains]=" + searchName;
  let filterProvinces =
    searchProvinces && "&filters[province_t][$contains]=" + searchProvinces;
  let filterStatus = "";
  if (statusSelected.id !== 1) {
    filterStatus = "&filters[status][$eq]=" + statusSelected.name;
  }
  let filterRegion = "";
  if (regionSelected.id !== 1) {
    _.map(regionDetails[regionSelected.name], (prov, ind) => {
      filterRegion += `&filters[province_t][$in][${ind}]=` + prov;
    });
  }
  // console.log(filterRegion);

  return await fetch(
    `${backendUrl}/api/rp-droppoints?filters[rp_company][id][$eq]=${company_id}&pagination[page]=${activePage}&pagination[pageSize]=${pageSize}${filterType}${filterStatus}${filterBranch}${filterName}${filterProvinces}${filterRegion}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          localStorage.getItem("jwt") || sessionStorage.getItem("jwt")
        }`,
      },
    }
  )
    .then(async (res) => {
      // Handle success.
      return res.json();
    })
    .catch((error) => {
      // Handle error.
      // console.log("An error occurred:", error.response);
    });
};

export const getAllDC = async () => {
  let company_id = localStorage.company_id || sessionStorage.company_id;
  return await fetch(
    `${backendUrl}/api/rp-droppoints?filters[rp_company][id][$eq]=${company_id}&filters[cate_e][$eq]=DC&pagination[limit]=-1&sort=id:asc`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          localStorage.getItem("jwt") || sessionStorage.getItem("jwt")
        }`,
      },
    }
  )
    .then(async (res) => {
      // Handle success.
      return res.json();
    })
    .catch((error) => {
      // Handle error.
      // console.log("An error occurred:", error.response);
    });
};

export const getAllDropPoint = async () => {
  let company_id = localStorage.company_id || sessionStorage.company_id;
  return await fetch(
    `${backendUrl}/api/rp-droppoints?filters[rp_company][id][$eq]=${company_id}&pagination[limit]=-1&sort=id:asc`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          localStorage.getItem("jwt") || sessionStorage.getItem("jwt")
        }`,
      },
    }
  )
    .then(async (res) => {
      // Handle success.
      return res.json();
    })
    .catch((error) => {
      // Handle error.
      // console.log("An error occurred:", error.response);
    });
};

export const getGeofenceData = async (lattitude, longtitude) => {
  return await fetch(
    `${server}/reverse_geocode?lat=${lattitude}&lng=${longtitude}&sort=d&&access_token=${token}`
  ).then(async (res) => {
    return res.json();
  });
};

export const countDrops = async (cate_e) => {
  let company_id = localStorage.company_id || sessionStorage.company_id;
  return fetch(
    `${backendUrl}/api/utils/count?${
      cate_e ? "cate_e=" + cate_e : "rp_company=" + company_id
    }`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          localStorage.getItem("jwt") || sessionStorage.getItem("jwt")
        }`,
      },
    }
  ).then((res) => res.json());
};

export const getVehicle = async (activePage, pageSize) => {
  let company_id = localStorage.company_id || sessionStorage.company_id;
  return await fetch(
    `${backendUrl}/api/rp-vehicles?filters[rp_company][id][$eq]=${company_id}&pagination[page]=${activePage}&pagination[pageSize]=${pageSize}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          localStorage.getItem("jwt") || sessionStorage.getItem("jwt")
        }`,
      },
    }
  )
    .then(async (res) => {
      // Handle success.
      return res.json();
    })
    .catch((error) => {
      // Handle error.
      // console.log("An error occurred:", error.response);
    });
};

export const getVehicleNoPage = async () => {
  let company_id = localStorage.company_id || sessionStorage.company_id;
  return await fetch(
    `${backendUrl}/api/rp-vehicles?filters[rp_company][id][$eq]=${company_id}&pagination[limit]=-1`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          localStorage.getItem("jwt") || sessionStorage.getItem("jwt")
        }`,
      },
    }
  )
    .then(async (res) => {
      // Handle success.
      return res.json();
    })
    .catch((error) => {
      // Handle error.
      // console.log("An error occurred:", error.response);
    });
};

export const saveFav = async (data) => {
  return await fetch(`${backendUrl}/api/rp-favs`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        localStorage.getItem("jwt") || sessionStorage.getItem("jwt")
      }`,
    },
    body: JSON.stringify({ data }),
  })
    .then(async (res) => {
      // Handle success.
      return res;
    })
    .catch((error) => {
      // Handle error.
      // console.log("An error occurred:", error.response);
    });
};

export const saveOpt = async (data) => {
  return await fetch(`${backendUrl}/api/rp-plans`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        localStorage.getItem("jwt") || sessionStorage.getItem("jwt")
      }`,
    },
    body: JSON.stringify({ data }),
  })
    .then(async (res) => {
      // Handle success.
      return res;
    })
    .catch((error) => {
      // Handle error.
      // console.log("An error occurred:", error.response);
    });
};

export const getAllFavorList = async () => {
  let company_id = localStorage.company_id || sessionStorage.company_id;
  return await fetch(
    `${backendUrl}/api/rp-favs?filters[rp_company][id][$eq]=${company_id}&sort=id:desc`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          localStorage.getItem("jwt") || sessionStorage.getItem("jwt")
        }`,
      },
    }
  )
    .then(async (res) => {
      // Handle success.
      return res.json();
    })
    .catch((error) => {
      // Handle error.
      // console.log("An error occurred:", error.response);
    });
};

export const getPlan = async (date) => {
  let company_id = localStorage.company_id || sessionStorage.company_id;
  return await fetch(
    `${backendUrl}/api/rp-plans?filters[rp_company][id][$eq]=${company_id}&filters[Date][$eq]=${date}&sort=id:desc`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          localStorage.getItem("jwt") || sessionStorage.getItem("jwt")
        }`,
      },
    }
  )
    .then(async (res) => {
      // Handle success.
      return res.json();
    })
    .catch((error) => {
      // Handle error.
      // console.log("An error occurred:", error.response);
    });
};

export const updateOpt = async (id, data) => {
  return await fetch(`${backendUrl}/api/rp-plans/${id}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        localStorage.getItem("jwt") || sessionStorage.getItem("jwt")
      }`,
    },
    body: JSON.stringify({ data }),
  })
    .then(async (res) => {
      // Handle success.
      return res;
    })
    .catch((error) => {
      // Handle error.
      // console.log("An error occurred:", error.response);
    });
};

export const createDropPoint = async (data) => {
  return await fetch(`${backendUrl}/api/rp-droppoints`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        localStorage.getItem("jwt") || sessionStorage.getItem("jwt")
      }`,
    },
    body: JSON.stringify({ data }),
  })
    .then(async (res) => {
      // Handle success.
      return res;
    })
    .catch((error) => {
      // Handle error.
      // console.log("An error occurred:", error.response);
    });
};

export const existDropPoint = async (uploadDroppoint) => {
  let filterDupBranchID = "";
  uploadDroppoint.map((each, index) => {
    return (filterDupBranchID =
      filterDupBranchID + `filters[branch_id][$in][${index}]=${each}&`);
  });

  return await fetch(`${backendUrl}/api/rp-droppoints?${filterDupBranchID}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        localStorage.getItem("jwt") || sessionStorage.getItem("jwt")
      }`,
    },
  })
    .then(async (res) => {
      // Handle success.
      return res.json();
    })
    .catch((error) => {
      // Handle error.
      // console.log("An error occurred:", error.response);
    });
};

export const createTask = async (data) => {
  return await fetch(`${backendUrl}/api/rp-tasks`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        localStorage.getItem("jwt") || sessionStorage.getItem("jwt")
      }`,
    },
    body: JSON.stringify({ data }),
  })
    .then(async (res) => {
      console(res);
      // Handle success.
      return res;
    })
    .catch((error) => {
      // Handle error.
      // console.log("An error occurred:", error.response);
    });
};
