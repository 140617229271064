import React, { useState } from "react";
import _ from "lodash";
import humanizeDuration from "humanize-duration";

function RouteResults({ route, storeJob }) {
  // console.log(route);
  // get middle route only;

  let drops = _.filter(route.steps, (each, index) => {
    if (index !== 0 && index !== route.steps.length - 1) {
      return true;
    }
    return false;
  });

  // console.log(drops, storeJob);

  return (
    <div className="">
      <div className="mb-3 space-x-5">
        <span className="text-sm">
          Cost: {humanizeDuration(route.cost * 1000)}
        </span>
        <span className="text-sm">Delivery: {route.delivery}</span>
      </div>
      <ol className="flex relative border-t border-gray-200">
        <li className="mb-5 -mt-6 pt-4 px-4">
          <span className="flex -top-6 justify-center items-center w-6 h-6 rounded-full ring-8 ring-white ">
            <img
              className="m-2"
              src="/route_icons/truck_start.png"
              alt="Bonnie image"
            />
          </span>
          <div className="justify-between items-center p-2 rounded-lg border shadow-sm sm:flex ">
            <div className="text-sm font-normal text-gray-500">
              <span className="text-gray-800 text-xs font-normal mr-2 px-2.5 py-0.5 rounded ">
                Start
              </span>
              <span className="flex text-gray-800 text-xs font-normal mr-2 px-2.5 py-0.5 rounded ">
                Dist.: {(route.steps[0].distance / 1000).toFixed(2)} Km
              </span>
              <span className="flex text-gray-800 text-xs font-normal mr-2 px-2.5 py-0.5 rounded ">
                Dur.: {humanizeDuration(route.steps[0].duration * 1000)}
              </span>
            </div>
          </div>
        </li>
        {drops.map((each) => (
          <li className="mb-5 -mt-6 pt-4 px-4">
            <span className="flex -top-6 justify-center items-center w-6 h-6 rounded-full ring-8 ring-white ">
              <img
                className="m-2"
                src="/route_icons/stop.png"
                alt="Thomas Lean image"
              />
            </span>
            <div className="flex-col p-2 bg-white rounded-lg border border-gray-200">
              <span className="flex text-gray-800 text-xs font-normal mr-2 px-2.5 py-0.5 rounded ">
                Drop #
                {storeJob[each.id] && storeJob[each.id].replace("Marker_", "")}
              </span>
              <span className="flex text-gray-800 text-xs font-normal mr-2 px-2.5 py-0.5 rounded ">
                Dist.: {(each.distance / 1000).toFixed(2)} Km
              </span>
              <span className="flex text-gray-800 text-xs font-normal mr-2 px-2.5 py-0.5 rounded ">
                Dur.: {humanizeDuration(each.duration * 1000)}
              </span>
            </div>
          </li>
        ))}
        <li className="mb-5 -mt-6 p-4 pt-4 px-4">
          <span className="flex -top-6 justify-center items-center w-6 h-6 rounded-full ring-8 ring-white ">
            <img
              className="m-2"
              src="/route_icons/truck_stop.png"
              alt="Jese Leos image"
            />
          </span>
          <div className="flex-col p-2 bg-white rounded-lg border border-gray-200">
            <span className="flex text-gray-800 text-xs font-normal mr-2 px-2.5 py-0.5 rounded ">
              Stop
            </span>
            <span className="flex text-gray-800 text-xs font-normal mr-2 px-2.5 py-0.5 rounded ">
              Dist.:{" "}
              {(route.steps[route.steps.length - 1].distance / 1000).toFixed(2)}{" "}
              Km
            </span>
            <span className="flex text-gray-800 text-xs font-normal mr-2 px-2.5 py-0.5 rounded ">
              Dur.:{" "}
              {humanizeDuration(
                route.steps[route.steps.length - 1].duration * 1000
              )}
            </span>
          </div>
        </li>
      </ol>
    </div>
  );
}

export default RouteResults;
