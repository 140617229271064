import React, { useState, useEffect } from "react";
import { getPOIsTH, getPOIsEN } from "../utils/apis";
import { Context } from "../store";
import _ from "lodash";
import { MdAddLocationAlt } from "react-icons/md";
import Categories from "../utils/Categories";
import "react-toastify/dist/ReactToastify.css";
import { franc } from "franc";
import { searchDropPoint } from "../utils/apis";
import maplibregl from "maplibre-gl";

var el = document.createElement("div");
el.className = "marker";
el.style.backgroundImage = "url(./pinpoint/snap.png)";
el.style.width = "30px";
el.style.height = "40px";
el.style.backgroundSize = "contain";
el.style.backgroundRepeat = "no-repeat";

var marker = new maplibregl.Marker({
  element: el,
  draggable: false,
});

export default function SearchBox({ addDestination }) {
  const [state, setState] = React.useContext(Context);

  const handleChange = (e) => {
    if (e.target.value && e.target.value.length >= 3) {
      let language = franc(e.target.value, { minLength: 3 });
      // console.log("lang", language);
      setState((state) => ({ ...state, lang: language }));
      handleSearch(e.target.value);
    }
  };

  const handleChangeCP = (e) => {
    if (e.target.value && e.target.value.length >= 3) {
      let language = franc(e.target.value, { minLength: 3 });
      // console.log("lang", language);
      setState((state) => ({ ...state, lang: language }));
      handleSearchCP(e.target.value);
    } else {
      return setState((state) => ({ ...state, resultDrops: "" }));
    }
  };

  const handleClick = (data) => {
    // flight to location
    let map = state.map;
    map.flyTo({
      center: [data.geometry.coordinates[0], data.geometry.coordinates[1]],
      duration: 2000,
      zoom: 16,
    });
    marker
      .setLngLat([data.geometry.coordinates[0], data.geometry.coordinates[1]])
      .addTo(map);
    setState((state) => ({ ...state, selectedPOI: data }));
  };

  const handleClickCP = (data) => {
    // flight to location
    let map = state.map;
    map.flyTo({
      center: [data.attributes.longitude, data.attributes.latitude],
      duration: 2000,
      zoom: 16,
    });

    marker
      .setLngLat([data.attributes.longitude, data.attributes.latitude])
      .addTo(map);
    setState((state) => ({ ...state, selectedPOI: data }));
  };

  const handleSearch = async (e) => {
    // get coordinates
    let map = state.map;
    let results;
    if (state.lang === "tha") {
      results = await getPOIsTH(e);
    } else {
      results = await getPOIsEN(e);
    }
    // console.log(results);
    setState((state) => ({ ...state, results, resultDrops: "" }));
  };

  const handleSearchCP = async (e) => {
    let resultDrops = await searchDropPoint(e);
    // console.log(resultDrops, resultDrops.data.length);
    if (resultDrops.data.length === 0) {
      // console.log("resultDrops");
      return setState((state) => ({ ...state, resultDrops: "" }));
    }
    setState((state) => ({ ...state, resultDrops, results: "" }));
    // console.log(resultDrops);
  };

  return (
    <div className="flex flex-col h-screen bg-sky-800 bg-opacity-90">
      <div className="relative flex-shrink-0 px-4 py-4 text-gray-400 border-b dark:border-primary-darker dark:focus-within:text-light focus-within:text-gray-700">
        <span className="absolute inset-y-0 inline-flex items-center px-4">
          <svg
            className="w-5 h-5"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </span>
        <input
          onChange={handleChangeCP}
          type="text"
          className="w-full py-2 pl-10 pr-4 border rounded-full dark:bg-dark dark:border-transparent dark:text-light focus:outline-none focus:ring"
          placeholder="Search Drops..."
        />
      </div>

      {state.resultDrops && (
        <div
          className={`flex-col px-4 pt-3 space-y-2 overflow-y-hidden ${
            !state.resultDrops && "h-0"
          } h-full hover:overflow-y-auto`}
        >
          {state.resultDrops.data.map((each, index) => {
            let color = "black";
            if (each.attributes.cate_e === "Arabitia-Cafe") {
              color = "%2378350F";
            } else if (each.attributes.cate_e === "CP Fresh Mart") {
              color = "%23EAB308";
            } else if (each.attributes.cate_e === "Customer") {
              color = "%23DB2777";
            } else if (each.attributes.cate_e === "DC") {
              color = "%23EA580C";
            } else if (each.attributes.cate_e === "Jungle Cafe") {
              color = "%2310B981";
            } else if (each.attributes.cate_e === "Kaidee Shop") {
              color = "%231E40AF";
            } else if (each.attributes.cate_e === "Refrig") {
              color = "%230284C7";
            } else if (each.attributes.cate_e === "Spare-part") {
              color = "%230D9488";
            } else if (each.attributes.cate_e === "Top Charoen") {
              color = "%230284C7";
            } else {
              color = "black";
            }
            return (
              <div
                key={index}
                className="flex h-auto bg-white shadow-lg p-2 rounded-lg justify-between transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 hover:bg-sky-500 hover:text-white font-jamjuree duration-300 cursor-pointer"
                onClick={() => {
                  handleClickCP(each);
                }}
              >
                <div sr-only="icons" className="flex p-2 w-16 items-center">
                  <img
                    title={each.attributes.cate_t}
                    src={`data:image/svg+xml;charset=utf-8,<svg width="100" height="133.33424" viewBox="0 0 105 136.33424" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M 48.48894,2.0846666 C 45.345582,2.4764484 42.045821,3.0699806 39.971926,3.6166357 31.331372,5.8941857 23.70167,10.287001 17.343929,16.644739 7.5307186,26.457951 2.26971,39.565851 2.6992958,53.1321 c 0.2096929,6.62207 1.4945831,12.285322 4.2570658,18.763368 3.8625134,9.057619 8.9221174,16.765286 21.9823994,33.487402 9.794726,12.54096 13.702902,17.89379 17.704361,24.24874 1.304226,2.0713 2.77134,4.14006 3.260261,4.59722 1.567811,1.46597 3.94258,1.46597 5.510391,0 0.48892,-0.45716 1.956035,-2.52592 3.26026,-4.59722 4.00146,-6.35495 7.909636,-11.70778 17.704362,-24.24874 C 89.438679,88.660754 94.498283,80.953087 98.360793,71.895468 102.97204,61.082067 103.87705,50.520165 101.12201,39.670835 98.886222,30.866339 94.481777,23.15329 87.973228,16.644739 80.532191,9.2037052 71.45767,4.5056208 60.997853,2.6789833 58.10893,2.1744806 50.622249,1.8187766 48.48894,2.0846666 Z" 
                    fill="${color}"/><style>
                    .number { font: bold 50px sans-serif; fill: white;}
                  </style>
                  <text x="50%" y="50%" dominant-baseline="start" text-anchor="middle" class="number">★</text></svg>`}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = "./markers/default.png";
                    }}
                    alt="poi-icon"
                    width={30}
                  />
                </div>
                <div sr-only="poi_name" className="w-full text-right">
                  <div className="flex-col">
                    <div
                      sr-only="admin_boundary"
                      className="flex flex-col justify-end text-sm items-end"
                    >
                      <div className="font-semibold">
                        {each.attributes.name_t}
                      </div>
                      <div>[ {each.attributes.branch_id} ]</div>
                    </div>
                  </div>
                </div>
                <button
                  onClick={() => {
                    handleClickCP(each);
                    addDestination(each);
                  }}
                  className="w-16 flex justify-end mx-auto hover:text-yellow-400"
                >
                  <MdAddLocationAlt className="w-5 h-5" />
                </button>
              </div>
            );
          })}
        </div>
      )}
      <div className="relative flex-shrink-0 px-4 py-4 text-gray-400 border-b dark:border-primary-darker dark:focus-within:text-light focus-within:text-gray-700">
        <span className="absolute inset-y-0 inline-flex items-center px-4">
          <svg
            className="w-5 h-5"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </span>
        <input
          onChange={handleChange}
          type="text"
          className="w-full py-2 pl-10 pr-4 border rounded-full dark:bg-dark dark:border-transparent dark:text-light focus:outline-none focus:ring"
          placeholder="Search Map..."
        />
      </div>

      <div className="flex-1 px-4 pt-3 space-y-2 overflow-y-hidden h hover:overflow-y-auto">
        {state.results &&
          state.results.map((each, index) => {
            // console.log("each", each);
            let data = each._source;
            return (
              <div
                key={index}
                className="flex h-auto bg-white shadow-lg p-2 rounded-lg justify-between transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 hover:bg-cyan-500 hover:text-white font-jamjuree duration-300 cursor-pointer"
                onClick={() => {
                  handleClick(data);
                }}
              >
                <div sr-only="icons" className="flex p-2 w-16 items-center">
                  <img
                    src={`./markers/${
                      _.filter(Categories, ["cat_new", data.new_cate])[0]
                        .new_type
                    }.png`}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = "./markers/default.png";
                    }}
                    alt="poi-icon"
                    width={30}
                  />
                </div>
                <div sr-only="poi_name" className="w-full text-sm text-right">
                  <div sr-only="name" className="flex flex-col font-semibold">
                    {state.lang === "tha" ? data.name_t : data.name_e}
                  </div>
                  {state.lang !== "tha" && (
                    <div sr-only="admin_boundary" className="flex flex-col">
                      <div>
                        {data.address_e
                          ? data.address_e
                          : data.province_e + " " + data.post_code}
                      </div>
                    </div>
                  )}
                  {state.lang === "tha" && (
                    <div sr-only="admin_boundary" className="flex flex-col">
                      <div>
                        {data.address_t
                          ? data.address_t
                          : data.province_t + " " + data.post_code}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
