import React from "react";
import Whitecard from "../components/Whitecard";
import { getCategoryApi, countDrops } from "../utils/apis";
import _ from "lodash";

function Dashboard() {
  const [total, setTotal] = React.useState();
  const [status, setStatus] = React.useState();
  const [category, setCategory] = React.useState();

  const getTotal = async () => {
    let allDrops = await countDrops();
    setTotal(allDrops);
  };

  const getStatus = async () => {
    let allCategory = await getCategoryApi();

    setCategory(allCategory.data);
    let typeAwait = allCategory.data.map(
      async (item) => await countDrops(item.attributes.cate_e)
    );
    Promise.all(typeAwait).then((results) => {
      let resultCount = _.map(results, (result) => result);
      setStatus(resultCount);
    });
  };

  React.useEffect(() => {
    getTotal();
    getStatus();
  }, []);

  if (!category || !status) {
    return (
      <div className="flex justify-center h-screen items-center w-sidebar ml-12">
        <div
          style={{ borderTopColor: "transparent" }}
          className="w-16 h-16 border-4 border-blue-400 border-dotted rounded-full animate-spin"
        ></div>
      </div>
    );
  } else {
    return (
      <div className="w-sidebar ml-12">
        <div className="flex flex-col p-2 space-y-2 divide-y divide-dashed h-exclude">
          <div className={`grid grid-cols-4 gap-2 m-3 w-auto`}>
            {category.map((cate, index) => (
              <div
                key={index}
                style={{ backgroundColor: "#" + cate.attributes.hex }}
                className={`flex p-2 rounded-xl shadow-md`}
              >
                <div className="mt-4 text-white flex flex-col w-full">
                  <div className={`flex space-x-2 justify-between mx-2`}>
                    <div className="uppercase font-bold text-white">
                      {cate.attributes.cate_e}
                    </div>
                    <img
                      title={cate.attributes.color}
                      src={cate.attributes.icon}
                      alt={cate.attributes.cate_e}
                      className="w-10 h-10 block"
                    />
                  </div>
                  <h5 className="mt-4 text-3xl font-bold text-white text-center">
                    {status[index]}
                  </h5>
                  <div className="w-full flex justify-end">
                    <p className="mt-2 text-sm text-end">Points</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="flex space-x-2 px-3 pt-3">
            <Whitecard title="Delivery Locations" icon="marker" data={total} />
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
