import { Fragment, useState, useContext, useEffect } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { searchOrigin } from "../utils/apis";
import _ from "lodash";
import { Context } from "../store";

export default function SearchOrigin({ origin_place }) {
  // console.log(origin_place);
  const [selected, setSelected] = useState([]);
  const [query, setQuery] = useState("");
  const [dropPoints, setDropPoints] = useState([]);
  const [state, setState] = useContext(Context);

  const getDropPoint = async (e) => {
    // console.log(e);
    let searchResult = await searchOrigin(e);
    // console.log(searchResult);
    let dropPointsData = _.map(searchResult.data, (item) => ({
      id: item.id,
      ...item.attributes,
      name: item.attributes.name_t,
    }));
    setDropPoints(dropPointsData);
  };

  const handleRemove = (person) => {
    let filterPerson = _.filter(selected, (each) => each.name !== person.name);
    setSelected(filterPerson);
  };

  useEffect(() => {
    setState({ ...state, selectedoriginplace: selected });
  }, [selected]);

  // console.log(selected);

  useEffect(() => {
    if (origin_place && origin_place.data.length !== 0) {
      let startOrigin = _.map(origin_place.data, (item) => ({
        id: item.id,
        ...item.attributes,
        name: item.attributes.name_t,
      }));
      setSelected(startOrigin);
    }
  }, [origin_place]);

  return (
    <div className="w-auto">
      <Combobox value={selected} onChange={setSelected} multiple={true}>
        {selected.length > 0 && (
          <ul className="space-y-1">
            {selected.map((person, index) => (
              <li key={index}>
                <strong className="inline-flex items-center border border-indigo-600 text-gray-500 border-current uppercase px-5 py-1 rounded-full text-[11px] tracking-wide">
                  {person ? person.name : ""}

                  <button
                    className="p-1 bg-red-100 hover:opacity-75 transition-opacity rounded-full ml-2.5 -mr-2.5 focus:outline-none focus:ring"
                    type="button"
                    onClick={() => handleRemove(person)}
                  >
                    <span className="sr-only"> Close </span>

                    <svg
                      className="w-3 h-3"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </strong>
              </li>
            ))}
          </ul>
        )}

        <div className="mt-1">
          <div className="flex w-full cursor-default overflow-hidden rounded-lg bg-white dark:bg-gray-600 text-left shadow-md focus:outline-none focus-visible:ring-2  sm:text-sm">
            <Combobox.Input
              className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 bg-white dark:text-white dark:bg-gray-600"
              displayValue={""}
              onChange={(event) => getDropPoint(event.target.value)}
            />
            <Combobox.Button className="relative inset-y-0 right-0 flex items-center pr-2">
              <SelectorIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            <Combobox.Options className="absolute mt-1 max-h-60 w-4/5 overflow-auto rounded-md bg-white dark:bg-gray-600 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {dropPoints.length === 0 && query !== "" ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700 dark:text-white">
                  Not found.
                </div>
              ) : (
                dropPoints.map((item) => (
                  <Combobox.Option
                    key={item.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active
                          ? "bg-teal-600 text-white dark:text-blue-200"
                          : "text-gray-900 dark:text-white"
                      }`
                    }
                    value={item}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          [{item.branch_id}] {item.name}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? "text-white" : "text-teal-600"
                            }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
}
