const Categories = [
  {
    gid: 1,
    sect_code: 1000,
    sect_en: "Dwelling",
    sect_th: "ที่อยู่อาศัย",
    cat_new: 1001,
    cat_ename: "Single-family house",
    cat_tname: "บ้านเดี่ยว",
    new_type: 1510,
  },
  {
    gid: 2,
    sect_code: 1000,
    sect_en: "Dwelling",
    sect_th: "ที่อยู่อาศัย",
    cat_new: 1002,
    cat_ename: "Townhouse",
    cat_tname: "ทาวเฮาส์",
    new_type: 1510,
  },
  {
    gid: 3,
    sect_code: 1000,
    sect_en: "Dwelling",
    sect_th: "ที่อยู่อาศัย",
    cat_new: 1003,
    cat_ename: "Shop house",
    cat_tname: "อาคารพาณิชย์",
    new_type: 1510,
  },
  {
    gid: 4,
    sect_code: 1000,
    sect_en: "Dwelling",
    sect_th: "ที่อยู่อาศัย",
    cat_new: 1004,
    cat_ename: "Row house",
    cat_tname: "ห้องแถว",
    new_type: 1510,
  },
  {
    gid: 5,
    sect_code: 1000,
    sect_en: "Dwelling",
    sect_th: "ที่อยู่อาศัย",
    cat_new: 1005,
    cat_ename: "Official residence",
    cat_tname: "บ้านพักหน่วยงาน",
    new_type: 1510,
  },
  {
    gid: 6,
    sect_code: 1100,
    sect_en: "Dwelling",
    sect_th: "ที่อยู่อาศัย",
    cat_new: 1101,
    cat_ename: "Condominium",
    cat_tname: "คอนโดมิเนียม",
    new_type: 1501,
  },
  {
    gid: 7,
    sect_code: 1200,
    sect_en: "Dwelling",
    sect_th: "ที่อยู่อาศัย",
    cat_new: 1201,
    cat_ename: "Apartment",
    cat_tname: "อพาร์ทเม้นท์",
    new_type: 1503,
  },
  {
    gid: 8,
    sect_code: 1200,
    sect_en: "Dwelling",
    sect_th: "ที่อยู่อาศัย",
    cat_new: 1202,
    cat_ename: "Mansion",
    cat_tname: "แมนชั่น",
    new_type: 1504,
  },
  {
    gid: 9,
    sect_code: 1300,
    sect_en: "Dwelling",
    sect_th: "ที่อยู่อาศัย",
    cat_new: 1301,
    cat_ename: "Dormitory",
    cat_tname: "หอพักรวม",
    new_type: 1502,
  },
  {
    gid: 10,
    sect_code: 1300,
    sect_en: "Dwelling",
    sect_th: "ที่อยู่อาศัย",
    cat_new: 1302,
    cat_ename: "Female Dormitory",
    cat_tname: "หอพักหญิง",
    new_type: 1502,
  },
  {
    gid: 11,
    sect_code: 1300,
    sect_en: "Dwelling",
    sect_th: "ที่อยู่อาศัย",
    cat_new: 1303,
    cat_ename: "Male Dormitory",
    cat_tname: "หอพักชาย",
    new_type: 1502,
  },
  {
    gid: 18,
    sect_code: 1600,
    sect_en: "Building",
    sect_th: "อาคารสำนักงาน",
    cat_new: 1601,
    cat_ename: "Building",
    cat_tname: "อาคารสำนักงาน",
    new_type: 1401,
  },
  {
    gid: 19,
    sect_code: 1600,
    sect_en: "Building",
    sect_th: "อาคารสำนักงาน",
    cat_new: 1602,
    cat_ename: "Tower",
    cat_tname: "ทาวเวอร์",
    new_type: 1402,
  },
  {
    gid: 65,
    sect_code: 2400,
    sect_en: "Convenience Store",
    sect_th: "ร้านสะดวกซื้อ",
    cat_new: 2401,
    cat_ename: "7-Eleven",
    cat_tname: "เซเว่น อีเลฟเว่น",
    new_type: 1810,
  },
  {
    gid: 66,
    sect_code: 2400,
    sect_en: "Convenience Store",
    sect_th: "ร้านสะดวกซื้อ",
    cat_new: 2402,
    cat_ename: "Family Mart",
    cat_tname: "แฟมิลี่ มาร์ท",
    new_type: 1803,
  },
  {
    gid: 67,
    sect_code: 2400,
    sect_en: "Convenience Store",
    sect_th: "ร้านสะดวกซื้อ",
    cat_new: 2403,
    cat_ename: "Lotus Express",
    cat_tname: "โลตัส เอ็กซ์เพรส",
    new_type: 1808,
  },
  {
    gid: 68,
    sect_code: 2400,
    sect_en: "Convenience Store",
    sect_th: "ร้านสะดวกซื้อ",
    cat_new: 2404,
    cat_ename: "Mini Big C",
    cat_tname: "มินิบิ๊กซี",
    new_type: 1809,
  },
  {
    gid: 69,
    sect_code: 2400,
    sect_en: "Convenience Store",
    sect_th: "ร้านสะดวกซื้อ",
    cat_new: 2405,
    cat_ename: "Fresh Mart",
    cat_tname: "เฟรชมาร์ท",
    new_type: 1811,
  },
  {
    gid: 70,
    sect_code: 2400,
    sect_en: "Convenience Store",
    sect_th: "ร้านสะดวกซื้อ",
    cat_new: 2406,
    cat_ename: "Maxvalue",
    cat_tname: "แม็กซ์แวลู",
    new_type: 1807,
  },
  {
    gid: 71,
    sect_code: 2400,
    sect_en: "Convenience Store",
    sect_th: "ร้านสะดวกซื้อ",
    cat_new: 2407,
    cat_ename: "108 Shop",
    cat_tname: "108 ช็อป",
    new_type: 1801,
  },
  {
    gid: 72,
    sect_code: 2700,
    sect_en: "Car Dealers",
    sect_th: "ตัวแทนจำหน่ายรถยนต์",
    cat_new: 2724,
    cat_ename: "Jaguar",
    cat_tname: "จากัวร์",
    new_type: 2023,
  },
  {
    gid: 73,
    sect_code: 2400,
    sect_en: "Convenience Store",
    sect_th: "ร้านสะดวกซื้อ",
    cat_new: 2408,
    cat_ename: "CP Fresh Mart",
    cat_tname: "ซีพี่ เฟรชมาร์ท",
    new_type: 1805,
  },
  {
    gid: 74,
    sect_code: 2400,
    sect_en: "Convenience Store",
    sect_th: "ร้านสะดวกซื้อ",
    cat_new: 2409,
    cat_ename: "Home Fresh Mart",
    cat_tname: "โฮม เฟรชมาร์ท",
    new_type: 1804,
  },
  {
    gid: 75,
    sect_code: 2400,
    sect_en: "Convenience Store",
    sect_th: "ร้านสะดวกซื้อ",
    cat_new: 2410,
    cat_ename: "Jiffy",
    cat_tname: "จิฟฟี่",
    new_type: 1806,
  },
  {
    gid: 76,
    sect_code: 2400,
    sect_en: "Convenience Store",
    sect_th: "ร้านสะดวกซื้อ",
    cat_new: 2411,
    cat_ename: "Bai Chak",
    cat_tname: "ใบจาก",
    new_type: 1802,
  },
  {
    gid: 77,
    sect_code: 2400,
    sect_en: "Convenience Store",
    sect_th: "ร้านสะดวกซื้อ",
    cat_new: 2412,
    cat_ename: "Lemon Green",
    cat_tname: "เลมอนกรีน",
    new_type: 1812,
  },
  {
    gid: 78,
    sect_code: 2400,
    sect_en: "Convenience Store",
    sect_th: "ร้านสะดวกซื้อ",
    cat_new: 2413,
    cat_ename: "V Shop",
    cat_tname: "วีช็อป",
    new_type: 1813,
  },
  {
    gid: 79,
    sect_code: 2400,
    sect_en: "Convenience Store",
    sect_th: "ร้านสะดวกซื้อ",
    cat_new: 2499,
    cat_ename: "Other Convenience Store",
    cat_tname: "ร้านสะดวกซื้ออื่นๆ",
    new_type: 1814,
  },
  {
    gid: 80,
    sect_code: 2500,
    sect_en: "Shops and Stores",
    sect_th: "ร้านขายสินค้า",
    cat_new: 2501,
    cat_ename: "Grocery Store",
    cat_tname: "ร้านขายของชำ",
    new_type: 2514,
  },
  {
    gid: 81,
    sect_code: 2500,
    sect_en: "Shops and Stores",
    sect_th: "ร้านขายสินค้า",
    cat_new: 2502,
    cat_ename: "Amorn",
    cat_tname: "อมร",
    new_type: 2509,
  },
  {
    gid: 82,
    sect_code: 2500,
    sect_en: "Shops and Stores",
    sect_th: "ร้านขายสินค้า",
    cat_new: 2503,
    cat_ename: "Boots",
    cat_tname: "บู๊ทส์",
    new_type: 2511,
  },
  {
    gid: 119,
    sect_code: 2700,
    sect_en: "Car Dealers",
    sect_th: "ตัวแทนจำหน่ายรถยนต์",
    cat_new: 2706,
    cat_ename: "Nissan",
    cat_tname: "นิสสัน",
    new_type: 2010,
  },
  {
    gid: 83,
    sect_code: 2500,
    sect_en: "Shops and Stores",
    sect_th: "ร้านขายสินค้า",
    cat_new: 2504,
    cat_ename: "Watson",
    cat_tname: "วัตสัน",
    new_type: 2512,
  },
  {
    gid: 85,
    sect_code: 2500,
    sect_en: "Shops and Stores",
    sect_th: "ร้านขายสินค้า",
    cat_new: 2506,
    cat_ename: "Jewelry & Accessories",
    cat_tname: "เครื่องประดับ",
    new_type: 2514,
  },
  {
    gid: 12,
    sect_code: 1400,
    sect_en: "Housing Estate",
    sect_th: "หมู่บ้านจัดสรร",
    cat_new: 1401,
    cat_ename: "Housing Estate",
    cat_tname: "หมู่บ้านจัดสรร",
    new_type: 1505,
  },
  {
    gid: 13,
    sect_code: 1400,
    sect_en: "Housing Estate",
    sect_th: "หมู่บ้านจัดสรร",
    cat_new: 1402,
    cat_ename: "Land & House",
    cat_tname: "แลนด์แอนด์เฮ้าส์",
    new_type: 1506,
  },
  {
    gid: 86,
    sect_code: 2500,
    sect_en: "Shops and Stores",
    sect_th: "ร้านขายสินค้า",
    cat_new: 2507,
    cat_ename: "Book Store",
    cat_tname: "หนังสือ",
    new_type: 2502,
  },
  {
    gid: 87,
    sect_code: 2500,
    sect_en: "Shops and Stores",
    sect_th: "ร้านขายสินค้า",
    cat_new: 2508,
    cat_ename: "Optical/Glasses",
    cat_tname: "แว่นตา",
    new_type: 2503,
  },
  {
    gid: 88,
    sect_code: 2500,
    sect_en: "Shops and Stores",
    sect_th: "ร้านขายสินค้า",
    cat_new: 2509,
    cat_ename: "Watch",
    cat_tname: "นาฬิกา",
    new_type: 2514,
  },
  {
    gid: 90,
    sect_code: 2500,
    sect_en: "Shops and Stores",
    sect_th: "ร้านขายสินค้า",
    cat_new: 2511,
    cat_ename: "Mobile Phone",
    cat_tname: "โทรศัพท์มือถือ",
    new_type: 2506,
  },
  {
    gid: 91,
    sect_code: 2500,
    sect_en: "Shops and Stores",
    sect_th: "ร้านขายสินค้า",
    cat_new: 2512,
    cat_ename: "Electric Appliance",
    cat_tname: "เครื่องใช้ไฟฟ้า",
    new_type: 2514,
  },
  {
    gid: 93,
    sect_code: 2500,
    sect_en: "Shops and Stores",
    sect_th: "ร้านขายสินค้า",
    cat_new: 2514,
    cat_ename: "Camera & Photo  service",
    cat_tname: "กล้อง และบริการถ่าย-อัดรูป",
    new_type: 2508,
  },
  {
    gid: 94,
    sect_code: 2500,
    sect_en: "Shops and Stores",
    sect_th: "ร้านขายสินค้า",
    cat_new: 2515,
    cat_ename: "Copy Printing Binder service",
    cat_tname: "บริการถ่ายเอกสาร พิมพ์รายงาน เข้าเล่ม",
    new_type: 2514,
  },
  {
    gid: 95,
    sect_code: 2500,
    sect_en: "Shops and Stores",
    sect_th: "ร้านขายสินค้า",
    cat_new: 2516,
    cat_ename: "Cosmetics",
    cat_tname: "เครื่องสำอาง / ความสวยงาม",
    new_type: 2514,
  },
  {
    gid: 96,
    sect_code: 2500,
    sect_en: "Shops and Stores",
    sect_th: "ร้านขายสินค้า",
    cat_new: 2517,
    cat_ename: "Costume/Fashion/Wedding",
    cat_tname: "เครื่องแต่งกาย / แฟชั่น / ชุดแต่งงาน",
    new_type: 2514,
  },
  {
    gid: 97,
    sect_code: 2500,
    sect_en: "Shops and Stores",
    sect_th: "ร้านขายสินค้า",
    cat_new: 2518,
    cat_ename: "Stationery& Office equipments",
    cat_tname: "เครื่องเขียน-อุปกรณ์สำนักงาน",
    new_type: 2514,
  },
  {
    gid: 98,
    sect_code: 2500,
    sect_en: "Shops and Stores",
    sect_th: "ร้านขายสินค้า",
    cat_new: 2519,
    cat_ename: "Toys & Games",
    cat_tname: "ของเล่น เกมส์",
    new_type: 2514,
  },
  {
    gid: 99,
    sect_code: 2500,
    sect_en: "Shops and Stores",
    sect_th: "ร้านขายสินค้า",
    cat_new: 2520,
    cat_ename: "Mom & Children",
    cat_tname: "แม่และเด็ก",
    new_type: 2514,
  },
  {
    gid: 100,
    sect_code: 2500,
    sect_en: "Shops and Stores",
    sect_th: "ร้านขายสินค้า",
    cat_new: 2521,
    cat_ename: "Consumer Products",
    cat_tname: "อุปโภค บริโภค",
    new_type: 2514,
  },
  {
    gid: 101,
    sect_code: 2500,
    sect_en: "Shops and Stores",
    sect_th: "ร้านขายสินค้า",
    cat_new: 2522,
    cat_ename: "Agricultural equipments",
    cat_tname: "อุปกรณ์การเกษตร",
    new_type: 2514,
  },
  {
    gid: 102,
    sect_code: 2500,
    sect_en: "Shops and Stores",
    sect_th: "ร้านขายสินค้า",
    cat_new: 2523,
    cat_ename: "Pet",
    cat_tname: "สัตว์เลี้ยง",
    new_type: 2514,
  },
  {
    gid: 103,
    sect_code: 2500,
    sect_en: "Shops and Stores",
    sect_th: "ร้านขายสินค้า",
    cat_new: 2524,
    cat_ename: "Monk appliance",
    cat_tname: "สังฆภัณฑ์",
    new_type: 2514,
  },
  {
    gid: 104,
    sect_code: 2500,
    sect_en: "Shops and Stores",
    sect_th: "ร้านขายสินค้า",
    cat_new: 2525,
    cat_ename: "Liquor & Beverage Store",
    cat_tname: "ร้านขายเครื่องดื่ม แอลกอออล์",
    new_type: 2514,
  },
  {
    gid: 106,
    sect_code: 2500,
    sect_en: "Shops and Stores",
    sect_th: "ร้านขายสินค้า",
    cat_new: 2527,
    cat_ename: "Duty Free Shop",
    cat_tname: "ร้านดิวตี้ฟรี",
    new_type: 2505,
  },
  {
    gid: 107,
    sect_code: 2500,
    sect_en: "Shops and Stores",
    sect_th: "ร้านขายสินค้า",
    cat_new: 2599,
    cat_ename: "Others Goods",
    cat_tname: "สินค้าอื่นๆ",
    new_type: 2514,
  },
  {
    gid: 114,
    sect_code: 2700,
    sect_en: "Car Dealers",
    sect_th: "ตัวแทนจำหน่ายรถยนต์",
    cat_new: 2701,
    cat_ename: "Toyota",
    cat_tname: "โตโยต้า",
    new_type: 2012,
  },
  {
    gid: 115,
    sect_code: 2700,
    sect_en: "Car Dealers",
    sect_th: "ตัวแทนจำหน่ายรถยนต์",
    cat_new: 2702,
    cat_ename: "Honda",
    cat_tname: "ฮอนด้า",
    new_type: 2004,
  },
  {
    gid: 116,
    sect_code: 2700,
    sect_en: "Car Dealers",
    sect_th: "ตัวแทนจำหน่ายรถยนต์",
    cat_new: 2703,
    cat_ename: "Isuzu",
    cat_tname: "อีซูซุ",
    new_type: 2006,
  },
  {
    gid: 117,
    sect_code: 2700,
    sect_en: "Car Dealers",
    sect_th: "ตัวแทนจำหน่ายรถยนต์",
    cat_new: 2704,
    cat_ename: "Mazda",
    cat_tname: "มาสด้า",
    new_type: 2007,
  },
  {
    gid: 118,
    sect_code: 2700,
    sect_en: "Car Dealers",
    sect_th: "ตัวแทนจำหน่ายรถยนต์",
    cat_new: 2705,
    cat_ename: "Mitsubishi",
    cat_tname: "มิตซูบิชิ",
    new_type: 2009,
  },
  {
    gid: 120,
    sect_code: 2700,
    sect_en: "Car Dealers",
    sect_th: "ตัวแทนจำหน่ายรถยนต์",
    cat_new: 2707,
    cat_ename: "Lexus",
    cat_tname: "เล็กซัส",
    new_type: 2025,
  },
  {
    gid: 121,
    sect_code: 2700,
    sect_en: "Car Dealers",
    sect_th: "ตัวแทนจำหน่ายรถยนต์",
    cat_new: 2708,
    cat_ename: "Suzuki",
    cat_tname: "ซูซูกิ",
    new_type: 2016,
  },
  {
    gid: 122,
    sect_code: 2700,
    sect_en: "Car Dealers",
    sect_th: "ตัวแทนจำหน่ายรถยนต์",
    cat_new: 2709,
    cat_ename: "Subaru",
    cat_tname: "ซูบารุ",
    new_type: 2026,
  },
  {
    gid: 123,
    sect_code: 2700,
    sect_en: "Car Dealers",
    sect_th: "ตัวแทนจำหน่ายรถยนต์",
    cat_new: 2710,
    cat_ename: "Hino",
    cat_tname: "ฮีโน่",
    new_type: 2014,
  },
  {
    gid: 124,
    sect_code: 2700,
    sect_en: "Car Dealers",
    sect_th: "ตัวแทนจำหน่ายรถยนต์",
    cat_new: 2711,
    cat_ename: "Daihatsu",
    cat_tname: "ไดฮัทสุ",
    new_type: 2024,
  },
  {
    gid: 125,
    sect_code: 2700,
    sect_en: "Car Dealers",
    sect_th: "ตัวแทนจำหน่ายรถยนต์",
    cat_new: 2712,
    cat_ename: "MG",
    cat_tname: "เอ็มจี",
    new_type: 2035,
  },
  {
    gid: 126,
    sect_code: 2700,
    sect_en: "Car Dealers",
    sect_th: "ตัวแทนจำหน่ายรถยนต์",
    cat_new: 2713,
    cat_ename: "Hyundai",
    cat_tname: "ฮุนได",
    new_type: 2005,
  },
  {
    gid: 127,
    sect_code: 2700,
    sect_en: "Car Dealers",
    sect_th: "ตัวแทนจำหน่ายรถยนต์",
    cat_new: 2714,
    cat_ename: "KIA",
    cat_tname: "เกีย",
    new_type: 2015,
  },
  {
    gid: 128,
    sect_code: 2700,
    sect_en: "Car Dealers",
    sect_th: "ตัวแทนจำหน่ายรถยนต์",
    cat_new: 2715,
    cat_ename: "Daewoo",
    cat_tname: "แดวู",
    new_type: 2020,
  },
  {
    gid: 129,
    sect_code: 2700,
    sect_en: "Car Dealers",
    sect_th: "ตัวแทนจำหน่ายรถยนต์",
    cat_new: 2716,
    cat_ename: "Ford",
    cat_tname: "ฟอร์ด",
    new_type: 2003,
  },
  {
    gid: 130,
    sect_code: 2700,
    sect_en: "Car Dealers",
    sect_th: "ตัวแทนจำหน่ายรถยนต์",
    cat_new: 2717,
    cat_ename: "Chevrolet",
    cat_tname: "เชฟโรเลต",
    new_type: 2002,
  },
  {
    gid: 131,
    sect_code: 2700,
    sect_en: "Car Dealers",
    sect_th: "ตัวแทนจำหน่ายรถยนต์",
    cat_new: 2718,
    cat_ename: "BMW",
    cat_tname: "บีเอ็มดับเบิ้ลยู",
    new_type: 2001,
  },
  {
    gid: 132,
    sect_code: 2700,
    sect_en: "Car Dealers",
    sect_th: "ตัวแทนจำหน่ายรถยนต์",
    cat_new: 2719,
    cat_ename: "Mercedes Benz",
    cat_tname: "เมอร์เซเดส-เบนซ์",
    new_type: 2008,
  },
  {
    gid: 133,
    sect_code: 2700,
    sect_en: "Car Dealers",
    sect_th: "ตัวแทนจำหน่ายรถยนต์",
    cat_new: 2720,
    cat_ename: "Volvo",
    cat_tname: "วอลโว่",
    new_type: 2013,
  },
  {
    gid: 134,
    sect_code: 2700,
    sect_en: "Car Dealers",
    sect_th: "ตัวแทนจำหน่ายรถยนต์",
    cat_new: 2721,
    cat_ename: "Peugeot",
    cat_tname: "เปอโยต์",
    new_type: 2011,
  },
  {
    gid: 135,
    sect_code: 2700,
    sect_en: "Car Dealers",
    sect_th: "ตัวแทนจำหน่ายรถยนต์",
    cat_new: 2722,
    cat_ename: "Citroen",
    cat_tname: "ซีตรอง",
    new_type: 2019,
  },
  {
    gid: 136,
    sect_code: 2700,
    sect_en: "Car Dealers",
    sect_th: "ตัวแทนจำหน่ายรถยนต์",
    cat_new: 2723,
    cat_ename: "Volkswagen",
    cat_tname: "โฟล์คสวาเกน",
    new_type: 2022,
  },
  {
    gid: 137,
    sect_code: 2700,
    sect_en: "Car Dealers",
    sect_th: "ตัวแทนจำหน่ายรถยนต์",
    cat_new: 2725,
    cat_ename: "Fiat",
    cat_tname: "เฟียต",
    new_type: 2021,
  },
  {
    gid: 138,
    sect_code: 2700,
    sect_en: "Car Dealers",
    sect_th: "ตัวแทนจำหน่ายรถยนต์",
    cat_new: 2726,
    cat_ename: "Audi",
    cat_tname: "ออดี้",
    new_type: 2031,
  },
  {
    gid: 139,
    sect_code: 2700,
    sect_en: "Car Dealers",
    sect_th: "ตัวแทนจำหน่ายรถยนต์",
    cat_new: 2727,
    cat_ename: "Porsche",
    cat_tname: "ปอร์เช่",
    new_type: 2032,
  },
  {
    gid: 140,
    sect_code: 2700,
    sect_en: "Car Dealers",
    sect_th: "ตัวแทนจำหน่ายรถยนต์",
    cat_new: 2728,
    cat_ename: "Ferrari",
    cat_tname: "เฟอร์รารี่",
    new_type: 2027,
  },
  {
    gid: 141,
    sect_code: 2700,
    sect_en: "Car Dealers",
    sect_th: "ตัวแทนจำหน่ายรถยนต์",
    cat_new: 2729,
    cat_ename: "Alfa Romeo",
    cat_tname: "อัลฟาโรเมโอ",
    new_type: 2030,
  },
  {
    gid: 142,
    sect_code: 2700,
    sect_en: "Car Dealers",
    sect_th: "ตัวแทนจำหน่ายรถยนต์",
    cat_new: 2730,
    cat_ename: "Land Rover",
    cat_tname: "แลนด์โรเวอร์",
    new_type: 2033,
  },
  {
    gid: 143,
    sect_code: 2700,
    sect_en: "Car Dealers",
    sect_th: "ตัวแทนจำหน่ายรถยนต์",
    cat_new: 2731,
    cat_ename: "SAAB",
    cat_tname: "ซาบ ออโตโมบิล เอบี",
    new_type: 2029,
  },
  {
    gid: 144,
    sect_code: 2700,
    sect_en: "Car Dealers",
    sect_th: "ตัวแทนจำหน่ายรถยนต์",
    cat_new: 2732,
    cat_ename: "SSANGYONG",
    cat_tname: "ซันยอง",
    new_type: 2028,
  },
  {
    gid: 145,
    sect_code: 2700,
    sect_en: "Car Dealers",
    sect_th: "ตัวแทนจำหน่ายรถยนต์",
    cat_new: 2733,
    cat_ename: "TATA Motors",
    cat_tname: "ทาทามอเตอร์",
    new_type: 2036,
  },
  {
    gid: 146,
    sect_code: 2700,
    sect_en: "Car Dealers",
    sect_th: "ตัวแทนจำหน่ายรถยนต์",
    cat_new: 2798,
    cat_ename: "Used Car",
    cat_tname: "จำหน่ายรถยนต์มือสอง",
    new_type: 2034,
  },
  {
    gid: 147,
    sect_code: 2700,
    sect_en: "Car Dealers",
    sect_th: "ตัวแทนจำหน่ายรถยนต์",
    cat_new: 2799,
    cat_ename: "Other Car Dealership",
    cat_tname: "ผู้แทนจำหน่ายรถยนต์อื่นๆ",
    new_type: 2034,
  },
  {
    gid: 148,
    sect_code: 2800,
    sect_en: "Car Services",
    sect_th: "บริการด้านรถยนต์",
    cat_new: 2801,
    cat_ename: "B-Quick",
    cat_tname: "บี-ควิก",
    new_type: 2101,
  },
  {
    gid: 149,
    sect_code: 2800,
    sect_en: "Car Services",
    sect_th: "บริการด้านรถยนต์",
    cat_new: 2802,
    cat_ename: "A.C.T",
    cat_tname: "แอค",
    new_type: 2102,
  },
  {
    gid: 150,
    sect_code: 2800,
    sect_en: "Car Services",
    sect_th: "บริการด้านรถยนต์",
    cat_new: 2803,
    cat_ename: "Tyre Plus",
    cat_tname: "ไทร์พลัส",
    new_type: 2103,
  },
  {
    gid: 151,
    sect_code: 2800,
    sect_en: "Car Services",
    sect_th: "บริการด้านรถยนต์",
    cat_new: 2804,
    cat_ename: "Cockpit",
    cat_tname: "ค็อกพิท",
    new_type: 2104,
  },
  {
    gid: 152,
    sect_code: 2800,
    sect_en: "Car Services",
    sect_th: "บริการด้านรถยนต์",
    cat_new: 2805,
    cat_ename: "Procheck",
    cat_tname: "โปรเช็ค",
    new_type: 2106,
  },
  {
    gid: 153,
    sect_code: 2800,
    sect_en: "Car Services",
    sect_th: "บริการด้านรถยนต์",
    cat_new: 2806,
    cat_ename: "Autoboy",
    cat_tname: "ออโต้บอย",
    new_type: 2110,
  },
  {
    gid: 154,
    sect_code: 2800,
    sect_en: "Car Services",
    sect_th: "บริการด้านรถยนต์",
    cat_new: 2807,
    cat_ename: "Garage",
    cat_tname: "อู่ซ่อมรถ",
    new_type: 2110,
  },
  {
    gid: 156,
    sect_code: 2800,
    sect_en: "Car Services",
    sect_th: "บริการด้านรถยนต์",
    cat_new: 2809,
    cat_ename: "Car Care",
    cat_tname: "คาร์แคร์",
    new_type: 2107,
  },
  {
    gid: 157,
    sect_code: 2800,
    sect_en: "Car Services",
    sect_th: "บริการด้านรถยนต์",
    cat_new: 2810,
    cat_ename: "Car Check Service",
    cat_tname: "ตรวจสภาพรถเอกชน",
    new_type: 2108,
  },
  {
    gid: 158,
    sect_code: 2800,
    sect_en: "Car Services",
    sect_th: "บริการด้านรถยนต์",
    cat_new: 2811,
    cat_ename: "Car rent",
    cat_tname: "บริการเช่ารถ",
    new_type: 2109,
  },
  {
    gid: 159,
    sect_code: 2800,
    sect_en: "Car Services",
    sect_th: "บริการด้านรถยนต์",
    cat_new: 2899,
    cat_ename: "Other Car Service",
    cat_tname: "ศูนย์บริการรถยนต์อื่นๆ",
    new_type: 2106,
  },
  {
    gid: 160,
    sect_code: 2900,
    sect_en: "Motorbike Dealers",
    sect_th: "ตัวแทนจำหน่ายรถจักรยานยนต์",
    cat_new: 2901,
    cat_ename: "Honda",
    cat_tname: "ฮอนด้า",
    new_type: 2017,
  },
  {
    gid: 161,
    sect_code: 2900,
    sect_en: "Motorbike Dealers",
    sect_th: "ตัวแทนจำหน่ายรถจักรยานยนต์",
    cat_new: 2902,
    cat_ename: "Suzuki",
    cat_tname: "ซูซูกิ",
    new_type: 2017,
  },
  {
    gid: 162,
    sect_code: 2900,
    sect_en: "Motorbike Dealers",
    sect_th: "ตัวแทนจำหน่ายรถจักรยานยนต์",
    cat_new: 2903,
    cat_ename: "Yamaha",
    cat_tname: "ยามาฮา",
    new_type: 2017,
  },
  {
    gid: 163,
    sect_code: 2900,
    sect_en: "Motorbike Dealers",
    sect_th: "ตัวแทนจำหน่ายรถจักรยานยนต์",
    cat_new: 2904,
    cat_ename: "Kawasaki",
    cat_tname: "คาวาซากิ",
    new_type: 2017,
  },
  {
    gid: 164,
    sect_code: 2900,
    sect_en: "Motorbike Dealers",
    sect_th: "ตัวแทนจำหน่ายรถจักรยานยนต์",
    cat_new: 2905,
    cat_ename: "Vespa",
    cat_tname: "เวสป้า",
    new_type: 2017,
  },
  {
    gid: 165,
    sect_code: 2900,
    sect_en: "Motorbike Dealers",
    sect_th: "ตัวแทนจำหน่ายรถจักรยานยนต์",
    cat_new: 2906,
    cat_ename: "BMW",
    cat_tname: "บีเอ็มดับเบิ้ลยู",
    new_type: 2017,
  },
  {
    gid: 166,
    sect_code: 2900,
    sect_en: "Motorbike Dealers",
    sect_th: "ตัวแทนจำหน่ายรถจักรยานยนต์",
    cat_new: 2907,
    cat_ename: "Ducati",
    cat_tname: "ดูคาติ",
    new_type: 2017,
  },
  {
    gid: 167,
    sect_code: 2900,
    sect_en: "Motorbike Dealers",
    sect_th: "ตัวแทนจำหน่ายรถจักรยานยนต์",
    cat_new: 2908,
    cat_ename: "Harley-Davidson",
    cat_tname: "ฮาร์เลย์-เดวิดสัน",
    new_type: 2017,
  },
  {
    gid: 168,
    sect_code: 2900,
    sect_en: "Motorbike Dealers",
    sect_th: "ตัวแทนจำหน่ายรถจักรยานยนต์",
    cat_new: 2909,
    cat_ename: "Triump",
    cat_tname: "ไทรอัมพ์",
    new_type: 2017,
  },
  {
    gid: 169,
    sect_code: 2900,
    sect_en: "Motorbike Dealers",
    sect_th: "ตัวแทนจำหน่ายรถจักรยานยนต์",
    cat_new: 2998,
    cat_ename: "Used Motorbike",
    cat_tname: "จำหน่ายรถจักรยานยนต์มือสอง",
    new_type: 2017,
  },
  {
    gid: 170,
    sect_code: 2900,
    sect_en: "Motorbike Dealers",
    sect_th: "ตัวแทนจำหน่ายรถจักรยานยนต์",
    cat_new: 2999,
    cat_ename: "Other Motorbike Dealership",
    cat_tname: "ผู้แทนจำหน่ายรถจักรยานยนต์อื่นๆ",
    new_type: 2017,
  },
  {
    gid: 177,
    sect_code: 3100,
    sect_en: "Healthy-Laundry",
    sect_th: "สุขภาพความงาม-บริการซักรีด",
    cat_new: 3101,
    cat_ename: "Barber Shop",
    cat_tname: "ร้านตัดผมชาย",
    new_type: 2514,
  },
  {
    gid: 178,
    sect_code: 3100,
    sect_en: "Healthy-Laundry",
    sect_th: "สุขภาพความงาม-บริการซักรีด",
    cat_new: 3102,
    cat_ename: "Hair Cut",
    cat_tname: "ร้านเสริมสวยหญิง",
    new_type: 2514,
  },
  {
    gid: 179,
    sect_code: 3100,
    sect_en: "Healthy-Laundry",
    sect_th: "สุขภาพความงาม-บริการซักรีด",
    cat_new: 3103,
    cat_ename: "Beauty Salon",
    cat_tname: "ร้านเสริมความงาม",
    new_type: 2514,
  },
  {
    gid: 180,
    sect_code: 3100,
    sect_en: "Healthy-Laundry",
    sect_th: "สุขภาพความงาม-บริการซักรีด",
    cat_new: 3104,
    cat_ename: "Laundry",
    cat_tname: "ร้านซักรีด",
    new_type: 2514,
  },
  {
    gid: 187,
    sect_code: 4000,
    sect_en: "Bank",
    sect_th: "ธนาคาร",
    cat_new: 4001,
    cat_ename: "Bangkok Bank",
    cat_tname: "กรุงเทพ",
    new_type: 1601,
  },
  {
    gid: 188,
    sect_code: 4000,
    sect_en: "Bank",
    sect_th: "ธนาคาร",
    cat_new: 4002,
    cat_ename: "Krung Thai Bank",
    cat_tname: "กรุงไทย",
    new_type: 1608,
  },
  {
    gid: 189,
    sect_code: 4000,
    sect_en: "Bank",
    sect_th: "ธนาคาร",
    cat_new: 4003,
    cat_ename: "Bank of Ayudhya",
    cat_tname: "กรุงศรีอยุธยา",
    new_type: 1603,
  },
  {
    gid: 190,
    sect_code: 4000,
    sect_en: "Bank",
    sect_th: "ธนาคาร",
    cat_new: 4004,
    cat_ename: "Kasikorn Bank",
    cat_tname: "กสิกรไทย",
    new_type: 1606,
  },
  {
    gid: 191,
    sect_code: 4000,
    sect_en: "Bank",
    sect_th: "ธนาคาร",
    cat_new: 4005,
    cat_ename: "Siam Commercial Bank",
    cat_tname: "ไทยพาณิชย์",
    new_type: 1611,
  },
  {
    gid: 192,
    sect_code: 4000,
    sect_en: "Bank",
    sect_th: "ธนาคาร",
    cat_new: 4006,
    cat_ename: "TMB",
    cat_tname: "ทหารไทย",
    new_type: 1614,
  },
  {
    gid: 193,
    sect_code: 4000,
    sect_en: "Bank",
    sect_th: "ธนาคาร",
    cat_new: 4007,
    cat_ename: "Thanachart Bank",
    cat_tname: "ธนชาต",
    new_type: 1615,
  },
  {
    gid: 194,
    sect_code: 4000,
    sect_en: "Bank",
    sect_th: "ธนาคาร",
    cat_new: 4008,
    cat_ename: "Government Savings Bank",
    cat_tname: "ออมสิน",
    new_type: 1617,
  },
  {
    gid: 195,
    sect_code: 4000,
    sect_en: "Bank",
    sect_th: "ธนาคาร",
    cat_new: 4009,
    cat_ename: "Government Housing Bank",
    cat_tname: "อาคารสงเคราะห์",
    new_type: 1616,
  },
  {
    gid: 196,
    sect_code: 4000,
    sect_en: "Bank",
    sect_th: "ธนาคาร",
    cat_new: 4010,
    cat_ename: "BAAC",
    cat_tname: "ธกส.",
    new_type: 1602,
  },
  {
    gid: 197,
    sect_code: 4000,
    sect_en: "Bank",
    sect_th: "ธนาคาร",
    cat_new: 4011,
    cat_ename: "Islamic Bank of Thailand",
    cat_tname: "อิสลามแห่งประเทศไทย",
    new_type: 1605,
  },
  {
    gid: 198,
    sect_code: 4000,
    sect_en: "Bank",
    sect_th: "ธนาคาร",
    cat_new: 4012,
    cat_ename: "SME Bank",
    cat_tname: "เอสเอ็มอี",
    new_type: 1612,
  },
  {
    gid: 199,
    sect_code: 4000,
    sect_en: "Bank",
    sect_th: "ธนาคาร",
    cat_new: 4013,
    cat_ename: "UOB Bank",
    cat_tname: "ยูโอบี",
    new_type: 1619,
  },
  {
    gid: 200,
    sect_code: 4000,
    sect_en: "Bank",
    sect_th: "ธนาคาร",
    cat_new: 4014,
    cat_ename: "CIMB Thai",
    cat_tname: "ซีไอเอ็มบี ไทย",
    new_type: 1604,
  },
  {
    gid: 201,
    sect_code: 4000,
    sect_en: "Bank",
    sect_th: "ธนาคาร",
    cat_new: 4015,
    cat_ename: "Kiatnakin Bank",
    cat_tname: "เกียรตินาคิน",
    new_type: 1607,
  },
  {
    gid: 202,
    sect_code: 4000,
    sect_en: "Bank",
    sect_th: "ธนาคาร",
    cat_new: 4016,
    cat_ename: "Land & House Retail Bank",
    cat_tname: "แลนด์ แอนด์ เฮ้าส์",
    new_type: 1609,
  },
  {
    gid: 203,
    sect_code: 4000,
    sect_en: "Bank",
    sect_th: "ธนาคาร",
    cat_new: 4017,
    cat_ename: "Standard Chartered",
    cat_tname: "สแตนดาร์ดชาร์เตอร์",
    new_type: 1613,
  },
  {
    gid: 205,
    sect_code: 4000,
    sect_en: "Bank",
    sect_th: "ธนาคาร",
    cat_new: 4019,
    cat_ename: "Citibank",
    cat_tname: "ซิตี้แบงก์",
    new_type: 1620,
  },
  {
    gid: 206,
    sect_code: 4000,
    sect_en: "Bank",
    sect_th: "ธนาคาร",
    cat_new: 4020,
    cat_ename: "ICBC (Thai)",
    cat_tname: "ไอซีบีซี (ไทย)",
    new_type: 1621,
  },
  {
    gid: 207,
    sect_code: 4000,
    sect_en: "Bank",
    sect_th: "ธนาคาร",
    cat_new: 4099,
    cat_ename: "Other Bank",
    cat_tname: "ธนาคารอื่นๆ",
    new_type: 1622,
  },
  {
    gid: 208,
    sect_code: 4100,
    sect_en: "Finance-Leasing",
    sect_th: "สินเชื่อ-เช่าซื้อ",
    cat_new: 4101,
    cat_ename: "Financial & Leasing Service",
    cat_tname: "สินเชื่อ-เช่าซื้อ",
    new_type: 1622,
  },
  {
    gid: 209,
    sect_code: 4200,
    sect_en: "Insurrance",
    sect_th: "ประกันภัย",
    cat_new: 4201,
    cat_ename: "Insurrance Service",
    cat_tname: "ธุรกิจประกันภัย",
    new_type: 1622,
  },
  {
    gid: 217,
    sect_code: 4400,
    sect_en: "Company/Factory",
    sect_th: "สถานประกอบการ",
    cat_new: 4403,
    cat_ename: "Accountting Office",
    cat_tname: "สำนักงานบัญชี",
    new_type: 1002,
  },
  {
    gid: 218,
    sect_code: 4400,
    sect_en: "Company/Factory",
    sect_th: "สถานประกอบการ",
    cat_new: 4404,
    cat_ename: "AAPICO Group",
    cat_tname: "อาปิโก้ กรุ๊ป",
    new_type: 1001,
  },
  {
    gid: 219,
    sect_code: 4400,
    sect_en: "Company/Factory",
    sect_th: "สถานประกอบการ",
    cat_new: 4405,
    cat_ename: "SME",
    cat_tname: "เอสเอ็มอี",
    new_type: 1004,
  },
  {
    gid: 220,
    sect_code: 4400,
    sect_en: "Company/Factory",
    sect_th: "สถานประกอบการ",
    cat_new: 4406,
    cat_ename: "Factory/Warehouse",
    cat_tname: "โรงงาน/คลังสินค้า",
    new_type: 2807,
  },
  {
    gid: 221,
    sect_code: 4400,
    sect_en: "Company/Factory",
    sect_th: "สถานประกอบการ",
    cat_new: 4407,
    cat_ename: "Industrial Estate",
    cat_tname: "นิคมอุตสาหกรรม",
    new_type: 1003,
  },
  {
    gid: 222,
    sect_code: 4400,
    sect_en: "Company/Factory",
    sect_th: "สถานประกอบการ",
    cat_new: 4499,
    cat_ename: "Other Business",
    cat_tname: "ธุรกิจอื่นๆ",
    new_type: 1005,
  },
  {
    gid: 171,
    sect_code: 2950,
    sect_en: "Motorbike Service",
    sect_th: "บริการด้านรถจักรยานยนต์",
    cat_new: 2951,
    cat_ename: "Motorbike Repair",
    cat_tname: "อู่ซ่อมรถจักรยานยนต์",
    new_type: 2110,
  },
  {
    gid: 210,
    sect_code: 4300,
    sect_en: "Accommodation",
    sect_th: "โรงแรม-ที่พัก",
    cat_new: 4301,
    cat_ename: "Hotel",
    cat_tname: "โรงแรม",
    new_type: "0404",
  },
  {
    gid: 245,
    sect_code: 4700,
    sect_en: "Market",
    sect_th: "ตลาด",
    cat_new: 4701,
    cat_ename: "Fresh Food Market",
    cat_tname: "ตลาดสด",
    new_type: 1901,
  },
  {
    gid: 246,
    sect_code: 4700,
    sect_en: "Market",
    sect_th: "ตลาด",
    cat_new: 4702,
    cat_ename: "Flea Market",
    cat_tname: "ตลาดนัด",
    new_type: 1901,
  },
  {
    gid: 247,
    sect_code: 4700,
    sect_en: "Market",
    sect_th: "ตลาด",
    cat_new: 4703,
    cat_ename: "Night Market",
    cat_tname: "ตลาดนัดกลางคืน",
    new_type: 1901,
  },
  {
    gid: 248,
    sect_code: 4700,
    sect_en: "Market",
    sect_th: "ตลาด",
    cat_new: 4799,
    cat_ename: "Other Market",
    cat_tname: "ตลาดอื่นๆ",
    new_type: 1901,
  },
  {
    gid: 249,
    sect_code: 4800,
    sect_en: "Coopertives-Association",
    sect_th: "สหกรณ์-สมาคม",
    cat_new: 4801,
    cat_ename: "Cooperatives",
    cat_tname: "สหกรณ์",
    new_type: 2803,
  },
  {
    gid: 250,
    sect_code: 4800,
    sect_en: "Coopertives-Association",
    sect_th: "สหกรณ์-สมาคม",
    cat_new: 4802,
    cat_ename: "Foundation/Welfare Center",
    cat_tname: "มูลนิธิ/ศูนย์สวัสดิการ",
    new_type: 2804,
  },
  {
    gid: 252,
    sect_code: 4800,
    sect_en: "Coopertives-Association",
    sect_th: "สหกรณ์-สมาคม",
    cat_new: 4804,
    cat_ename: "Union",
    cat_tname: "สหภาพ",
    new_type: 2809,
  },
  {
    gid: 253,
    sect_code: 4800,
    sect_en: "Coopertives-Association",
    sect_th: "สหกรณ์-สมาคม",
    cat_new: 4805,
    cat_ename: "NGO",
    cat_tname: "องค์กรไม่แสวงหาผลกำไร",
    new_type: 2804,
  },
  {
    gid: 264,
    sect_code: 5100,
    sect_en: "Pubic Uitilities",
    sect_th: "หน่วยงานบริการสาธารณะ",
    cat_new: 5101,
    cat_ename: "Post Office",
    cat_tname: "ไปรษณีย์",
    new_type: 2602,
  },
  {
    gid: 265,
    sect_code: 5100,
    sect_en: "Pubic Uitilities",
    sect_th: "หน่วยงานบริการสาธารณะ",
    cat_new: 5102,
    cat_ename: "Electricity Authority Office",
    cat_tname: "การไฟฟ้า",
    new_type: 2601,
  },
  {
    gid: 266,
    sect_code: 5100,
    sect_en: "Pubic Uitilities",
    sect_th: "หน่วยงานบริการสาธารณะ",
    cat_new: 5103,
    cat_ename: "Water Organization",
    cat_tname: "การประปา",
    new_type: 2604,
  },
  {
    gid: 268,
    sect_code: 5100,
    sect_en: "Pubic Uitilities",
    sect_th: "หน่วยงานบริการสาธารณะ",
    cat_new: 5105,
    cat_ename: "Police Station",
    cat_tname: "สถานีตำรวจ",
    new_type: 1104,
  },
  {
    gid: 269,
    sect_code: 5100,
    sect_en: "Pubic Uitilities",
    sect_th: "หน่วยงานบริการสาธารณะ",
    cat_new: 5106,
    cat_ename: "Fire Station",
    cat_tname: "สถานีดับเพลิง",
    new_type: 1101,
  },
  {
    gid: 273,
    sect_code: 5100,
    sect_en: "Pubic Uitilities",
    sect_th: "หน่วยงานบริการสาธารณะ",
    cat_new: 5110,
    cat_ename: "Community Hall",
    cat_tname: "ศาลาประชาคม",
    new_type: 2703,
  },
  {
    gid: 274,
    sect_code: 5100,
    sect_en: "Pubic Uitilities",
    sect_th: "หน่วยงานบริการสาธารณะ",
    cat_new: 5111,
    cat_ename: "Police Booth",
    cat_tname: "ป้อมตำรวจ",
    new_type: 1103,
  },
  {
    gid: 275,
    sect_code: 5100,
    sect_en: "Pubic Uitilities",
    sect_th: "หน่วยงานบริการสาธารณะ",
    cat_new: 5112,
    cat_ename: "EMS",
    cat_tname: "บริการทางการแพทย์แบบฉุกเฉิน",
    new_type: 1102,
  },
  {
    gid: 276,
    sect_code: 5100,
    sect_en: "Pubic Uitilities",
    sect_th: "หน่วยงานบริการสาธารณะ",
    cat_new: 5113,
    cat_ename: "Other Emergency",
    cat_tname: "ศูนย์ช่วยเหลือฉุกเฉินอื่นๆ",
    new_type: 1105,
  },
  {
    gid: 277,
    sect_code: 5100,
    sect_en: "Pubic Uitilities",
    sect_th: "หน่วยงานบริการสาธารณะ",
    cat_new: 5114,
    cat_ename: "Slaughterhouse",
    cat_tname: "โรงฆ่าสัตว์",
    new_type: 2605,
  },
  {
    gid: 278,
    sect_code: 5100,
    sect_en: "Pubic Uitilities",
    sect_th: "หน่วยงานบริการสาธารณะ",
    cat_new: 5199,
    cat_ename: "Other Public Utility",
    cat_tname: "หน่วยงานบริการสาธารณะอื่นๆ",
    new_type: 2605,
  },
  {
    gid: 279,
    sect_code: 5200,
    sect_en: "Transportain",
    sect_th: "การคมนาคมขนส่ง",
    cat_new: 5201,
    cat_ename: "Bus Station",
    cat_tname: "สถานีขนส่งผู้โดยสาร",
    new_type: 1302,
  },
  {
    gid: 281,
    sect_code: 5200,
    sect_en: "Transportain",
    sect_th: "การคมนาคมขนส่ง",
    cat_new: 5203,
    cat_ename: "Railway Station",
    cat_tname: "สถานีรถไฟ",
    new_type: 1305,
  },
  {
    gid: 282,
    sect_code: 5200,
    sect_en: "Transportain",
    sect_th: "การคมนาคมขนส่ง",
    cat_new: 5204,
    cat_ename: "Airport",
    cat_tname: "สนามบินพาณิชย์",
    new_type: 1301,
  },
  {
    gid: 283,
    sect_code: 5200,
    sect_en: "Transportain",
    sect_th: "การคมนาคมขนส่ง",
    cat_new: 5205,
    cat_ename: "Other Airport",
    cat_tname: "สนามบินอื่นๆ",
    new_type: 1303,
  },
  {
    gid: 284,
    sect_code: 5200,
    sect_en: "Transportain",
    sect_th: "การคมนาคมขนส่ง",
    cat_new: 5206,
    cat_ename: "Commodity Transport Station",
    cat_tname: "สถานีขนส่งสินค้า",
    new_type: 1309,
  },
  {
    gid: 285,
    sect_code: 5200,
    sect_en: "Transportain",
    sect_th: "การคมนาคมขนส่ง",
    cat_new: 5207,
    cat_ename: "Airport Rail Link Station",
    cat_tname: "แอร์พอร์ตลิงค์",
    new_type: 1304,
  },
  {
    gid: 286,
    sect_code: 5200,
    sect_en: "Transportain",
    sect_th: "การคมนาคมขนส่ง",
    cat_new: 5208,
    cat_ename: "MRT Station",
    cat_tname: "รถไฟฟ้าใต้ดินเอ็มอาร์ที",
    new_type: 1306,
  },
  {
    gid: 287,
    sect_code: 5200,
    sect_en: "Transportain",
    sect_th: "การคมนาคมขนส่ง",
    cat_new: 5209,
    cat_ename: "BTS Station",
    cat_tname: "รถไฟฟ้าบีทีเอส",
    new_type: 1307,
  },
  {
    gid: 288,
    sect_code: 5200,
    sect_en: "Transportain",
    sect_th: "การคมนาคมขนส่ง",
    cat_new: 5210,
    cat_ename: "Motorcycle Taxi Station",
    cat_tname: "วินมอเตอร์ไซค์",
    new_type: 1311,
  },
  {
    gid: 58,
    sect_code: 2300,
    sect_en: "Bakery",
    sect_th: "เบเกอรี่",
    cat_new: 2301,
    cat_ename: "Dunkin Donut",
    cat_tname: "ดังกิ้น โดนัท",
    new_type: "0216",
  },
  {
    gid: 289,
    sect_code: 5200,
    sect_en: "Transportain",
    sect_th: "การคมนาคมขนส่ง",
    cat_new: 5299,
    cat_ename: "Other Transportation",
    cat_tname: "การขนส่งอื่นๆ",
    new_type: 1310,
  },
  {
    gid: 290,
    sect_code: 5300,
    sect_en: "Facilities",
    sect_th: "สาธารณูปโภค",
    cat_new: 5301,
    cat_ename: "ATM",
    cat_tname: "เอทีเอ็ม",
    new_type: 1701,
  },
  {
    gid: 291,
    sect_code: 5300,
    sect_en: "Facilities",
    sect_th: "สาธารณูปโภค",
    cat_new: 5302,
    cat_ename: "Money Exchange",
    cat_tname: "แลกเปลี่ยนเงินตรา",
    new_type: 1702,
  },
  {
    gid: 292,
    sect_code: 5300,
    sect_en: "Facilities",
    sect_th: "สาธารณูปโภค",
    cat_new: 5303,
    cat_ename: "ADM",
    cat_tname: "เอดีเอ็ม",
    new_type: 1703,
  },
  {
    gid: 293,
    sect_code: 5300,
    sect_en: "Facilities",
    sect_th: "สาธารณูปโภค",
    cat_new: 5304,
    cat_ename: "Parking",
    cat_tname: "ที่จอดรถ",
    new_type: 2401,
  },
  {
    gid: 294,
    sect_code: 5300,
    sect_en: "Facilities",
    sect_th: "สาธารณูปโภค",
    cat_new: 5305,
    cat_ename: "Intersection",
    cat_tname: "สี่แยก",
    new_type: 2805,
  },
  {
    gid: 295,
    sect_code: 5300,
    sect_en: "Facilities",
    sect_th: "สาธารณูปโภค",
    cat_new: 5306,
    cat_ename: "Toll Gate",
    cat_tname: "ด่านเก็บเงิน",
    new_type: 2806,
  },
  {
    gid: 296,
    sect_code: 5300,
    sect_en: "Facilities",
    sect_th: "สาธารณูปโภค",
    cat_new: 5307,
    cat_ename: "Red Light Camera",
    cat_tname: "กล้องตรวจจับไฟแดง",
    new_type: 2810,
  },
  {
    gid: 297,
    sect_code: 5300,
    sect_en: "Facilities",
    sect_th: "สาธารณูปโภค",
    cat_new: 5308,
    cat_ename: "Speed Camera (Express Way)",
    cat_tname: "กล้องตรวจจับความเร็ว (ทางด่วน)",
    new_type: 2811,
  },
  {
    gid: 298,
    sect_code: 5300,
    sect_en: "Facilities",
    sect_th: "สาธารณูปโภค",
    cat_new: 5309,
    cat_ename: "Speed Camera (ASIA Road)",
    cat_tname: "กล้องตรวจจับความเร็ว (ถนนสายเอเชีย)",
    new_type: 2812,
  },
  {
    gid: 299,
    sect_code: 5300,
    sect_en: "Facilities",
    sect_th: "สาธารณูปโภค",
    cat_new: 5310,
    cat_ename: "Speed Camera (Normal)",
    cat_tname: "กล้องตรวจจับความเร็ว (ถนนทั่วไป)",
    new_type: 2813,
  },
  {
    gid: 300,
    sect_code: 5300,
    sect_en: "Facilities",
    sect_th: "สาธารณูปโภค",
    cat_new: 5311,
    cat_ename: "Tunnel",
    cat_tname: "อุโมงค์",
    new_type: 2814,
  },
  {
    gid: 301,
    sect_code: 5300,
    sect_en: "Facilities",
    sect_th: "สาธารณูปโภค",
    cat_new: 5312,
    cat_ename: "Bridge (Big Bridge)",
    cat_tname: "สะพาน",
    new_type: 2815,
  },
  {
    gid: 302,
    sect_code: 5300,
    sect_en: "Facilities",
    sect_th: "สาธารณูปโภค",
    cat_new: 5313,
    cat_ename: "Gate Entrance",
    cat_tname: "ประตูทางเข้า",
    new_type: 2605,
  },
  {
    gid: 303,
    sect_code: 5300,
    sect_en: "Facilities",
    sect_th: "สาธารณูปโภค",
    cat_new: 5314,
    cat_ename: "Border Gate",
    cat_tname: "ประตูชายแดน",
    new_type: 2816,
  },
  {
    gid: 304,
    sect_code: 5300,
    sect_en: "Facilities",
    sect_th: "สาธารณูปโภค",
    cat_new: 5399,
    cat_ename: "Other Facilities",
    cat_tname: "สาธารณูปโภคอื่นๆ",
    new_type: 1704,
  },
  {
    gid: 305,
    sect_code: 6000,
    sect_en: "Medical",
    sect_th: "บริการทางการแพทย์",
    cat_new: 6001,
    cat_ename: "Pharmacy",
    cat_tname: "ร้านขายยา",
    new_type: 1204,
  },
  {
    gid: 306,
    sect_code: 6000,
    sect_en: "Medical",
    sect_th: "บริการทางการแพทย์",
    cat_new: 6002,
    cat_ename: "Clinic",
    cat_tname: "คลีนิก",
    new_type: 1205,
  },
  {
    gid: 307,
    sect_code: 6000,
    sect_en: "Medical",
    sect_th: "บริการทางการแพทย์",
    cat_new: 6003,
    cat_ename: "Dental Clinic",
    cat_tname: "ทันตกรรม",
    new_type: 1205,
  },
  {
    gid: 308,
    sect_code: 6000,
    sect_en: "Medical",
    sect_th: "บริการทางการแพทย์",
    cat_new: 6004,
    cat_ename: "Private Hospital",
    cat_tname: "โรงพยาบาลเอกชน",
    new_type: 1203,
  },
  {
    gid: 309,
    sect_code: 6000,
    sect_en: "Medical",
    sect_th: "บริการทางการแพทย์",
    cat_new: 6005,
    cat_ename: "Government Hospital",
    cat_tname: "โรงพยาบาลรัฐ",
    new_type: 1203,
  },
  {
    gid: 310,
    sect_code: 6000,
    sect_en: "Medical",
    sect_th: "บริการทางการแพทย์",
    cat_new: 6006,
    cat_ename: "Animal Hospital",
    cat_tname: "สถานรักษาพยาบาลสัตว์",
    new_type: 1201,
  },
  {
    gid: 311,
    sect_code: 6000,
    sect_en: "Medical",
    sect_th: "บริการทางการแพทย์",
    cat_new: 6007,
    cat_ename: "Health Center",
    cat_tname: "ศูนย์บริการสาธารณสุข",
    new_type: 1202,
  },
  {
    gid: 312,
    sect_code: 6000,
    sect_en: "Medical",
    sect_th: "บริการทางการแพทย์",
    cat_new: 6099,
    cat_ename: "Other Medical",
    cat_tname: "เกี่ยวกับการแพทย์อื่นๆ",
    new_type: 1206,
  },
  {
    gid: 313,
    sect_code: 7000,
    sect_en: "Educations",
    sect_th: "สถาบันการศึกษา",
    cat_new: 7001,
    cat_ename: "School",
    cat_tname: "โรงเรียน",
    new_type: 2203,
  },
  {
    gid: 315,
    sect_code: 7000,
    sect_en: "Educations",
    sect_th: "สถาบันการศึกษา",
    cat_new: 7003,
    cat_ename: "International School",
    cat_tname: "โรงเรียนนานาชาติ",
    new_type: 2204,
  },
  {
    gid: 316,
    sect_code: 7000,
    sect_en: "Educations",
    sect_th: "สถาบันการศึกษา",
    cat_new: 7004,
    cat_ename: "Tutorials",
    cat_tname: "โรงเรียนกวดวิชา",
    new_type: 2206,
  },
  {
    gid: 317,
    sect_code: 7000,
    sect_en: "Educations",
    sect_th: "สถาบันการศึกษา",
    cat_new: 7005,
    cat_ename: "College",
    cat_tname: "วิทยาลัย",
    new_type: 2202,
  },
  {
    gid: 318,
    sect_code: 7000,
    sect_en: "Educations",
    sect_th: "สถาบันการศึกษา",
    cat_new: 7006,
    cat_ename: "University",
    cat_tname: "มหาวิทยาลัย",
    new_type: 2201,
  },
  {
    gid: 319,
    sect_code: 7000,
    sect_en: "Educations",
    sect_th: "สถาบันการศึกษา",
    cat_new: 7007,
    cat_ename: "Library",
    cat_tname: "ห้องสมุด",
    new_type: 2207,
  },
  {
    gid: 320,
    sect_code: 7000,
    sect_en: "Educations",
    sect_th: "สถาบันการศึกษา",
    cat_new: 7099,
    cat_ename: "Other Education",
    cat_tname: "สถานศึกษาอื่นๆ",
    new_type: 2208,
  },
  {
    gid: 321,
    sect_code: 8000,
    sect_en: "Religions",
    sect_th: "สถาบันทางศาสนา",
    cat_new: 8001,
    cat_ename: "Temple",
    cat_tname: "วัด",
    new_type: 2305,
  },
  {
    gid: 322,
    sect_code: 8000,
    sect_en: "Religions",
    sect_th: "สถาบันทางศาสนา",
    cat_new: 8002,
    cat_ename: "Mosque",
    cat_tname: "มิสยิด",
    new_type: 2304,
  },
  {
    gid: 324,
    sect_code: 8000,
    sect_en: "Religions",
    sect_th: "สถาบันทางศาสนา",
    cat_new: 8004,
    cat_ename: "Shrine",
    cat_tname: "ศาลเจ้า",
    new_type: 2302,
  },
  {
    gid: 326,
    sect_code: 8100,
    sect_en: "Religions",
    sect_th: "สุสาน/ที่ฝังศพ",
    cat_new: 8101,
    cat_ename: "Cemetery",
    cat_tname: "สุสาน",
    new_type: 2301,
  },
  {
    gid: 345,
    sect_code: 9000,
    sect_en: "Sports",
    sect_th: "กีฬา-นันทนาการ",
    cat_new: 9008,
    cat_ename: "Bowling",
    cat_tname: "โบว์ลิ่ง",
    new_type: "0801",
  },
  {
    gid: 20,
    sect_code: 2000,
    sect_en: "Fast Food",
    sect_th: "อาหารจานด่วน",
    cat_new: 2001,
    cat_ename: "KFC",
    cat_tname: "เคเอฟซี",
    new_type: "0204",
  },
  {
    gid: 21,
    sect_code: 2000,
    sect_en: "Fast Food",
    sect_th: "อาหารจานด่วน",
    cat_new: 2002,
    cat_ename: "Pizza Hut",
    cat_tname: "พิซซ่า ฮัท",
    new_type: "0207",
  },
  {
    gid: 325,
    sect_code: 8000,
    sect_en: "Religions",
    sect_th: "สถาบันทางศาสนา",
    cat_new: 8099,
    cat_ename: "Other Religion",
    cat_tname: "ศาสนาสถานอื่นๆ",
    new_type: 2306,
  },
  {
    gid: 22,
    sect_code: 2000,
    sect_en: "Fast Food",
    sect_th: "อาหารจานด่วน",
    cat_new: 2003,
    cat_ename: "The Pizza Company",
    cat_tname: "เดอะพิซซ่าคอมปะนี",
    new_type: "0208",
  },
  {
    gid: 23,
    sect_code: 2000,
    sect_en: "Fast Food",
    sect_th: "อาหารจานด่วน",
    cat_new: 2004,
    cat_ename: "Mc Donald's",
    cat_tname: "แมคโดนัลด์",
    new_type: "0205",
  },
  {
    gid: 24,
    sect_code: 2000,
    sect_en: "Fast Food",
    sect_th: "อาหารจานด่วน",
    cat_new: 2005,
    cat_ename: "Chester's Grill",
    cat_tname: "เชสเตอร์ กริลล์",
    new_type: "0203",
  },
  {
    gid: 25,
    sect_code: 2000,
    sect_en: "Fast Food",
    sect_th: "อาหารจานด่วน",
    cat_new: 2006,
    cat_ename: "A&W",
    cat_tname: "เอ แอนด์ ดับบลิว",
    new_type: "0201",
  },
  {
    gid: 26,
    sect_code: 2000,
    sect_en: "Fast Food",
    sect_th: "อาหารจานด่วน",
    cat_new: 2007,
    cat_ename: "Burger King",
    cat_tname: "เบอร์เกอร์ คิง",
    new_type: "0202",
  },
  {
    gid: 27,
    sect_code: 2000,
    sect_en: "Fast Food",
    sect_th: "อาหารจานด่วน",
    cat_new: 2008,
    cat_ename: "Narai Pizzeria",
    cat_tname: "นารายณ์พิซเซอเรีย",
    new_type: "0206",
  },
  {
    gid: 28,
    sect_code: 2000,
    sect_en: "Fast Food",
    sect_th: "อาหารจานด่วน",
    cat_new: 2099,
    cat_ename: "Other Fast Food",
    cat_tname: "จานด่วนอื่นๆ",
    new_type: "0221",
  },
  {
    gid: 29,
    sect_code: 2100,
    sect_en: "Restaurant",
    sect_th: "ร้านอาหาร",
    cat_new: 2101,
    cat_ename: "Bar B.Q. Plaza",
    cat_tname: "บาร์บีคิว พลาซ่า",
    new_type: "0301",
  },
  {
    gid: 30,
    sect_code: 2100,
    sect_en: "Restaurant",
    sect_th: "ร้านอาหาร",
    cat_new: 2102,
    cat_ename: "Daidomon",
    cat_tname: "ไดโดมอน",
    new_type: "0302",
  },
  {
    gid: 31,
    sect_code: 2100,
    sect_en: "Restaurant",
    sect_th: "ร้านอาหาร",
    cat_new: 2103,
    cat_ename: "Fuji",
    cat_tname: "ฟูจิ",
    new_type: "0303",
  },
  {
    gid: 32,
    sect_code: 2100,
    sect_en: "Restaurant",
    sect_th: "ร้านอาหาร",
    cat_new: 2104,
    cat_ename: "Hachibun Ramen",
    cat_tname: "ฮะจิบัง ราเมน",
    new_type: "0304",
  },
  {
    gid: 33,
    sect_code: 2100,
    sect_en: "Restaurant",
    sect_th: "ร้านอาหาร",
    cat_new: 2105,
    cat_ename: "Joom Zap Hut",
    cat_tname: "จุ่มแซ่บ ฮัท",
    new_type: "0305",
  },
  {
    gid: 34,
    sect_code: 2100,
    sect_en: "Restaurant",
    sect_th: "ร้านอาหาร",
    cat_new: 2106,
    cat_ename: "MK Restaurants",
    cat_tname: "เอ็มเค เรสโตรองต์",
    new_type: "0306",
  },
  {
    gid: 35,
    sect_code: 2100,
    sect_en: "Restaurant",
    sect_th: "ร้านอาหาร",
    cat_new: 2107,
    cat_ename: "Oishi",
    cat_tname: "โออิชิ",
    new_type: "0307",
  },
  {
    gid: 36,
    sect_code: 2100,
    sect_en: "Restaurant",
    sect_th: "ร้านอาหาร",
    cat_new: 2108,
    cat_ename: "Ootoya",
    cat_tname: "โอโตยะ",
    new_type: "0308",
  },
  {
    gid: 37,
    sect_code: 2100,
    sect_en: "Restaurant",
    sect_th: "ร้านอาหาร",
    cat_new: 2109,
    cat_ename: "Seefah",
    cat_tname: "สีฟ้า",
    new_type: "0309",
  },
  {
    gid: 38,
    sect_code: 2100,
    sect_en: "Restaurant",
    sect_th: "ร้านอาหาร",
    cat_new: 2110,
    cat_ename: "Sizzler",
    cat_tname: "ซิซซ์เล่อร์",
    new_type: "0310",
  },
  {
    gid: 39,
    sect_code: 2100,
    sect_en: "Restaurant",
    sect_th: "ร้านอาหาร",
    cat_new: 2111,
    cat_ename: "Zen",
    cat_tname: "ร้านอาหารญี่ปุ่นเซน",
    new_type: "0311",
  },
  {
    gid: 40,
    sect_code: 2100,
    sect_en: "Restaurant",
    sect_th: "ร้านอาหาร",
    cat_new: 2112,
    cat_ename: "Thai-Chinesse",
    cat_tname: "ไทย-จีน",
    new_type: "0312",
  },
  {
    gid: 43,
    sect_code: 2100,
    sect_en: "Restaurant",
    sect_th: "ร้านอาหาร",
    cat_new: 2115,
    cat_ename: "Nooddle",
    cat_tname: "ก๋วยเตี๋ยว",
    new_type: "0312",
  },
  {
    gid: 45,
    sect_code: 2100,
    sect_en: "Restaurant",
    sect_th: "ร้านอาหาร",
    cat_new: 2117,
    cat_ename: "Muslim",
    cat_tname: "อิสลาม",
    new_type: "0312",
  },
  {
    gid: 46,
    sect_code: 2100,
    sect_en: "Restaurant",
    sect_th: "ร้านอาหาร",
    cat_new: 2118,
    cat_ename: "Japanese",
    cat_tname: "ญี่ปุ่น",
    new_type: "0312",
  },
  {
    gid: 47,
    sect_code: 2100,
    sect_en: "Restaurant",
    sect_th: "ร้านอาหาร",
    cat_new: 2119,
    cat_ename: "Italian",
    cat_tname: "อิตาเลียน",
    new_type: "0312",
  },
  {
    gid: 48,
    sect_code: 2100,
    sect_en: "Restaurant",
    sect_th: "ร้านอาหาร",
    cat_new: 2120,
    cat_ename: "Vietnamese",
    cat_tname: "เวียดนาม",
    new_type: "0312",
  },
  {
    gid: 49,
    sect_code: 2100,
    sect_en: "Restaurant",
    sect_th: "ร้านอาหาร",
    cat_new: 2121,
    cat_ename: "Korean",
    cat_tname: "เกาหลี",
    new_type: "0312",
  },
  {
    gid: 50,
    sect_code: 2100,
    sect_en: "Restaurant",
    sect_th: "ร้านอาหาร",
    cat_new: 2122,
    cat_ename: "American",
    cat_tname: "อเมริกัน",
    new_type: "0312",
  },
  {
    gid: 51,
    sect_code: 2100,
    sect_en: "Restaurant",
    sect_th: "ร้านอาหาร",
    cat_new: 2199,
    cat_ename: "Other Restaurant",
    cat_tname: "ร้านอาหารอื่นๆ",
    new_type: "0312",
  },
  {
    gid: 52,
    sect_code: 2200,
    sect_en: "Coffee",
    sect_th: "กาแฟ",
    cat_new: 2201,
    cat_ename: "Starbucks",
    cat_tname: "สตาร์บัคส์",
    new_type: "0219",
  },
  {
    gid: 53,
    sect_code: 2200,
    sect_en: "Coffee",
    sect_th: "กาแฟ",
    cat_new: 2202,
    cat_ename: "Cafe Amazon",
    cat_tname: "คาเฟ่ อเมซอน",
    new_type: "0213",
  },
  {
    gid: 54,
    sect_code: 2200,
    sect_en: "Coffee",
    sect_th: "กาแฟ",
    cat_new: 2203,
    cat_ename: "Black Canyon",
    cat_tname: "แบล็ค แคนยอน",
    new_type: "0212",
  },
  {
    gid: 55,
    sect_code: 2200,
    sect_en: "Coffee",
    sect_th: "กาแฟ",
    cat_new: 2204,
    cat_ename: "Ban Rai Coffee",
    cat_tname: "บ้านไร่กาแฟ",
    new_type: "0210",
  },
  {
    gid: 56,
    sect_code: 2200,
    sect_en: "Coffee",
    sect_th: "กาแฟ",
    cat_new: 2205,
    cat_ename: "Coffee World",
    cat_tname: "คอฟฟี่ เวิลด์",
    new_type: "0214",
  },
  {
    gid: 57,
    sect_code: 2200,
    sect_en: "Coffee",
    sect_th: "กาแฟ",
    cat_new: 2299,
    cat_ename: "Other Coffee Shop",
    cat_tname: "ร้านกาแฟอื่นๆ",
    new_type: "0222",
  },
  {
    gid: 59,
    sect_code: 2300,
    sect_en: "Bakery",
    sect_th: "เบเกอรี่",
    cat_new: 2302,
    cat_ename: "Mister Donut",
    cat_tname: "มิสเตอร์ โดนัท",
    new_type: "0217",
  },
  {
    gid: 60,
    sect_code: 2300,
    sect_en: "Bakery",
    sect_th: "เบเกอรี่",
    cat_new: 2303,
    cat_ename: "Swensen's",
    cat_tname: "สเวนเซ่นส์",
    new_type: "0220",
  },
  {
    gid: 61,
    sect_code: 2300,
    sect_en: "Bakery",
    sect_th: "เบเกอรี่",
    cat_new: 2304,
    cat_ename: "Baskin Robbins",
    cat_tname: "บาสกิน รอบบิ้นส์",
    new_type: "0211",
  },
  {
    gid: 62,
    sect_code: 2300,
    sect_en: "Bakery",
    sect_th: "เบเกอรี่",
    cat_new: 2305,
    cat_ename: "Dairy Queen",
    cat_tname: "แดรี่ ควีน",
    new_type: "0215",
  },
  {
    gid: 108,
    sect_code: 2600,
    sect_en: "Construction Materials",
    sect_th: "วัสดุอุปกรณ์ก่อสร้าง",
    cat_new: 2601,
    cat_ename: "Boonthavorn",
    cat_tname: "บุญถาวร",
    new_type: "0112",
  },
  {
    gid: 109,
    sect_code: 2600,
    sect_en: "Construction Materials",
    sect_th: "วัสดุอุปกรณ์ก่อสร้าง",
    cat_new: 2602,
    cat_ename: "Home Pro",
    cat_tname: "โฮมโปร",
    new_type: "0115",
  },
  {
    gid: 110,
    sect_code: 2600,
    sect_en: "Construction Materials",
    sect_th: "วัสดุอุปกรณ์ก่อสร้าง",
    cat_new: 2603,
    cat_ename: "Home Mart",
    cat_tname: "โฮมมาร์ท",
    new_type: "0114",
  },
  {
    gid: 111,
    sect_code: 2600,
    sect_en: "Construction Materials",
    sect_th: "วัสดุอุปกรณ์ก่อสร้าง",
    cat_new: 2604,
    cat_ename: "Index Living Mall",
    cat_tname: "อินเด็กซ์ ลิฟวิ่งมอลล์",
    new_type: "0113",
  },
  {
    gid: 112,
    sect_code: 2600,
    sect_en: "Construction Materials",
    sect_th: "วัสดุอุปกรณ์ก่อสร้าง",
    cat_new: 2605,
    cat_ename: "Global House",
    cat_tname: "โกลบอลเฮาส์",
    new_type: "0111",
  },
  {
    gid: 113,
    sect_code: 2600,
    sect_en: "Construction Materials",
    sect_th: "วัสดุอุปกรณ์ก่อสร้าง",
    cat_new: 2699,
    cat_ename: "Hard ware & Construction Materials",
    cat_tname: "ฮาร์ดแวร์ อุปกรณ์ วัสดุก่อสร้าง",
    new_type: "0111",
  },
  {
    gid: 174,
    sect_code: 3000,
    sect_en: "Entertainment",
    sect_th: "บันเทิง",
    cat_new: 3002,
    cat_ename: "Message",
    cat_tname: "นวด",
    new_type: "0507",
  },
  {
    gid: 175,
    sect_code: 3000,
    sect_en: "Entertainment",
    sect_th: "บันเทิง",
    cat_new: 3003,
    cat_ename: "Spa",
    cat_tname: "สปา",
    new_type: "0506",
  },
  {
    gid: 176,
    sect_code: 3000,
    sect_en: "Entertainment",
    sect_th: "บันเทิง",
    cat_new: 3099,
    cat_ename: "Others Entertainment",
    cat_tname: "สถานบันเทิงอื่นๆ",
    new_type: "0511",
  },
  {
    gid: 181,
    sect_code: 3200,
    sect_en: "Art-Theater",
    sect_th: "โรงภาพยนตร์/ศิลป์",
    cat_new: 3201,
    cat_ename: "EGV",
    cat_tname: "อีจีวี",
    new_type: "0501",
  },
  {
    gid: 182,
    sect_code: 3200,
    sect_en: "Art-Theater",
    sect_th: "โรงภาพยนตร์/ศิลป์",
    cat_new: 3202,
    cat_ename: "Major Cineplex",
    cat_tname: "เมเจอร์ ซีนีเพล็กซ์",
    new_type: "0502",
  },
  {
    gid: 183,
    sect_code: 3200,
    sect_en: "Art-Theater",
    sect_th: "โรงภาพยนตร์/ศิลป์",
    cat_new: 3203,
    cat_ename: "Major Hollywood",
    cat_tname: "เมเจอร์ ฮอลลีวู้ด",
    new_type: "0503",
  },
  {
    gid: 184,
    sect_code: 3200,
    sect_en: "Art-Theater",
    sect_th: "โรงภาพยนตร์/ศิลป์",
    cat_new: 3204,
    cat_ename: "SF Cinema",
    cat_tname: "เอสเอฟ ซีนีม่า",
    new_type: "0504",
  },
  {
    gid: 185,
    sect_code: 3200,
    sect_en: "Art-Theater",
    sect_th: "โรงภาพยนตร์/ศิลป์",
    cat_new: 3205,
    cat_ename: "Theatre",
    cat_tname: "โรงละคร / มหรสพ",
    new_type: "0505",
  },
  {
    gid: 186,
    sect_code: 3200,
    sect_en: "Art-Theater",
    sect_th: "โรงภาพยนตร์/ศิลป์",
    cat_new: 3206,
    cat_ename: "Museums/Art Gallery",
    cat_tname: "พิพิธภัณฑ์ / ห้องแสดงศิลป",
    new_type: "0604",
  },
  {
    gid: 211,
    sect_code: 4300,
    sect_en: "Accommodation",
    sect_th: "โรงแรม-ที่พัก",
    cat_new: 4302,
    cat_ename: "Resort",
    cat_tname: "รีสอร์ท",
    new_type: "0403",
  },
  {
    gid: 212,
    sect_code: 4300,
    sect_en: "Accommodation",
    sect_th: "โรงแรม-ที่พัก",
    cat_new: 4303,
    cat_ename: "Bungalow",
    cat_tname: "บังกะโล",
    new_type: "0401",
  },
  {
    gid: 213,
    sect_code: 4300,
    sect_en: "Accommodation",
    sect_th: "โรงแรม-ที่พัก",
    cat_new: 4304,
    cat_ename: "Guest House",
    cat_tname: "เกสท์เฮ้าส์",
    new_type: "0402",
  },
  {
    gid: 223,
    sect_code: 4500,
    sect_en: "Fuel station",
    sect_th: "สถานีบริการเชื้อเพลิง",
    cat_new: 4501,
    cat_ename: "Ptt",
    cat_tname: "ปั๊มปตท.",
    new_type: "0708",
  },
  {
    gid: 224,
    sect_code: 4500,
    sect_en: "Fuel station",
    sect_th: "สถานีบริการเชื้อเพลิง",
    cat_new: 4502,
    cat_ename: "Shell",
    cat_tname: "ปั๊มเชลล์",
    new_type: "0710",
  },
  {
    gid: 225,
    sect_code: 4500,
    sect_en: "Fuel station",
    sect_th: "สถานีบริการเชื้อเพลิง",
    cat_new: 4503,
    cat_ename: "Esso",
    cat_tname: "ปั๊มเอสโซ่",
    new_type: "0703",
  },
  {
    gid: 226,
    sect_code: 4500,
    sect_en: "Fuel station",
    sect_th: "สถานีบริการเชื้อเพลิง",
    cat_new: 4504,
    cat_ename: "Caltex",
    cat_tname: "ปั๊มคาลเท็กซ์",
    new_type: "0702",
  },
  {
    gid: 227,
    sect_code: 4500,
    sect_en: "Fuel station",
    sect_th: "สถานีบริการเชื้อเพลิง",
    cat_new: 4505,
    cat_ename: "Bangchak",
    cat_tname: "ปั๊มบางจาก",
    new_type: "0701",
  },
  {
    gid: 228,
    sect_code: 4500,
    sect_en: "Fuel station",
    sect_th: "สถานีบริการเชื้อเพลิง",
    cat_new: 4506,
    cat_ename: "Pt",
    cat_tname: "ปั๊มพีที",
    new_type: "0707",
  },
  {
    gid: 229,
    sect_code: 4500,
    sect_en: "Fuel station",
    sect_th: "สถานีบริการเชื้อเพลิง",
    cat_new: 4507,
    cat_ename: "Susco",
    cat_tname: "ปั๊มซัสโก้",
    new_type: "0709",
  },
  {
    gid: 230,
    sect_code: 4500,
    sect_en: "Fuel station",
    sect_th: "สถานีบริการเชื้อเพลิง",
    cat_new: 4508,
    cat_ename: "LPG",
    cat_tname: "แอลพีจี",
    new_type: "0704",
  },
  {
    gid: 231,
    sect_code: 4500,
    sect_en: "Fuel station",
    sect_th: "สถานีบริการเชื้อเพลิง",
    cat_new: 4509,
    cat_ename: "NGV",
    cat_tname: "เอ็นจีวี",
    new_type: "0705",
  },
  {
    gid: 232,
    sect_code: 4500,
    sect_en: "Fuel station",
    sect_th: "สถานีบริการเชื้อเพลิง",
    cat_new: 4510,
    cat_ename: "Liquid Gas",
    cat_tname: "แก๊สหุงต้ม",
    new_type: "0712",
  },
  {
    gid: 233,
    sect_code: 4500,
    sect_en: "Fuel station",
    sect_th: "สถานีบริการเชื้อเพลิง",
    cat_new: 4599,
    cat_ename: "Other Gas Station",
    cat_tname: "สถานีบริการน้ำมันอื่นๆ",
    new_type: "0712",
  },
  {
    gid: 234,
    sect_code: 4600,
    sect_en: "Department Store",
    sect_th: "ห้างสรรพสินค้า",
    cat_new: 4601,
    cat_ename: "Central",
    cat_tname: "เซ็นทรัล",
    new_type: "0101",
  },
  {
    gid: 235,
    sect_code: 4600,
    sect_en: "Department Store",
    sect_th: "ห้างสรรพสินค้า",
    cat_new: 4602,
    cat_ename: "Big C",
    cat_tname: "บิ๊กซี",
    new_type: "0105",
  },
  {
    gid: 236,
    sect_code: 4600,
    sect_en: "Department Store",
    sect_th: "ห้างสรรพสินค้า",
    cat_new: 4603,
    cat_ename: "TESCO Lotus",
    cat_tname: "เทสโก้ โลตัส",
    new_type: "0108",
  },
  {
    gid: 237,
    sect_code: 4600,
    sect_en: "Department Store",
    sect_th: "ห้างสรรพสินค้า",
    cat_new: 4604,
    cat_ename: "Robinson",
    cat_tname: "โรบินสัน",
    new_type: "0102",
  },
  {
    gid: 238,
    sect_code: 4600,
    sect_en: "Department Store",
    sect_th: "ห้างสรรพสินค้า",
    cat_new: 4605,
    cat_ename: "The Mall",
    cat_tname: "เดอะ มอลล์",
    new_type: "0104",
  },
  {
    gid: 239,
    sect_code: 4600,
    sect_en: "Department Store",
    sect_th: "ห้างสรรพสินค้า",
    cat_new: 4606,
    cat_ename: "Foodland",
    cat_tname: "ฟู้ดแลนด์",
    new_type: "0106",
  },
  {
    gid: 240,
    sect_code: 4600,
    sect_en: "Department Store",
    sect_th: "ห้างสรรพสินค้า",
    cat_new: 4607,
    cat_ename: "Makro",
    cat_tname: "แมคโคร",
    new_type: "0107",
  },
  {
    gid: 241,
    sect_code: 4600,
    sect_en: "Department Store",
    sect_th: "ห้างสรรพสินค้า",
    cat_new: 4608,
    cat_ename: "Tops",
    cat_tname: "ท็อปส์",
    new_type: "0109",
  },
  {
    gid: 242,
    sect_code: 4600,
    sect_en: "Department Store",
    sect_th: "ห้างสรรพสินค้า",
    cat_new: 4609,
    cat_ename: "Jusco",
    cat_tname: "จัสโก้",
    new_type: "0110",
  },
  {
    gid: 243,
    sect_code: 4600,
    sect_en: "Department Store",
    sect_th: "ห้างสรรพสินค้า",
    cat_new: 4610,
    cat_ename: "Community Mall",
    cat_tname: "คอมมูนิตี้มออล์",
    new_type: "0116",
  },
  {
    gid: 244,
    sect_code: 4600,
    sect_en: "Department Store",
    sect_th: "ห้างสรรพสินค้า",
    cat_new: 4699,
    cat_ename: "Other Department Store",
    cat_tname: "ห้างสรรพสินค้าอื่นๆ",
    new_type: "0116",
  },
  {
    gid: 254,
    sect_code: 5000,
    sect_en: "Governments",
    sect_th: "ราชการ",
    cat_new: 5001,
    cat_ename: "Government Office",
    cat_tname: "สถานที่ราชการ",
    new_type: "0911",
  },
  {
    gid: 255,
    sect_code: 5000,
    sect_en: "Governments",
    sect_th: "ราชการ",
    cat_new: 5002,
    cat_ename: "Military Facility",
    cat_tname: "หน่วยงานทางทหาร",
    new_type: "0910",
  },
  {
    gid: 256,
    sect_code: 5000,
    sect_en: "Governments",
    sect_th: "ราชการ",
    cat_new: 5003,
    cat_ename: "City Hall",
    cat_tname: "ศาลากลาง",
    new_type: "0901",
  },
  {
    gid: 257,
    sect_code: 5000,
    sect_en: "Governments",
    sect_th: "ราชการ",
    cat_new: 5004,
    cat_ename: "District Office",
    cat_tname: "ที่ว่าการอำเภอ",
    new_type: "0902",
  },
  {
    gid: 258,
    sect_code: 5000,
    sect_en: "Governments",
    sect_th: "ราชการ",
    cat_new: 5005,
    cat_ename: "Municipal Office",
    cat_tname: "สำนักงานเทศบาล",
    new_type: "0903",
  },
  {
    gid: 259,
    sect_code: 5000,
    sect_en: "Governments",
    sect_th: "ราชการ",
    cat_new: 5006,
    cat_ename: "Sub District Office",
    cat_tname: "ที่ทำการองค์การบริหารส่วนตำบล",
    new_type: "0904",
  },
  {
    gid: 260,
    sect_code: 5000,
    sect_en: "Governments",
    sect_th: "ราชการ",
    cat_new: 5007,
    cat_ename: "Court House",
    cat_tname: "ศาล",
    new_type: "0907",
  },
  {
    gid: 263,
    sect_code: 5000,
    sect_en: "Governments",
    sect_th: "ราชการ",
    cat_new: 5010,
    cat_ename: "International Organization",
    cat_tname: "องค์การระหว่างประเทศ",
    new_type: "0908",
  },
  {
    gid: 328,
    sect_code: 8200,
    sect_en: "Attractions",
    sect_th: "สถานที่ท่องเที่ยว",
    cat_new: 8202,
    cat_ename: "Park",
    cat_tname: "สวนสาธารณะ",
    new_type: "0609",
  },
  {
    gid: 329,
    sect_code: 8200,
    sect_en: "Attractions",
    sect_th: "สถานที่ท่องเที่ยว",
    cat_new: 8203,
    cat_ename: "Zoo",
    cat_tname: "สวนสัตว์",
    new_type: "0608",
  },
  {
    gid: 330,
    sect_code: 8200,
    sect_en: "Attractions",
    sect_th: "สถานที่ท่องเที่ยว",
    cat_new: 8204,
    cat_ename: "Amusement park",
    cat_tname: "สวนสนุก",
    new_type: "0601",
  },
  {
    gid: 331,
    sect_code: 8200,
    sect_en: "Attractions",
    sect_th: "สถานที่ท่องเที่ยว",
    cat_new: 8205,
    cat_ename: "Waterfall",
    cat_tname: "น้ำตก",
    new_type: "0605",
  },
  {
    gid: 332,
    sect_code: 8200,
    sect_en: "Attractions",
    sect_th: "สถานที่ท่องเที่ยว",
    cat_new: 8206,
    cat_ename: "Mountain",
    cat_tname: "ภูเขา",
    new_type: "0610",
  },
  {
    gid: 333,
    sect_code: 8200,
    sect_en: "Attractions",
    sect_th: "สถานที่ท่องเที่ยว",
    cat_new: 8207,
    cat_ename: "Island",
    cat_tname: "เกาะ",
    new_type: "0611",
  },
  {
    gid: 336,
    sect_code: 8200,
    sect_en: "Attractions",
    sect_th: "สถานที่ท่องเที่ยว",
    cat_new: 8210,
    cat_ename: "Tourist attractions",
    cat_tname: "สถานที่ท่องเที่ยว",
    new_type: "0602",
  },
  {
    gid: 337,
    sect_code: 8200,
    sect_en: "Attractions",
    sect_th: "สถานที่ท่องเที่ยว",
    cat_new: 8211,
    cat_ename: "Tourist Information Center",
    cat_tname: "ศูนย์บริการนักท่องเที่ยว",
    new_type: "0606",
  },
  {
    gid: 338,
    sect_code: 9000,
    sect_en: "Sports",
    sect_th: "กีฬา-นันทนาการ",
    cat_new: 9001,
    cat_ename: "Fitness & Health",
    cat_tname: "ฟิตเนส แอนด์ เฮลท์",
    new_type: "0803",
  },
  {
    gid: 339,
    sect_code: 9000,
    sect_en: "Sports",
    sect_th: "กีฬา-นันทนาการ",
    cat_new: 9002,
    cat_ename: "Snooker Club",
    cat_tname: "สนุกเกอร์คลับ",
    new_type: "0508",
  },
  {
    gid: 340,
    sect_code: 9000,
    sect_en: "Sports",
    sect_th: "กีฬา-นันทนาการ",
    cat_new: 9003,
    cat_ename: "Football Outdoor",
    cat_tname: "สนามฟุตบอลกลางแจ้ง",
    new_type: "0806",
  },
  {
    gid: 341,
    sect_code: 9000,
    sect_en: "Sports",
    sect_th: "กีฬา-นันทนาการ",
    cat_new: 9004,
    cat_ename: "Football Indoor",
    cat_tname: "สนามฟุตบอลในร่ม",
    new_type: "0806",
  },
  {
    gid: 342,
    sect_code: 9000,
    sect_en: "Sports",
    sect_th: "กีฬา-นันทนาการ",
    cat_new: 9005,
    cat_ename: "Golf Course",
    cat_tname: "สนามกอล์ฟ",
    new_type: "0804",
  },
  {
    gid: 343,
    sect_code: 9000,
    sect_en: "Sports",
    sect_th: "กีฬา-นันทนาการ",
    cat_new: 9006,
    cat_ename: "Golf Driving Range",
    cat_tname: "สนามฝึกซ้อมกอล์ฟ",
    new_type: "0804",
  },
  {
    gid: 344,
    sect_code: 9000,
    sect_en: "Sports",
    sect_th: "กีฬา-นันทนาการ",
    cat_new: 9007,
    cat_ename: "Race Car Track",
    cat_tname: "สนามแข่งรถ",
    new_type: "0805",
  },
  {
    gid: 346,
    sect_code: 9000,
    sect_en: "Sports",
    sect_th: "กีฬา-นันทนาการ",
    cat_new: 9009,
    cat_ename: "Boxing Stadium",
    cat_tname: "สนามมวย",
    new_type: "0802",
  },
  {
    gid: 348,
    sect_code: 9000,
    sect_en: "Sports",
    sect_th: "กีฬา-นันทนาการ",
    cat_new: 9011,
    cat_ename: "Shooting Gun",
    cat_tname: "สนามยิงปืน",
    new_type: "0809",
  },
  {
    gid: 349,
    sect_code: 9000,
    sect_en: "Sports",
    sect_th: "กีฬา-นันทนาการ",
    cat_new: 9012,
    cat_ename: "Tennis Cout",
    cat_tname: "สนามเทนนิส",
    new_type: "0809",
  },
  {
    gid: 350,
    sect_code: 9000,
    sect_en: "Sports",
    sect_th: "กีฬา-นันทนาการ",
    cat_new: 9013,
    cat_ename: "Badminton Court",
    cat_tname: "สนามแบดมินตัน",
    new_type: "0809",
  },
  {
    gid: 351,
    sect_code: 9000,
    sect_en: "Sports",
    sect_th: "กีฬา-นันทนาการ",
    cat_new: 9014,
    cat_ename: "Swimming Pool",
    cat_tname: "สระว่ายน้ำ",
    new_type: "0809",
  },
  {
    gid: 354,
    sect_code: 9000,
    sect_en: "Sports",
    sect_th: "กีฬา-นันทนาการ",
    cat_new: 9099,
    cat_ename: "Other sports",
    cat_tname: "กีฬาอื่นๆ",
    new_type: "0809",
  },
  {
    gid: 352,
    sect_code: 9000,
    sect_en: "Sports",
    sect_th: "กีฬา-นันทนาการ",
    cat_new: 9015,
    cat_ename: "Stadium",
    cat_tname: "สนามแข่งขันกีฬากลางแจ้งขนาดใหญ่",
    new_type: "0807",
  },
  {
    gid: 356,
    sect_code: 1100,
    sect_en: "Dwelling",
    sect_th: "ที่อยู่อาศัย",
    cat_new: 1102,
    cat_ename: "Flat",
    cat_tname: "แฟลต",
    new_type: 1502,
  },
  {
    gid: 215,
    sect_code: 4400,
    sect_en: "Company/Factory",
    sect_th: "สถานประกอบการ",
    cat_new: 4401,
    cat_ename: "Business company",
    cat_tname: "บริษัท / ห้างหุ้นส่วนจำกัด",
    new_type: 1002,
  },
  {
    gid: 216,
    sect_code: 4400,
    sect_en: "Company/Factory",
    sect_th: "สถานประกอบการ",
    cat_new: 4402,
    cat_ename: "Law Office",
    cat_tname: "สำนักทนายความ / กฎหมาย",
    new_type: 1002,
  },
  {
    gid: 155,
    sect_code: 2800,
    sect_en: "Car Services",
    sect_th: "บริการด้านรถยนต์",
    cat_new: 2808,
    cat_ename: "Car Spare parts & Accessory",
    cat_tname: "อะไหล่ / อุปกรณ์ตกแต่งรถยนต์",
    new_type: 2105,
  },
  {
    gid: 314,
    sect_code: 7000,
    sect_en: "Educations",
    sect_th: "สถาบันการศึกษา",
    cat_new: 7002,
    cat_ename: "Kindergarten and Nursery",
    cat_tname: "โรงเรียนอนุบาล / สถานรับเลี้ยงเด็ก",
    new_type: 2205,
  },
  {
    gid: 335,
    sect_code: 8200,
    sect_en: "Attractions",
    sect_th: "สถานที่ท่องเที่ยว",
    cat_new: 8209,
    cat_ename: "Dam/Reservoir",
    cat_tname: "เขื่อน / อ่างเก็บ้ำ",
    new_type: "0603",
  },
  {
    gid: 334,
    sect_code: 8200,
    sect_en: "Attractions",
    sect_th: "สถานที่ท่องเที่ยว",
    cat_new: 8208,
    cat_ename: "River / Canal",
    cat_tname: "แม่น้ำ / คลอง",
    new_type: "0612",
  },
  {
    gid: 271,
    sect_code: 5100,
    sect_en: "Pubic Uitilities",
    sect_th: "หน่วยงานบริการสาธารณะ",
    cat_new: 5108,
    cat_ename: "Pawn Shop",
    cat_tname: "สถานธนานุบาล / โรงรับจำนำ",
    new_type: 2513,
  },
  {
    gid: 272,
    sect_code: 5100,
    sect_en: "Pubic Uitilities",
    sect_th: "หน่วยงานบริการสาธารณะ",
    cat_new: 5109,
    cat_ename: "Covention & Exhibition Center",
    cat_tname: "ศูนย์ประชุม / แสดงนิทรรศการ",
    new_type: 2701,
  },
  {
    gid: 89,
    sect_code: 2500,
    sect_en: "Shops and Stores",
    sect_th: "ร้านขายสินค้า",
    cat_new: 2510,
    cat_ename: "Computer / IT",
    cat_tname: "คอมพิวเตอร์ / ไอที",
    new_type: 2504,
  },
  {
    gid: 280,
    sect_code: 5200,
    sect_en: "Transportain",
    sect_th: "การคมนาคมขนส่ง",
    cat_new: 5202,
    cat_ename: "Pier / Ferry",
    cat_tname: "ท่าเรือ",
    new_type: 1308,
  },
  {
    gid: 84,
    sect_code: 2500,
    sect_en: "Shops and Stores",
    sect_th: "ร้านขายสินค้า",
    cat_new: 2505,
    cat_ename: "Gold",
    cat_tname: "ทองคำ",
    new_type: 2501,
  },
  {
    gid: 251,
    sect_code: 4800,
    sect_en: "Coopertives-Association",
    sect_th: "สหกรณ์-สมาคม",
    cat_new: 4803,
    cat_ename: "Club / Association",
    cat_tname: "สโมสร / สมาคม",
    new_type: 2802,
  },
  {
    gid: 173,
    sect_code: 3000,
    sect_en: "Entertainment",
    sect_th: "บันเทิง",
    cat_new: 3001,
    cat_ename: "Pub  / Karaoke / Lounge / Night Club",
    cat_tname: "ผับ / คาราโอเกะ / เลานจ์ / ไนท์คลับ",
    new_type: "0510",
  },
  {
    gid: 214,
    sect_code: 4300,
    sect_en: "Accommodation",
    sect_th: "โรงแรม-ที่พัก",
    cat_new: 4399,
    cat_ename: "Other Accommodation",
    cat_tname: "ที่พักแรม อื่นๆ",
    new_type: "0405",
  },
  {
    gid: 44,
    sect_code: 2100,
    sect_en: "Restaurant",
    sect_th: "ร้านอาหาร",
    cat_new: 2116,
    cat_ename: "Bar-B-Q / Seafood",
    cat_tname: "ปิ้งย่าง / ซีฟู้ด",
    new_type: "0312",
  },
  {
    gid: 41,
    sect_code: 2100,
    sect_en: "Restaurant",
    sect_th: "ร้านอาหาร",
    cat_new: 2113,
    cat_ename: "Thai-Isan-Som tum",
    cat_tname: "อีสาน / ส้มตำ",
    new_type: "0312",
  },
  {
    gid: 42,
    sect_code: 2100,
    sect_en: "Restaurant",
    sect_th: "ร้านอาหาร",
    cat_new: 2114,
    cat_ename: "Street-Kiosk Food",
    cat_tname: "จานเดียว / ตามสั่ง / แผงลอย",
    new_type: "0312",
  },
  {
    gid: 323,
    sect_code: 8000,
    sect_en: "Religions",
    sect_th: "สถาบันทางศาสนา",
    cat_new: 8003,
    cat_ename: "Church",
    cat_tname: "คริสต์",
    new_type: 2303,
  },
  {
    gid: 347,
    sect_code: 9000,
    sect_en: "Sports",
    sect_th: "กีฬา-นันทนาการ",
    cat_new: 9010,
    cat_ename: "Boxing Training Camp",
    cat_tname: "ค่ายมวย",
    new_type: "0802",
  },
  {
    gid: 327,
    sect_code: 8200,
    sect_en: "Attractions",
    sect_th: "สถานที่ท่องเที่ยว",
    cat_new: 8201,
    cat_ename: "National Park",
    cat_tname: "อุทยานแห่งชาติ / วนอุทยาน",
    new_type: "0607",
  },
  {
    gid: 204,
    sect_code: 4000,
    sect_en: "Bank",
    sect_th: "ธนาคาร",
    cat_new: 4018,
    cat_ename: "Tisco Bank",
    cat_tname: "ทิสโก้",
    new_type: 1618,
  },
  {
    gid: 262,
    sect_code: 5000,
    sect_en: "Governments",
    sect_th: "ราชการ",
    cat_new: 5009,
    cat_ename: "Embassy / Consulate",
    cat_tname: "สถานทูต / สถานกงสุล",
    new_type: "0909",
  },
  {
    gid: 261,
    sect_code: 5000,
    sect_en: "Governments",
    sect_th: "ราชการ",
    cat_new: 5008,
    cat_ename: "Customs Department/Immigration Bureau",
    cat_tname: "ศุลกากร / ตรวจคนเข้าเมือง",
    new_type: "0905",
  },
  {
    gid: 267,
    sect_code: 5100,
    sect_en: "Pubic Uitilities",
    sect_th: "หน่วยงานบริการสาธารณะ",
    cat_new: 5104,
    cat_ename: "Telephone/Telecom",
    cat_tname: "โทรศัพท์ / โทรคมนาคม การสื่อสาร",
    new_type: 2603,
  },
  {
    gid: 92,
    sect_code: 2500,
    sect_en: "Shops and Stores",
    sect_th: "ร้านขายสินค้า",
    cat_new: 2513,
    cat_ename: "Home Decoration & Household items & Furniture",
    cat_tname: "เครื่องใช้ตกแต่งภายในบ้าน / เฟอร์นิเจอร์",
    new_type: 2514,
  },
  {
    gid: 105,
    sect_code: 2500,
    sect_en: "Shops and Stores",
    sect_th: "ร้านขายสินค้า",
    cat_new: 2526,
    cat_ename: "Souvenir / OTOP",
    cat_tname: "สินค้าที่ระลึก / โอทอป",
    new_type: 2510,
  },
  {
    gid: 270,
    sect_code: 5100,
    sect_en: "Pubic Uitilities",
    sect_th: "หน่วยงานบริการสาธารณะ",
    cat_new: 5107,
    cat_ename: "Radio / Television Station",
    cat_tname: "สถานีวิทยุ / สถานีโทรทัศน์",
    new_type: 2801,
  },
  {
    gid: 64,
    sect_code: 2300,
    sect_en: "Bakery",
    sect_th: "เบเกอรี่",
    cat_new: 2399,
    cat_ename: "Cake / Bakery / Desert",
    cat_tname: "เค้ก / เบเกอรี่ / ขนมหวาน",
    new_type: "0222",
  },
  {
    gid: 172,
    sect_code: 2950,
    sect_en: "Motorbike Service",
    sect_th: "บริการด้านรถจักรยานยนต์",
    cat_new: 2952,
    cat_ename: "Motobike Spare part & Accessory",
    cat_tname: "อะไหล่ / อุปกรณ์ตกแต่งรถจักรยานยนต์",
    new_type: 2110,
  },
  {
    gid: 357,
    sect_code: 1200,
    sect_en: "Dwelling",
    sect_th: "ที่อยู่อาศัย",
    cat_new: 1203,
    cat_ename: "Lodging  House / Rented room",
    cat_tname: "บ้านพัก / ห้องพัก   ให้เช่า",
    new_type: 1502,
  },
  {
    gid: 355,
    sect_code: 4400,
    sect_en: "Company/Factory",
    sect_th: "สถานประกอบการ",
    cat_new: 4408,
    cat_ename: "Logistics / Delivery Service",
    cat_tname: "ธุรกิจโลจิสติกส์ / ขนส่งสินค้า / ไปรษณีย์เอกชน",
    new_type: 1005,
  },
  {
    gid: 353,
    sect_code: 9000,
    sect_en: "Sports",
    sect_th: "กีฬา-นันทนาการ",
    cat_new: 9016,
    cat_ename: "Sports Complex & Gymnasium",
    cat_tname: "สปอร์ตคอมเพล็กซ์ / สนามกีฬาในร่ม",
    new_type: "0808",
  },
  {
    gid: 359,
    sect_code: 8200,
    sect_en: "Attractions",
    sect_th: "สถานที่ท่องเที่ยว",
    cat_new: 8213,
    cat_ename: "Archaeological site / Historic Building or Place",
    cat_tname: "โบราณสถาน / สถานที่ทางประวัติศาสตร์",
    new_type: "0602",
  },
  {
    gid: 358,
    sect_code: 8200,
    sect_en: "Attractions",
    sect_th: "สถานที่ท่องเที่ยว",
    cat_new: 8212,
    cat_ename: "Monumemt/ Memorial / Statue",
    cat_tname: "อนุสาวรีย์/รูปปั้น",
    new_type: "0614",
  },
  {
    gid: 63,
    sect_code: 2300,
    sect_en: "Bakery",
    sect_th: "เบเกอรี่",
    cat_new: 2306,
    cat_ename: "S&P",
    cat_tname: "เอสแอนด์พี",
    new_type: "0218",
  },
  {
    gid: 14,
    sect_code: 1400,
    sect_en: "Housing Estate",
    sect_th: "หมู่บ้านจัดสรร",
    cat_new: 1403,
    cat_ename: "Sansiri",
    cat_tname: "แสนสิริ",
    new_type: 1507,
  },
  {
    gid: 15,
    sect_code: 1400,
    sect_en: "Housing Estate",
    sect_th: "หมู่บ้านจัดสรร",
    cat_new: 1404,
    cat_ename: "Pruksa",
    cat_tname: "พฤกษา",
    new_type: 1508,
  },
  {
    gid: 16,
    sect_code: 1400,
    sect_en: "Housing Estate",
    sect_th: "หมู่บ้านจัดสรร",
    cat_new: 1405,
    cat_ename: "Rajapruek",
    cat_tname: "ราชพฤกษ์",
    new_type: 1509,
  },
  {
    gid: 17,
    sect_code: 1500,
    sect_en: "Village/Community",
    sect_th: "หมู่บ้าน / ชุมชน",
    cat_new: 1501,
    cat_ename: "Village/Community",
    cat_tname: "หมู่บ้าน / ชุมชน",
    new_type: 2817,
  },
];

export default Categories;
