import React, { useState, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { RiLogoutBoxLine } from "react-icons/ri";
import { FaRoute, FaMapMarkerAlt, FaCar } from "react-icons/fa";
import { MdAccountCircle, MdSchedule, MdMenuBook } from "react-icons/md";
import SlideIn from "./SlideIn";
// import Settings from "./Settings";
import Account from "./Account";
import { useNavigate } from "react-router-dom";

const Sidebar = ({ navigationData }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const page = pathname.split("/")[1];

  const [currentRoute, setCurrentRoute] = useState(page || "maps");
  // const [showSettings, setShowSettings] = useState(false);
  const [showAccount, setShowAccount] = useState(false);

  // const openSettings = () => {
  //   // console.log("openSettings");
  //   setShowSettings(true);
  // };

  const openAccount = () => {
    // console.log("openAccount");
    setShowAccount(true);
  };

  const handleLogout = () => {
    localStorage.removeItem("jwt");
    localStorage.removeItem("userid");
    localStorage.removeItem("username");
    localStorage.removeItem("company_id");
    localStorage.removeItem("company_name");
    localStorage.removeItem("company_abbr");
    localStorage.removeItem("company_address");
    localStorage.removeItem("company_email");
    localStorage.removeItem("company_tel");
    localStorage.removeItem("company_website");

    sessionStorage.removeItem("jwt");
    sessionStorage.removeItem("userid");
    sessionStorage.removeItem("username");
    sessionStorage.removeItem("company_id");
    sessionStorage.removeItem("company_name");
    sessionStorage.removeItem("company_abbr");
    sessionStorage.removeItem("company_address");
    sessionStorage.removeItem("company_email");
    sessionStorage.removeItem("company_tel");
    sessionStorage.removeItem("company_website");
    return navigate("/signin");
  };

  const renderIcon = useCallback((element, currentRoute) => {
    switch (element) {
      case "maps":
        return (
          <div className="">
            <FaMapMarkerAlt
              className={`${
                element === currentRoute && "text-gray-500"
              } w-6 h-6`}
            />
          </div>
        );
      // case "optimize":
      //   return (
      //     <div className="">
      //       <MdOutlineEngineering
      //         className={`${
      //           element === currentRoute && "text-gray-500"
      //         } w-6 h-6`}
      //       />
      //     </div>
      //   );
      case "map-cluster":
        return (
          <div className="">
            <FaRoute
              className={`${
                element === currentRoute && "text-gray-500"
              } w-6 h-6`}
            />
          </div>
        );
      case "drop-master":
        return (
          <div className="">
            <MdSchedule
              className={`${
                element === currentRoute && "text-gray-500"
              } w-6 h-6`}
            />
          </div>
        );
      case "vehicle":
        return (
          <div className="">
            <FaCar
              className={`${
                element === currentRoute && "text-gray-500"
              } w-6 h-6`}
            />
          </div>
        );
      case "manual":
        return (
          <div className="">
            <MdMenuBook
              className={`${
                element === currentRoute && "text-gray-500"
              } w-6 h-6`}
            />
          </div>
        );
    }
  }, []);

  React.useEffect(() => {
    setShowAccount(false);
    // setShowSettings(false);
  }, [currentRoute]);

  return (
    <nav className="fixed left-0 top-0 bottom-0 shadow-lg z-50 w-14 bg-sidebar flex flex-col h-screen justify-between items-center py-6">
      <span className="text-3xl text-green-400 p-1">
        <img src="./markers/powermap.png" alt="logo" className="w-10 h-10" />
      </span>
      <ul className="flex flex-col items-center w-full">
        {navigationData.map((element, index) => (
          <Link key={index} to={`/${element}`}>
            <li
              className={`text-white hover:text-gray-800 text-xl p-2 m-2 cursor-pointer ${
                currentRoute === element &&
                "p-2 rounded-md bg-white text-black shadow-lg hover:text-gray-100"
              }`}
              onClick={() => {
                setCurrentRoute(element, currentRoute);
                // console.log("test");
              }}
            >
              {renderIcon(element)}
              <span
                className={`absolute w-auto min-w-max left-16 text-base font-medium hidden ${
                  currentRoute === element && "hover:inline-flex"
                }`}
              >
                {element}
              </span>
            </li>
          </Link>
        ))}
      </ul>
      <div className="flex flex-col justify-between items-center">
        {/* <div className="w-10 h-10 bg-gray-100 hover:bg-gray-200 cursor-pointer rounded-xl flex items-center justify-center text-gray-800 text-lg relative mb-4">
          <span className="h-5 w-5 flex justify-center items-center text-white absolute -top-1 -right-1 bg-red-500 text-xs rounded-full">
            24
          </span>
          <FaRegBell />
        </div> */}
        <span className="text-3xl text-gray-200 hover:text-gray-800 cursor-pointer mb-3">
          <button onClick={openAccount} className="">
            <MdAccountCircle />
          </button>
        </span>
        <span className="text-3xl text-gray-200 hover:text-gray-800 cursor-pointer">
          <button onClick={handleLogout} className="">
            <RiLogoutBoxLine />
          </button>
        </span>
        {showAccount && (
          <SlideIn
            open={showAccount}
            setOpen={setShowAccount}
            width="w-4/5 sm:w-80"
            layout="left"
          >
            <div className="flex flex-col h-screen bg-sky-800 bg-opacity-95 z-50">
              <Account />
            </div>
          </SlideIn>
        )}
      </div>
    </nav>
  );
};

export default Sidebar;
