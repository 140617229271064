import { Fragment, useState, useContext, useEffect } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { searchDropPoint } from "../utils/apis";
import _ from "lodash";
import { Context } from "../store";

export default function SearchDestinationPlace({ destination_place }) {
  const [selected, setSelected] = useState({
    id: 0,
    name: destination_place,
  });
  const [query, setQuery] = useState("");
  const [dropPoints, setDropPoints] = useState([]);
  const [state, setState] = useContext(Context);

  const getDropPoint = async (e) => {
    // console.log(e);
    let searchResult = await searchDropPoint(e);
    // console.log(searchResult);
    let dropPointsData = _.map(searchResult.data, (item) => ({
      id: item.id,
      ...item.attributes,
      name: item.attributes.name_t,
    }));
    setDropPoints(dropPointsData);
  };
  // console.log(selected);
  useEffect(() => {
    setState({ ...state, selecteddestinationplace: selected });
    // console.log("test");
  }, [selected]);
  return (
    <div className="w-auto">
      <Combobox value={selected} onChange={setSelected}>
        <div className="mt-1">
          <div className="w-full cursor-default overflow-hidden rounded-lg bg-white dark:bg-gray-600 text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
            <Combobox.Input
              className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 focus:ring-0 text-gray-900 bg-white dark:text-white dark:bg-gray-600"
              displayValue={(item) => item.name}
              //   onChange={(event) => setQuery(event.target.value)}
              onChange={(event) => getDropPoint(event.target.value)}
            />
            {/* <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <SelectorIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button> */}
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            <Combobox.Options className="absolute mt-1 max-h-60 w-4/5 overflow-auto rounded-md bg-white dark:bg-gray-600 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {dropPoints.length === 0 && query !== "" ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700 dark:text-white">
                  Not found.
                </div>
              ) : (
                dropPoints.map((item) => (
                  <Combobox.Option
                    key={item.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active
                          ? "bg-teal-600 text-white dark:text-blue-200"
                          : "text-gray-900 dark:text-white"
                      }`
                    }
                    value={item}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          [{item.branch_id}] {item.name}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? "text-white" : "text-teal-600"
                            }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
}
