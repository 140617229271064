import * as XLSX from "xlsx-js-style";

function ExportExcel(csvData) {
  let newMap = csvData.map((each) => {
    each["รหัสสาขา"] = each["branch_id"] || "";
    delete each["branch_id"];
    each["หมวดหมู่ภาษาไทย"] = each["cate_t"] || "";
    delete each["cate_t"];
    each["หมวดหมู่ภาษาอังกฤษ"] = each["cate_e"] || "";
    delete each["cate_e"];
    each["ชื่อภาษาไทย"] = each["name_t"] || "";
    delete each["name_t"];
    each["ชื่อภาษาอังกฤษ"] = each["name_e"] || "";
    delete each["name_e"];
    each["ที่อยู่ภาษาไทย"] = each["addr_t"] || "";
    delete each["addr_t"];
    each["ที่อยู่ภาษาอังกฤษ"] = each["addr_e"] || "";
    delete each["addr_e"];
    each["บ้านเลขที่"] = each["house_no"] || "";
    delete each["house_no"];
    each["หมู่"] = each["moo"] || "";
    delete each["moo"];
    each["ชื่อถนนภาษาไทย"] = each["road_tname"] || "";
    delete each["road_tname"];
    each["ชื่อถนนภาษาอังกฤษ"] = each["road_ename"] || "";
    delete each["road_ename"];
    each["ตำบล"] = each["tambon_t"] || "";
    delete each["tambon_t"];
    each["อำเภอ"] = each["amphoe_t"] || "";
    delete each["amphoe_t"];
    each["จังหวัด"] = each["province_t"] || "";
    delete each["province_t"];
    each["รหัสไปรษณีย์"] = each["postcode"] || "";
    delete each["postcode"];
    each["ผู้ติดต่อ"] = each["contact_name"] || "";
    delete each["contact_name"];
    each["เบอร์ติดต่อ"] = each["phone_no"] || "";
    delete each["phone_no"];
    each["เวลาเปิดทำการ"] = each["opening_hours"] || "";
    delete each["opening_hours"];
    each["ชื่อสาขาภาษาไทย"] = each["branch_tty"] || "";
    delete each["branch_tty"];
    each["ชื่อสาขาภาษาอังกฤษ"] = each["branch_ety"] || "";
    delete each["branch_ety"];
    each["Latitude"] = each["latitude"] || "";
    delete each["latitude"];
    each["Longitude"] = each["longitude"] || "";
    delete each["longitude"];
    each["หมายเหตุ"] = each["remark"] || "";
    delete each["remark"];
    return each;
  });

  let header = Object.keys(newMap[0]).map((el) => {
    let style = {
      fill: {
        fgColor: {
          rgb: "D3D3D3",
        },
      },
      font: {
        name: "Calibri",
        sz: 12,
        bold: true,
      },
      border: {
        top: { style: "medium", color: { rgb: "708090" } },
        right: { style: "medium", color: { rgb: "708090" } },
        bottom: { style: "medium", color: { rgb: "708090" } },
        left: { style: "medium", color: { rgb: "708090" } },
      },
    };
    return {
      v: el,
      t: "s",
      s: style,
    };
  });

  let data = newMap.map((el) => {
    return Object.values(el).map((ele) => ({
      v: ele,
      t: "s",
      s: {
        border: {
          top: { style: "thin", color: { rgb: "696969" } },
          right: { style: "thin", color: { rgb: "696969" } },
          bottom: { style: "thin", color: { rgb: "696969" } },
          left: { style: "thin", color: { rgb: "696969" } },
        },
      },
    }));
  });

  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet([header, ...data]);
  XLSX.utils.book_append_sheet(wb, ws, "Droppoint");

  XLSX.writeFile(wb, "Droppoint.xlsx");
}

export default ExportExcel;
