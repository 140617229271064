import React from "react";

export default function Manual() {
  const [openTab, setOpenTab] = React.useState(1);
  return (
    <div className="h-screen w-sidebar ml-14 flex-col">
      <div className="p-2 overflow-y-scroll overflow-x-hidden">
        <h1 className="mt-2 text-xl text-center text-indigo-700 font-bold">
          วิดีโอสาธิตการใช้งานโปรแกรม
        </h1>
        <ul
          className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
          role="tablist"
        >
          <li
            className={`bg-blue-500 -mb-px mr-2 last:mr-0 flex-auto text-center rounded-md`}
          >
            <a
              className={
                "text-base font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                (openTab === 1
                  ? "text-white bg-blue-600"
                  : "text-blue-600 bg-gray-50")
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(1);
              }}
              data-toggle="tab"
              href="#link1"
              role="tablist"
            >
              <i className="fas fa-space-shuttle text-base mr-1"></i>{" "}
              การจัดการสถานที่รับส่งสินค้า และรถขนส่ง
            </a>
          </li>
          <li
            className={`bg-blue-500 -mb-px mr-2 last:mr-0 flex-auto text-center rounded-md`}
          >
            <a
              className={
                "text-base font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                (openTab === 2
                  ? "text-white bg-blue-600"
                  : "text-blue-600 bg-gray-50")
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(2);
              }}
              data-toggle="tab"
              href="#link2"
              role="tablist"
            >
              <i className="fas fa-cog text-base mr-1"></i>
              การจัดการรอบขนส่ง
            </a>
          </li>
        </ul>
        <div className="relative flex flex-col min-w-0 break-wordsw-full mb-6">
          <div className="px-4 py-5 flex-auto">
            <div className="tab-content tab-space">
              <div
                className={openTab === 1 ? "block w-full h-full" : "hidden"}
                id="link1"
              >
                <div className="relative h-[auto] w-[auto] max-h-3xl">
                  <center>
                    <video width="1280" height="1024" controls>
                      <source
                        src="/manual/Planner Manual (Place and Vehicle).mp4"
                        type="video/mp4"
                      />
                    </video>
                  </center>
                </div>
              </div>
              <div
                className={openTab === 2 ? "block w-full h-full" : "hidden"}
                id="link2"
              >
                <div className="relative h-[auto] w-[auto] max-h-3xl">
                  <center>
                    <video width="1280" height="1024" controls>
                      <source
                        src="/manual/Planner Manual (Delivery).mp4"
                        type="video/mp4"
                      />
                    </video>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <div className="h-screen w-sidebar ml-14 flex-col">
      <div className="overflow-y-scroll overflow-x-hidden">
        <video width="1280" height="1024" controls>
          <source
            src="/manual/Planner Manual (Place and Vehicle).mp4"
            type="video/mp4"
          />
        </video>
        <video width="1280" height="1024" controls>
          <source
            src="/manual/Planner Manual (Delivery).mp4"
            type="video/mp4"
          />
        </video>
      </div>
    </div>
  );
}
