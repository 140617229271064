import _ from "lodash";
import { backendUrl } from "./backend";

let markerArray = [
  "add_marker.png",
  "destination_marker.png",
  "origin_marker.png",
  "selected_marker.png",
  "arrow.png",
];

let markerArrayCategory = [
  "marker-arabitia-cafe.png",
  "marker-cp-freshmart.png",
  "marker-customer.png",
  "marker-dc.png",
  "marker-jungle-cafe.png",
  "marker-kaidee-shop.png",
  "marker-refrig.png",
  "marker-spare-part.png",
  "marker-top-charoen.png",
];
let initMarkerLayer = {
  type: "geojson",
  data: {
    type: "FeatureCollection",
    features: [],
  },
};

export const login = async (identifier, password, isRememberMe) => {
  return await fetch(`${backendUrl}/api/auth/local`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      identifier,
      password,
    }),
  })
    .then(async (res) => {
      // Handle success.
      if (res.status !== 200) {
        let result = await res.json();
        return { status: false, message: result.error.message };
      } else {
        let result = await res.json();
        // console.log(result);
        await getcompany(result.user.id, isRememberMe);
        if (isRememberMe) {
          localStorage.setItem("jwt", result.jwt);
          localStorage.setItem("userid", result.user.id);
          localStorage.setItem("username", result.user.username);
          sessionStorage.setItem("jwt", result.jwt);
          sessionStorage.setItem("userid", result.user.id);
          sessionStorage.setItem("username", result.user.username);
        } else {
          sessionStorage.setItem("jwt", result.jwt);
          sessionStorage.setItem("userid", result.user.id);
          sessionStorage.setItem("username", result.user.username);
        }
        return { status: true, jwt: result.jwt };
      }
    })
    .catch((error) => {
      // Handle error.
      // console.log("An error occurred:", error.response);
    });
};

const getcompany = async (id, isRememberMe) => {
  return await fetch(
    `${backendUrl}/api/users/${id}/?populate[rp_company][populate]=*`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  )
    .then(async (res) => {
      // Handle success.
      if (res.status !== 200) {
        let result = await res.json();
        return { status: false, message: result.error.message };
      } else {
        let result = await res.json();
        // console.log(result);
        if (isRememberMe) {
          localStorage.setItem("company_id", result.rp_company.id);
          localStorage.setItem("company_name", result.rp_company.name);
          localStorage.setItem("company_abbr", result.rp_company.abbr);
          localStorage.setItem("company_address", result.rp_company.address);
          localStorage.setItem("company_email", result.rp_company.email);
          localStorage.setItem("company_tel", result.rp_company.tel);
          localStorage.setItem("company_website", result.rp_company.website);
          sessionStorage.setItem("company_id", result.rp_company.id);
          sessionStorage.setItem("company_name", result.rp_company.name);
          sessionStorage.setItem("company_abbr", result.rp_company.abbr);
          sessionStorage.setItem("company_address", result.rp_company.address);
          sessionStorage.setItem("company_email", result.rp_company.email);
          sessionStorage.setItem("company_tel", result.rp_company.tel);
          sessionStorage.setItem("company_website", result.rp_company.website);
        } else {
          sessionStorage.setItem("company_id", result.rp_company.id);
          sessionStorage.setItem("company_name", result.rp_company.name);
          sessionStorage.setItem("company_abbr", result.rp_company.abbr);
          sessionStorage.setItem("company_address", result.rp_company.address);
          sessionStorage.setItem("company_email", result.rp_company.email);
          sessionStorage.setItem("company_tel", result.rp_company.tel);
          sessionStorage.setItem("company_website", result.rp_company.website);
        }
        return { status: true };
      }
    })
    .catch((error) => {
      // Handle error.
      // console.log("An error occurred:", error.response);
    });
};

export const signup = async (userData) => {
  return await fetch(`${backendUrl}/api/users`, {
    body: JSON.stringify({ ...userData, role: 1, confirmed: true }),
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then(async (res) => {
      // console.log(res);
      // Handle success.
      if (res.status !== 200 && res.status !== 201) {
        // console.log("false");
        let result = await res.json();
        return { status: false, message: result.error.message };
      }
      // console.log("true");
      return { status: true };
    })
    .catch((error) => {
      // Handle error.
      // console.log("An error occurred:", error.response);
    });
};

export const forgotpassword = async (email) => {
  return await fetch(`${backendUrl}/api/utils/provider-type?email=${email}`, {
    method: "GET",
  })
    .then(async (res) => {
      // console.log(res);
      // Handle success.
      let result = await res.text();
      // console.log(result);
      if (result === "local") {
        return await fetch(`${backendUrl}/api/auth/forgot-password`, {
          body: JSON.stringify({ email }),
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then(async (res) => {
            // console.log(res);
            // Handle success.
            if (res.status !== 200) {
              let result = await res.json();
              return { status: false, message: result.error.message };
            }
            return { status: true };
          })
          .catch((error) => {
            // Handle error.
            // console.log("An error occurred:", error.response);
          });
      } else if (result === "0") {
        return { status: false, message: `Email not found` };
      } else {
        return {
          status: false,
          message: `This is ${result} account. Can't reset password.`,
        };
      }
    })
    .catch((error) => {
      // Handle error.
      // console.log("An error occurred:", error.response);
    });
};

export const resetpassword = async (password, code) => {
  return await fetch(`${backendUrl}/api/auth/reset-password`, {
    body: JSON.stringify({ password, passwordConfirmation: password, code }),
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then(async (res) => {
      // console.log(res);
      // Handle success.
      if (res.status !== 200) {
        let result = await res.json();
        return { status: false, message: result.error.message };
      }
      return { status: true };
    })
    .catch((error) => {
      // Handle error.
      // console.log("An error occurred:", error.response);
    });
};

export const addpoint = async (pointData) => {
  return await fetch(`${backendUrl}/api/rp-tasks`, {
    body: JSON.stringify({ data: pointData }),
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        localStorage.getItem("jwt") || sessionStorage.getItem("jwt")
      }`,
    },
  })
    .then(async (res) => {
      // console.log(res);
      // Handle success.
      if (res.status !== 200 && res.status !== 201) {
        // console.log("false");
        let result = await res.json();
        return { status: false, message: result.error.message };
      }
      // console.log("true");
      return { status: true };
    })
    .catch((error) => {
      // Handle error.
      // console.log("An error occurred:", error.response);
    });
};

export const updatepoint = async (pointid, pointData) => {
  return await fetch(`${backendUrl}/api/rp-tasks/${pointid}`, {
    body: JSON.stringify({ data: pointData }),
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        localStorage.getItem("jwt") || sessionStorage.getItem("jwt")
      }`,
    },
  })
    .then(async (res) => {
      // Handle success.
      if (res.status !== 200 && res.status !== 201) {
        // console.log("false");
        let result = await res.json();
        return { status: false, message: result.error.message };
      }
      // console.log("true");
      return { status: true };
    })
    .catch((error) => {
      // Handle error.
      // console.log("An error occurred:", error.response);
    });
};

export const deletepoint = async (pointid) => {
  return await fetch(`${backendUrl}/api/rp-tasks/${pointid}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        localStorage.getItem("jwt") || sessionStorage.getItem("jwt")
      }`,
    },
  })
    .then(async (res) => {
      // console.log(res);
      // Handle success.
      if (res.status !== 200 && res.status !== 201) {
        // console.log("false");
        let result = await res.json();
        return { status: false, message: result.error.message };
      }
      // console.log("true");
      return { status: true };
    })
    .catch((error) => {
      // Handle error.
      // console.log("An error occurred:", error.response);
    });
};
export const styleLoad = async (map) => {
  // console.log("style load map");
  _.map(markerArray, (each) => {
    map.loadImage("./pinpoint/" + each, function (error, image) {
      if (error) throw error;
      if (!map.hasImage(each.split(".")[0])) {
        map.addImage(each.split(".")[0], image);
      }
    });
  });
  _.map(markerArrayCategory, (each) => {
    map.loadImage("./cate-markers/" + each, function (error, image) {
      if (error) throw error;
      if (!map.hasImage(each.split(".")[0])) {
        // console.log("Add", each.split(".")[0]);
        map.addImage(each.split(".")[0], image);
      }
    });
  });
  let initMarkerLayer = {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [],
    },
  };
  if (!map.getSource("places")) {
    map.addSource("places", initMarkerLayer);
    map.addLayer({
      id: "places",
      type: "symbol",
      source: "places",
      layout: {
        "icon-image": ["get", "icon"],
        "text-field": ["get", "description"],
        "icon-size": 0.3,
        "icon-allow-overlap": true,
        "text-allow-overlap": true,
        // get the year from the source's "year" property
        "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
        "text-offset": [0, 1.25],
        "text-anchor": "top",
      },
      paint: {
        "text-color": "#006400",
        "text-halo-color": "#FFFF00",
        "text-halo-width": 2,
      },
    });
  }
};

export const loadMap = async (map, updateMap) => {
  map.on("load", () => {
    // console.log("load map");
    _.map(markerArray, (each) => {
      map.loadImage("./pinpoint/" + each, function (error, image) {
        if (error) throw error;
        if (!map.hasImage(each.split(".")[0])) {
          map.addImage(each.split(".")[0], image);
        }
      });
    });
    _.map(markerArrayCategory, (each) => {
      map.loadImage("./cate-markers/" + each, function (error, image) {
        if (error) throw error;
        if (!map.hasImage(each.split(".")[0])) {
          map.addImage(each.split(".")[0], image);
        }
      });
    });
    if (!map.getSource("places")) {
      map.addSource("places", initMarkerLayer);
      map.addLayer({
        id: "places",
        type: "symbol",
        source: "places",
        layout: {
          "icon-image": ["get", "icon"],
          "text-field": ["get", "destination_place"],
          "icon-size": 0.3,
          "icon-allow-overlap": true,
          "text-allow-overlap": true,
          // get the year from the source's "year" property
          "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
          "text-offset": [0, 1.25],
          "text-anchor": "top",
        },
        paint: {
          "text-color": "#006400",
          "text-halo-color": "#FFFF00",
          "text-halo-width": 2,
        },
      });
    }
    if (!map.getSource("origins")) {
      map.addSource("origins", initMarkerLayer);
      map.addLayer({
        id: "origins",
        type: "symbol",
        source: "origins",
        layout: {
          "icon-image": ["get", "icon"],
          "text-field": ["get", "name_t"],
          "icon-size": 0.3,
          "icon-allow-overlap": true,
          "text-allow-overlap": true,
          // get the year from the source's "year" property
          "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
          "text-offset": [0, 1.25],
          "text-anchor": "top",
        },
        paint: {
          "text-color": "#006400",
          "text-halo-color": "#FFFF00",
          "text-halo-width": 2,
        },
      });
    }
    if (updateMap) {
      updateMap();
    }
  });
};

export const addDropPoint = async (pointData) => {
  return await fetch(`${backendUrl}/api/rp-droppoints`, {
    body: JSON.stringify({ data: pointData }),
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        localStorage.getItem("jwt") || sessionStorage.getItem("jwt")
      }`,
    },
  })
    .then(async (res) => {
      // console.log(res);
      // Handle success.
      if (res.status !== 200 && res.status !== 201) {
        // console.log("false");
        let result = await res.json();
        return { status: false, message: result.error.message };
      }
      // console.log("true");
      return { status: true };
    })
    .catch((error) => {
      // Handle error.
      // console.log("An error occurred:", error.response);
    });
};

export const updateDropPoint = async (pointid, pointData) => {
  // console.log(pointData);
  return await fetch(`${backendUrl}/api/rp-droppoints/${pointid}`, {
    body: JSON.stringify({ data: pointData }),
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        localStorage.getItem("jwt") || sessionStorage.getItem("jwt")
      }`,
    },
  })
    .then(async (res) => {
      // console.log(res);
      // Handle success.
      if (res.status !== 200 && res.status !== 201) {
        // console.log("false");
        let result = await res.json();
        return { status: false, message: result.error.message };
      }
      // console.log("true");
      return { status: true };
    })
    .catch((error) => {
      // Handle error.
      // console.log("An error occurred:", error.response);
    });
};

export const removeDropPoint = async (pointid) => {
  return await fetch(`${backendUrl}/api/rp-droppoints/${pointid}`, {
    body: JSON.stringify({ data: { status: "deleted" } }),
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        localStorage.getItem("jwt") || sessionStorage.getItem("jwt")
      }`,
    },
  })
    .then(async (res) => {
      // console.log(res);
      // Handle success.
      if (res.status !== 200 && res.status !== 201) {
        // console.log("false");
        let result = await res.json();
        return { status: false, message: result.error.message };
      }
      // console.log("true");
      return { status: true };
    })
    .catch((error) => {
      // Handle error.
      // console.log("An error occurred:", error.response);
    });
};

export const updateStatusDropPoint = async (pointid, status) => {
  return await fetch(`${backendUrl}/api/rp-droppoints/${pointid}`, {
    body: JSON.stringify({ data: { status } }),
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        localStorage.getItem("jwt") || sessionStorage.getItem("jwt")
      }`,
    },
  })
    .then(async (res) => {
      // console.log(res);
      // Handle success.
      if (res.status !== 200 && res.status !== 201) {
        // console.log("false");
        let result = await res.json();
        return { status: false, message: result.error.message };
      }
      // console.log("true");
      return { status: true };
    })
    .catch((error) => {
      // Handle error.
      // console.log("An error occurred:", error.response);
    });
};

export const addVehicle = async (pointData) => {
  // console.log(JSON.stringify({ data: pointData }));
  return await fetch(`${backendUrl}/api/rp-vehicles`, {
    body: JSON.stringify({ data: pointData }),
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        localStorage.getItem("jwt") || sessionStorage.getItem("jwt")
      }`,
    },
  })
    .then(async (res) => {
      // console.log(res);
      // Handle success.
      if (res.status !== 200 && res.status !== 201) {
        // console.log("false");
        let result = await res.json();
        return { status: false, message: result.error.message };
      }
      // console.log("true");
      return { status: true };
    })
    .catch((error) => {
      // Handle error.
      // console.log("An error occurred:", error.response);
    });
};

export const updateVehicle = async (pointid, pointData) => {
  // console.log(pointData);
  return await fetch(`${backendUrl}/api/rp-vehicles/${pointid}`, {
    body: JSON.stringify({ data: pointData }),
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        localStorage.getItem("jwt") || sessionStorage.getItem("jwt")
      }`,
    },
  })
    .then(async (res) => {
      // console.log(res);
      // Handle success.
      if (res.status !== 200 && res.status !== 201) {
        // console.log("false");
        let result = await res.json();
        return { status: false, message: result.error.message };
      }
      // console.log("true");
      return { status: true };
    })
    .catch((error) => {
      // Handle error.
      // console.log("An error occurred:", error.response);
    });
};

export const updateStatusVehicle = async (pointid, status) => {
  return await fetch(`${backendUrl}/api/rp-vehicles/${pointid}`, {
    body: JSON.stringify({ data: { status } }),
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        localStorage.getItem("jwt") || sessionStorage.getItem("jwt")
      }`,
    },
  })
    .then(async (res) => {
      // console.log(res);
      // Handle success.
      if (res.status !== 200 && res.status !== 201) {
        // console.log("false");
        let result = await res.json();
        return { status: false, message: result.error.message };
      }
      // console.log("true");
      return { status: true };
    })
    .catch((error) => {
      // Handle error.
      // console.log("An error occurred:", error.response);
    });
};

export const getOrigin = (placesData) => {
  let OriginsIDArray = [];
  let Origins = [];
  // console.log(placesData);
  _.map(placesData, (item, index) => {
    // console.log(item);
    if (item.properties.origins && item.properties.origins.data.length > 0) {
      _.map(item.properties.origins.data, (each) => {
        if (!OriginsIDArray.includes(each.id)) {
          OriginsIDArray.push(each.id);
          Origins.push({
            type: "Feature",
            properties: {
              index: OriginsIDArray.length,
              name_t: each.attributes.name_t,
              latitude: each.attributes.latitude,
              longitude: each.attributes.longitude,
              icon: "origin_marker",
              start_time: item.properties.origin_time_to
                ? item.properties.origin_datetime
                : item.properties.origin_time_to,
              end_time: item.properties.origin_time_to,
              ...each.attributes,
            },
            geometry: {
              type: "Point",
              coordinates: [
                each.attributes.longitude,
                each.attributes.latitude,
              ],
            },
          });
        }
      });
    }
  });
  return Origins;
};
