import * as XLSX from "xlsx-js-style";
import { toast } from "react-toastify";
import _ from "lodash";
import { createDropPoint, existDropPoint } from "../utils/apis";

async function ImportExcel(file, toastId) {
  // console.log("file", file);
  const data = await file.arrayBuffer();
  // console.log("data", data);
  const workbook = XLSX.read(data, { type: "array" });
  let xlsjson = XLSX.utils.sheet_to_json(workbook.Sheets.Droppoint);
  // console.log(xlsjson);

  if (!xlsjson) {
    return toast.error("Import format not support");
  }

  let allBranchID = _.map(xlsjson, (item) => item["รหัสสาขา"]);
  if (allBranchID.length === 0) {
    return toast.error(
      "ไม่พบข้อมูล ไฟล์นำเข้ารองรับเฉพาะ excel(xlsx) ที่ได้จากการ Download Invoice ผ่านแอพเท่านั้น"
    );
  }
  if (_.uniq(allBranchID).length !== allBranchID.length) {
    return toast.error("นำเข้าล้มเหลว เลขรหัสสาขาซ้ำกันในไฟล์");
  } else {
    let isExist = await existDropPoint(allBranchID);
    if (isExist.data.length !== 0) {
      return toast.error("นำเข้าล้มเหลว เลขรหัสสาขาซ้ำกันในระบบ");
    }
  }

  let progress = 0;

  toastId.current = toast.info("Upload in Progress", { progress });
  xlsjson.map(async (item, ind) => {
    let currentData = {
      branch_id: item["รหัสสาขา"].toString(),
      cate_t: item["หมวดหมู่ภาษาไทย"].toString(),
      cate_e: item["หมวดหมู่ภาษาอังกฤษ"].toString(),
      name_t: item["ชื่อภาษาไทย"].toString(),
      name_e: item["ชื่อภาษาอังกฤษ"].toString(),
      addr_t: item["ที่อยู่ภาษาไทย"].toString(),
      addr_e: item["ที่อยู่ภาษาอังกฤษ"].toString(),
      house_no: item["บ้านเลขที่"].toString(),
      moo: item["หมู่"].toString(),
      road_tname: item["ชื่อถนนภาษาไทย"].toString(),
      road_ename: item["ชื่อถนนภาษาอังกฤษ"].toString(),
      tambon_t: item["ตำบล"].toString(),
      amphoe_t: item["อำเภอ"].toString(),
      province_t: item["จังหวัด"].toString(),
      postcode: item["รหัสไปรษณีย์"].toString(),
      contact_name: item["ผู้ติดต่อ"].toString(),
      phone_no: item["เบอร์ติดต่อ"].toString(),
      opening_hours: item["เวลาเปิดทำการ"].toString(),
      branch_tty: item["ชื่อสาขาภาษาไทย"].toString(),
      branch_ety: item["ชื่อสาขาภาษาอังกฤษ"].toString(),
      latitude: item["Latitude"].toString(),
      longitude: item["Longitude"].toString(),
      remark: item["หมายเหตุ"].toString(),
      created_id: localStorage.getItem("userid")
        ? parseInt(localStorage.getItem("userid"))
        : parseInt(sessionStorage.getItem("userid")),
      rp_company: localStorage.getItem("company_id")
        ? parseInt(localStorage.getItem("company_id"))
        : parseInt(sessionStorage.getItem("company_id")),
      status: "waiting",
    };
    // console.log(currentData);
    await createDropPoint(currentData);
    progress = (ind + 1) / xlsjson.length;
    // console.log(progress);
    toast.update(toastId.current, {
      render: `Upload success at ${(progress * 100).toFixed(2)}%`,
      progress,
    });
    toast.done(toastId.current);
  });
}

export default ImportExcel;
