import React, { createContext, useState, useContext, useCallback } from "react";

import _ from "lodash";

let today = new Date().toLocaleDateString("en-CA", {
  timeZone: "Asia/Bangkok",
});
const initialState = {
  results: [],
  vehicles: [{ name: "" }],
  showRouting: false,
  selectedPOI: null,
  origin: null,
  destination: null,
  currentLocation: null,
  options: [
    { name: "N/A" },
    { name: "Veh. #1" },
    { name: "Veh. #2" },
    { name: "Veh. #3" },
  ],
  markerLayer: {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [],
    },
  },
  routeOpt: null,
  jwt: "",
  todayDate: today,
  periodDate: today,
  switch: false,
};

const Store = ({ children }) => {
  const [state, setState] = useState(initialState);
  return (
    <Context.Provider value={[state, setState]}>{children}</Context.Provider>
  );
};

export const Context = createContext([initialState, () => {}]);
export default Store;
