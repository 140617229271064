import { RiRouteLine } from "react-icons/ri";
import { MdFavoriteBorder } from "react-icons/md";

function FavorList({ data, handleClick }) {
  // console.log(data);
  return (
    <div className="p-3 space-y-2">
      <ul
        role="list"
        className="bg-gray-100 divide-y divide-gray-400 border border-gray-300 p-2 rounded-md font-jamjuree font-medium"
      >
        {data.map((each, index) => (
          <li key={index} className="py-3 sm:py-4">
            <div className="flex items-center space-x-4">
              <div className="bg-red-100 flex-shrink-0 border-2 border-red-200 rounded-full p-2">
                <MdFavoriteBorder className="text-red-500 w-5 h-5" />
              </div>
              <div className="flex-1 min-w-0">
                <p className="font-medium text-gray-900 truncate uppercase">
                  {each.attributes.name}
                </p>
                <p className="text-sm text-gray-500 truncate">
                  Total Drop Point: {each.attributes.data.length}
                </p>
              </div>
              <div
                title="route"
                className="bg-orange-100 flex-shrink-0 border-2 border-orange-200 rounded-lg p-2 cursor-pointer"
                onClick={() => handleClick(each)}
              >
                <RiRouteLine className="text-orange-500 w-5 h-5" />
              </div>
              {/* <div className="inline-flex items-center text-base font-semibold text-gray-900">
                <button>
                  <FcOpenedFolder className="w-7 h-7" />
                </button>
              </div> */}
            </div>
          </li>
        ))}
      </ul>
      {/* <div className="flex justify-end">
        <button className="p-2 rounded-lg text-white bg-indigo-500 shadow-md">
          Submit
        </button>
      </div> */}
    </div>
  );
}

export default FavorList;
