import React from "react";
import { deletepoint } from "../utils";
import { BsTrash, BsPen, BsEye, BsEyeSlash } from "react-icons/bs";
import { FaFileDownload, FaFileUpload } from "react-icons/fa";
import { FcOpenedFolder } from "react-icons/fc";
import { BiMailSend, BiHeartCircle } from "react-icons/bi";
import { toast } from "react-toastify";
import EditForm from "./EditForm";
import Switch from "../components/Switch";
import { getOrigin } from "../utils";
import { getAllFavorList } from "../utils/apis";
import { Context } from "../store";
import moment from "moment";
import Task from "../components/Task";
import _ from "lodash";
import FavorList from "./FavorList";
import ExportTemplate from "./ExportTemplate";
import ImportExcel from "./ImportExcel";
import { getAllDC } from "../utils/apis";

let allSelectMarkers;
function ListMarkers({
  data,
  clearNewData,
  removePoint,
  mapRef,
  markerGroup,
  handleAddFavor,
  handleOpenPlan,
  plan,
}) {
  let dataFormat = data.length !== 0 ? { 0: data } : {};
  let formattedData = !_.isEmpty(markerGroup) ? markerGroup : dataFormat;
  const [items, setItems] = React.useState(formattedData);
  const [order, setOrder] = React.useState({});
  const [isEdit, setIsEdit] = React.useState(false);
  const [state, setState] = React.useContext(Context);
  const [show, setShow] = React.useState(true);
  const [showFavor, setShowFavor] = React.useState(false);
  const [listFavor, setListFavor] = React.useState();
  const [distribution, setDistribution] = React.useState();
  const toastId = React.useRef(null);

  const getCategory = async () => {
    let allCategory = await getAllDC();
    // console.log(allCategory.data);
    setDistribution(allCategory.data);
    // let formatCate = _.map(allCategory.data, (item, index) => ({
    //   id: index,
    //   name: item.attributes.cate_t,
    // }));
    // setCategory(formatCate);
  };

  React.useEffect(() => {
    getCategory();
    // periodRef.current = state.periodDate;
    // getInitialDropPoint();
  }, []);

  if (!_.isEmpty(markerGroup)) {
    let mergeGroup = _.flatten(Object.values(markerGroup));
    allSelectMarkers = _.map(mergeGroup, (item) => item.properties.index);
  } else {
    allSelectMarkers = [];
  }

  const handleImportTask = async (e) => {
    // console.log(e.target.files[0]);
    if (distribution) {
      await ImportExcel(e.target.files[0], toastId, distribution);
    }
    // console.log("test");
  };

  const handleExportTemplate = () => {
    ExportTemplate();
    toast.success("Export successful");
  };

  const handleDeletePoint = async (item) => {
    let pointDataResponse = null;
    if (item.properties.init_id) {
      pointDataResponse = await deletepoint(item.properties.init_id);
      if (pointDataResponse.status) {
        removePoint(item);
        toast.success("Delete successful");
      } else {
        return toast.error(pointDataResponse.message);
      }
    } else {
      removePoint(item);
      toast.success("Delete successful");
    }
  };

  const getFavorList = async (item) => {
    let result = await getAllFavorList();
    // console.log(result);
    setListFavor(result);
  };

  const handleOpenFavor = () => {
    // console.log("open favorite");
    setShowFavor(true);
    getFavorList();
  };

  const handleClick = (e) => {
    handleAddFavor(e);
    setShowFavor(false);
    // pass data to dataref and oriDataRef
  };

  React.useEffect(() => {
    let dataFormat = data.length !== 0 ? { 0: data } : {};
    let formattedData = !_.isEmpty(markerGroup) ? markerGroup : dataFormat;
    setItems(formattedData);
  }, [data]);

  React.useEffect(() => {
    setState((state) => ({ ...state, isEdit: isEdit }));
  }, [isEdit]);

  if (isEdit) {
    return (
      <EditForm
        setIsEdit={setIsEdit}
        data={order}
        clearNewData={clearNewData}
        // sentDataList={(e) => console.log(e)}
      />
    );
  } else {
    return (
      <div className="w-auto px-2">
        <div className="flex justify-between w-auto mb-3">
          <div className="flex my-auto space-x-2">
            <div title="Import Task">
              <label
                htmlFor="upload"
                className="flex items-center p-2.5 rounded-lg bg-blue-500 text-white cursor-pointer"
              >
                <div className="group relative">
                  <FaFileDownload className="w-5 h-5 my-auto" />
                  <input
                    id="upload"
                    type="file"
                    accept=".xlsx"
                    className="hidden"
                    onChange={(e) => handleImportTask(e)}
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                  />
                </div>
              </label>
            </div>
            <button
              title="Download Template"
              onClick={() => handleExportTemplate()}
              className={`flex p-2.5 rounded-lg bg-green-500 text-white`}
            >
              <FaFileUpload className="w-5 h-5 my-auto" />
            </button>
          </div>
          <div className="flex my-auto space-x-2">
            <button
              title="View"
              onClick={() => setShow(!show)}
              className={`p-2.5 rounded-lg bg-indigo-500 text-white`}
            >
              {show && <BsEye className="w-5 h-5 my-auto" />}
              {!show && <BsEyeSlash className="w-5 h-5 my-auto" />}
            </button>
            <button
              title="Plan"
              onClick={handleOpenPlan}
              className={`${
                !plan ? "hidden" : ""
              } p-2.5 rounded-lg bg-slate-500 text-white`}
              disabled={state.periodDate >= state.todayDate}
            >
              <FcOpenedFolder className="w-5 h-5" />
            </button>
            <button
              title="Favorite"
              onClick={handleOpenFavor}
              className={`${
                state.periodDate < state.todayDate ? "hidden" : ""
              } p-2.5 rounded-lg bg-pink-500 text-white`}
              disabled={state.periodDate < state.todayDate}
            >
              <BiHeartCircle className="w-5 h-5" />
            </button>
          </div>
        </div>
        {showFavor && (
          <Task
            open={showFavor}
            setOpen={setShowFavor}
            width="w-96"
            layout="right"
          >
            <div className="bg-gray-400 w-full h-screen">
              {listFavor && (
                <FavorList data={listFavor.data} handleClick={handleClick} />
              )}
            </div>
          </Task>
        )}
        {show && (
          <div className="w-auto p-1 max-w-md bg-white rounded-lg border shadow-md sm:p-3 dark:bg-gray-800 dark:border-gray-700">
            <div className="text-center content-center mb-2 flex justify-between ">
              <div>
                <h3 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
                  รอบขนส่ง
                  <button title="Clear" className="ml-1">
                    <BiMailSend
                      onClick={clearNewData}
                      className="h-5 w-5 dark:text-teal-500"
                    />
                  </button>
                </h3>
              </div>
              <div>
                {new moment(state.periodDate) >=
                  new moment().startOf("day") && (
                  <Switch state={state} setState={setState} />
                )}
              </div>
            </div>
            <div className="flex justify-center items-center">
              <div className="flex items-center">
                <div className="relative">
                  <input
                    name="datetime"
                    type="date"
                    defaultValue={state.periodDate}
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-8 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 datepicker-input"
                    onChange={async (e) =>
                      setState((state) => ({
                        ...state,
                        periodDate: e.target.value,
                        switch: false,
                      }))
                    }
                  />
                </div>
              </div>
            </div>
            <div className="flow-root mt-2 rounded-lg">
              <ul className="divide-y divide-gray-200 dark:divide-gray-700 h-auto">
                {Object.keys(items).map((key, index) => {
                  let origins = getOrigin(items[key]);
                  return (
                    <div
                      key={index}
                      className="border p-2 mt-2 rounded-lg dark:border-gray-600"
                    >
                      <p className="border-b font-bold uppercase dark:text-gray-400 dark:border-gray-600">
                        Group {index + 1}
                      </p>
                      <div className="">
                        <div className="font-bold italic dark:text-gray-400 ml-10 text-gray-500">
                          Origins
                        </div>
                        <div className="container">
                          <div className="flex flex-col md:grid grid-cols-12 text-gray-50">
                            {origins &&
                              origins.map((item, index) => (
                                <div key={index} className="flex md:contents">
                                  <div className="col-start-0 col-end-2 mr-5 md:mx-auto relative">
                                    {/* <div className="h-full w-6 flex items-center justify-center">
                                    <div className="h-full w-1 bg-gray-300 pointer-events-none"></div>
                                  </div> */}
                                    <div className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-gray-300 shadow text-center">
                                      <img
                                        src={"./pinpoint/origin_marker.png"}
                                        className="w-6 rounded-full cursor-pointer"
                                        onClick={() => {
                                          mapRef.flyTo({
                                            center: [
                                              item.properties.longitude,
                                              item.properties.latitude,
                                            ],
                                            duration: 2000,
                                            zoom: 16,
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-start-3 col-end-12 rounded-xl p-1 mr-auto w-full">
                                    <div className="flex mt-2 dark:text-gray-400 ">
                                      <div className="my-1 p-1 text-sm font-jamjuree dark:text-gray-400 text-gray-500">
                                        {item.properties.name_t}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="font-bold italic dark:text-gray-400 ml-10">
                          Destination
                        </div>
                        <div className="container">
                          <div className="flex flex-col md:grid grid-cols-12 text-gray-50">
                            {items[key].map((item, index) => {
                              let color = "%230000FF";
                              allSelectMarkers.length !== 0 &&
                              allSelectMarkers.includes(item.properties.index)
                                ? (color = "%234BB543")
                                : (color = "%230000FF");
                              return (
                                <div key={index} className="flex md:contents">
                                  <div className="col-start-0 col-end-2 mr-5 md:mx-auto relative">
                                    <div className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full text-center">
                                      <img
                                        className="w-6 rounded-full cursor-pointer"
                                        src={`data:image/svg+xml;charset=utf-8,<svg width="100" height="133.33424" viewBox="0 0 105 136.33424" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M 48.48894,2.0846666 C 45.345582,2.4764484 42.045821,3.0699806 39.971926,3.6166357 31.331372,5.8941857 23.70167,10.287001 17.343929,16.644739 7.5307186,26.457951 2.26971,39.565851 2.6992958,53.1321 c 0.2096929,6.62207 1.4945831,12.285322 4.2570658,18.763368 3.8625134,9.057619 8.9221174,16.765286 21.9823994,33.487402 9.794726,12.54096 13.702902,17.89379 17.704361,24.24874 1.304226,2.0713 2.77134,4.14006 3.260261,4.59722 1.567811,1.46597 3.94258,1.46597 5.510391,0 0.48892,-0.45716 1.956035,-2.52592 3.26026,-4.59722 4.00146,-6.35495 7.909636,-11.70778 17.704362,-24.24874 C 89.438679,88.660754 94.498283,80.953087 98.360793,71.895468 102.97204,61.082067 103.87705,50.520165 101.12201,39.670835 98.886222,30.866339 94.481777,23.15329 87.973228,16.644739 80.532191,9.2037052 71.45767,4.5056208 60.997853,2.6789833 58.10893,2.1744806 50.622249,1.8187766 48.48894,2.0846666 Z" 
                        fill="${color}"/><style>
                        .number { font: bold 50px sans-serif; fill: white;}
                      </style>
                      <text x="50%" y="50%" dominant-baseline="start" text-anchor="middle" class="number">${
                        item.properties.index + 1
                      }</text></svg>`}
                                        alt="icon"
                                        onClick={() => {
                                          mapRef.flyTo({
                                            center: [
                                              item.properties.longitude,
                                              item.properties.latitude,
                                            ],
                                            duration: 2000,
                                            zoom: 16,
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-start-3 col-end-12 rounded-xl p-1 w-full">
                                    <div className="flex mt-2 dark:text-gray-400 text-jamjuree">
                                      <div className="flex-1 min-w-0">
                                        <p className="text-sm font-medium text-gray-900 truncate dark:text-white font-jamjuree">
                                          {item.properties.category}
                                        </p>
                                        <p className="text-sm text-gray-500 truncate dark:text-gray-400 font-jamjuree">
                                          จาก {item.properties.origin_place}
                                        </p>
                                        <p className="text-sm text-gray-500 truncate dark:text-gray-400 font-jamjuree">
                                          ถึง{" "}
                                          {item.properties.destination_place ||
                                            "Not from master data"}
                                        </p>
                                      </div>
                                      <div className="flex space-x-1 ml-5">
                                        <button
                                          title="Edit"
                                          onClick={() => {
                                            setOrder(item);
                                            setIsEdit(true);
                                            setState({
                                              ...state,
                                              selected_marker: item,
                                            });
                                          }}
                                        >
                                          <BsPen className="h-4 w-4 text-yellow-500" />
                                        </button>
                                        <button
                                          title="Remove"
                                          onClick={() =>
                                            handleDeletePoint(item)
                                          }
                                        >
                                          <BsTrash className="h-4 w-4 text-red-500" />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </ul>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ListMarkers;
